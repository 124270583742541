import { Action } from '../actions/counterActions';

// TODO: Rename isCostco to be more descriptive.
export interface ErrorState {
  error?: Error,
  isCostco?: boolean
}

export default function errorStateReducer(state: ErrorState = {}, action: Action) {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_COSTCO_ERROR':
      return {
        ...state,
        error: action.payload,
        isCostco: true,
      };
    default:
      return state;
  }
}
