import i18next from 'i18next';
import * as _ from 'lodash';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { DocumentType, getDocumentDetailsString, getDocumentDisplayString } from '../../models/DocumentType';
import ARHttp from '../../services/ARHttp';
import { getSettings } from '../../services/Settings';
import DocumentDownloadItem, { DocumentDownloadIcon } from './DocumentDownloadItem';

interface Props {
  cToken: string;
  appId: string;
  documentsToBeSigned: boolean;
  thirdPartyApp: boolean;
  setHasWetSignNeededDocuments?: (hasWetSignNeededDocuments: boolean) => void;
  shouldShowSignatureAndReturnRequired?: (showSignatureAndReturnRequired: boolean) => void;
}

interface State {
  documents: CustomerDocument[];
}

interface CustomerDocument {
  link: string;
  title: string;
  description: string;
  docType: string;
  fileType: string;
}

export default class DocumentDownloadList extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.openDocument = this.openDocument.bind(this);

    this.state = {
      documents: [],
    };
  }

  public componentDidMount() {
    if (this.props.thirdPartyApp) {
      ARHttp({
        method: 'POST',
        url: '/aws/document/listsignabledocuments',
        body: {
          appId: this.props.appId,
          thirdPartyApp: true,
        },
        headers: {
          'X-XSRF-TOKEN': this.props.cToken,
        },
      }).subscribe((response: any) => {
        function getDocumentKey(document) {
          let titleKey = getDocumentDisplayString(document.docType);
          if (document.docURL.toLowerCase().includes('english')) {
            titleKey = `${titleKey}_english`;
          } else if (document.docURL.toLowerCase().includes('french')) {
            titleKey = `${titleKey}_french`;
          }
          return titleKey;
        }
        const documents: CustomerDocument[] = _.filter(response.merchantDocumentList, (listDocuments) => !!listDocuments.customerFacing)
          .map((document) => ({
            link: document.docURL,
            title: i18next.t(getDocumentKey(document)),
            description: getDocumentDetailsString(document.docType) === 'unknown'
              ? document.description : i18next.t(getDocumentDetailsString(document.docType)),
            docType: document.docType,
            fileType: document.mimeType,
          }));
        this.setState({ documents });
      });
    } else {
      ARHttp({
        method: 'POST',
        url: '/aws/document/listsignabledocuments',
        body: {
          appId: this.props.appId,
        },
        headers: {
          'X-XSRF-TOKEN': this.props.cToken,
        },
      }).subscribe((response: any) => {
        let documents: CustomerDocument[] = _.map(response.merchantDocumentList, (document) => {
          // Old JSP left-over. If the TERMINAL_HIRE_AGREEMENT is meant to be shown to the user for download,
          // we instead show the TERMINAL_HIRE_PRE_CONTRACT document. In reality this should be handled by the server, sending back
          // the correct document for the UI to show. This is a stop-gap solution to fix it, since it was done in the UI last time.
          // This requirement dates back to June of 2015.
          if (document.docType === DocumentType.TERMINAL_HIRE_AGREEMENT) {
            document.docType = DocumentType.TERMINAL_HIRE_PRE_CONTRACT;
            document.docURL = document.docURL.replace(/&docId=\d+/, '');
            document.docURL = document.docURL.replace('&typeId=24', '&typeId=26');
          }
          if (!(!!document.mimeType && document.mimeType === 'text/html')) {
            document.docURL = `${document.docURL}&ctoken=${this.props.cToken}`;
          }
          return {
            link: document.docURL,
            title: i18next.t(getDocumentDisplayString(document.docType)),
            description: i18next.t(getDocumentDetailsString(document.docType)),
            docType: document.docType,
            fileType: document.mimeType,
          };
        });
        documents = this.filterDocuementsToBeSigned(documents);

        if (this.props.setHasWetSignNeededDocuments) {
          this.props.setHasWetSignNeededDocuments(this.props.documentsToBeSigned && documents.length > 0);
        }

        this.setState({
          documents,
        });
      });
    }
  }

  public render() {
    const files = _.map(this.state.documents, (document, index) => (
      <DocumentDownloadItem
        last={false}
        title={document.title}
        description={document.description}
        link={document.link}
        icon={(!!document.fileType && (document.fileType === 'text/html' || document.fileType === 'URL'))
          ? DocumentDownloadIcon.EXTERNAL_LINK
          : DocumentDownloadIcon.FILE}
        onClick={this.openDocument}
        key={index}
      />
    ));
    return <>{files}</>;
  }

  public openDocument(link: string) {
    window.open(link, '_blank');
  }

  private filterDocuementsToBeSigned(documents: CustomerDocument[]): CustomerDocument[] {
    if (this.props.documentsToBeSigned) {
      documents = documents.filter((document) => {
        if (
          document.docType === 'DEPOSIT_SIGNED_DIRECT_DEBIT'
                    || document.docType === 'BILLING_SIGNED_DIRECT_DEBIT'
                    || document.docType === 'CHARGEBACK_SIGNED_DIRECT_DEBIT'
        ) {
          return true;
        }
      });
    } else {
      documents = documents.filter((document) => {
        if (
          document.docType === 'DEPOSIT_SIGNED_DIRECT_DEBIT'
                    || document.docType === 'BILLING_SIGNED_DIRECT_DEBIT'
                    || document.docType === 'CHARGEBACK_SIGNED_DIRECT_DEBIT'
        ) {
          return false;
        }
        return true;
      });
    }
    if (documents.length > 0 && this.props.documentsToBeSigned && this.props.shouldShowSignatureAndReturnRequired) {
      this.props.shouldShowSignatureAndReturnRequired(true);
    }
    return documents;
  }

  private appendCtoken(document) {
    const settings = getSettings();
    const jwtToken = window.sessionStorage.getItem(settings.tokenKey);
    const noCToken = ['TERMS_OF_SERVICE', 'OPERATING_GUIDE'];
    if (_.includes(noCToken, document.documentCode)) {
      if (document.link.includes('getdocumentpdf') || document.link.includes('scwebapi/apps/getdoc')) {
        if (this.props.cToken === null || this.props.cToken === undefined) {
          return `${document.link}&j=${jwtToken}`;
        }
        return `${document.link}&ctoken=${this.props.cToken}`;
      }
      return `${document.link}`;
    }
    if (this.props.cToken === null || this.props.cToken === undefined) {
      return `${document.link}&j=${jwtToken}`;
    }
    return `${document.link}&ctoken=${this.props.cToken}`;
  }
}
