import { Store } from 'redux';

let store: Store;

export function getStore(): Store {
  return store;
}

export function setStore(reduxStore: Store): void {
  store = reduxStore;
}
