import {
  Button, Card, CardContent, CardHeader, FormControl, Grid, Typography,
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import i18next from 'i18next';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import ARHttp from '../../services/ARHttp';

const pageStyles = (theme) => createStyles({
  subText: {
    margin: '8px 0',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  form: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  formControl: {
    marginTop: '18px',
  },
  headerText: {
    justifyContent: 'center',
  },
  cardContent: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
});

interface Props extends WithStyles {
  multisignPending: boolean;
  email: string;
  history: any;
  loadJurisdictionInfo: (jurisdictionInfo: any) => void;
  token: string;
  appId: string;
  thirdParty: boolean;
  thirdPartyStandalone: boolean;
  clearSkipToApp: () => void;
}

class SubmissionPage extends Component<Props, any> {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  public componentDidMount() {
    const {
      multisignPending, appId, token, loadJurisdictionInfo,
    } = this.props;

    window.document.title = multisignPending ? i18next.t('customer_ui_pending') : i18next.t('customer_ui_submitted');

    ARHttp({
      method: 'POST',
      url: '/aws/getappdetails',
      body: {
        appId,
      },
      headers: {
        'X-XSRF-TOKEN': token,
      },
    }).subscribe((response: any) => {
      loadJurisdictionInfo(response);
    });
  }

  public render() {
    const {
      classes, email, multisignPending, thirdParty, thirdPartyStandalone,
    } = this.props;

    const appComplete: React.JSX.Element = (
      <CardContent className={classes.cardContent}>
        <Typography variant="body1">
          {multisignPending ? undefined : <Trans>customer_apps_submitted_info_message</Trans>}
        </Typography>
        <br />
        <Typography variant="body1">
          {multisignPending ? (
            <Trans>signature_pending</Trans>
          ) : (
            <Trans values={{ emailAddress: email }}>customer_apps_notification</Trans>
          )}
        </Typography>
        <FormControl className={classes.formControl}>
          <Button variant="contained" color="primary" type="submit" disabled={false} onClick={this.onSubmit}>
            <ChevronLeft />
            <Trans>Return_to_Application_List</Trans>
          </Button>
        </FormControl>
      </CardContent>
    );
    let renderContent;
    if (thirdParty) {
      renderContent = (
        <Grid item xs={12}>
          <Card style={{ textAlign: 'center' }}>
            <CardHeader title={i18next.t('application_complete_message')} component="h1" />
            <CardContent>
              <p>
                {
                  thirdPartyStandalone
                    ? i18next.t('thrd_party_stnd_keep_an_eye_on_your_inbox')
                    : i18next.t('keep_an_eye_on_your_inbox')
                }
              </p>
            </CardContent>
          </Card>
        </Grid>
      );
    } else {
      renderContent = (
        <Card className={classes.form} style={{ padding: '24px', marginTop: '32px' }}>
          {multisignPending ? (
            undefined
          ) : (
            <CardHeader className={classes.headerText} title={i18next.t('customer_apps_submitted')} component="h1" />
          )}
          {appComplete}
        </Card>
      );
    }
    return <>{renderContent}</>;
  }

  private onSubmit() {
    this.props.clearSkipToApp();
    this.props.history.push('/applist');
  }
}

export default withStyles(pageStyles)(SubmissionPage);
