import * as _ from 'lodash';
import Address from '../../models/Address';
import AddressType from '../../models/AddressType';
import BankAccountTypes from '../../models/BankAccounts';
import Identification from '../../models/Identification';
import IDType from '../../models/IDType';
import Month from '../../models/Month';
import Person from '../../models/Person';
import PositionType from '../../models/PositionType';
import SelfboardApplication from '../../models/SelfBoardApplication';
import VatNumberOption from '../../models/VatNumberOption';
import { AboutBusinessFormState } from '../../reducers/aboutBusinessForm';
import { BankingInformationFormState } from '../../reducers/bankingInformationForm';
import { BusinessProfileFormState } from '../../reducers/businessProfileForm';
import { CardAcceptanceState } from '../../reducers/cardAcceptanceForm';
import { CreditUnderwritingFormState } from '../../reducers/creditUnderwritingForm';
import { DataEntryFormState } from '../../reducers/dataEntryForm';
import { EquipmentFormState } from '../../reducers/equipmentForm';
import { GeographyInfoFormState } from '../../reducers/geographyInfoForm';
import { JurisdictionInfoState } from '../../reducers/jurisdictionInfo';
import { OwnersProfile, OwnersProfileState } from '../../reducers/ownersProfile';
import { PersonalProfileState } from '../../reducers/personalProfile';
import { PlaceHolderFormState } from '../../reducers/placeHolderForm';

export function getAboutBusinessFormStateFromApplication(application: SelfboardApplication): AboutBusinessFormState {
  let values: AboutBusinessFormState = {};
  if (application.uiStateMap) {
    values = {
      ...values,
      isUsBusiness: application.uiStateMap.isUsBusiness,
      businessPercentageQuestion: application.uiStateMap.businessPercentageQuestion,
      businessVatNumberQuestion: application.uiStateMap.businessVatNumberQuestion,
    };
  }
  if (application.uiStateMap && application.uiStateMap.fieldAutomationData) {
    values = {
      ...values,
      showBusinessNameAndAddress: application.uiStateMap.fieldAutomationData.showBusinessNameAndAddress,
      fieldAutomationBusinessName: application.uiStateMap.fieldAutomationData.companyName,
      fieldAutomationBusinessAddress: application.uiStateMap.fieldAutomationData.companyAddress,
      companyID: application.uiStateMap.fieldAutomationData.companyID,
      showVatIdWarningMessage: application.uiStateMap.fieldAutomationData.showVatIdWarningMessage,
      fieldAutomationBusinessSelection: application.uiStateMap.fieldAutomationData.fieldAutomationBusinessSelection,
      continueManuallyWithOutFieldAutomation: application.uiStateMap.fieldAutomationData.continueManuallyWithOutFieldAutomation,
    };
  }
  if (application.scarecrowApplication) {
    if (application.scarecrowApplication.businessInfo) {
      if (application.scarecrowApplication.businessInfo.taxID) {
        values = {
          ...values,
          businessVatNumber: application.scarecrowApplication.businessInfo.taxID,
        };
      }
      if (application.scarecrowApplication.businessInfo.customerMembershipNumber) {
        values = {
          ...values,
          customerMembershipNumber: application.scarecrowApplication.businessInfo.customerMembershipNumber,
        };
      }
      if (application.scarecrowApplication.businessInfo.vatInfo) {
        if (application.scarecrowApplication.businessInfo.vatInfo.numberOption) {
          values = {
            ...values,
            vatNumberOption: application.scarecrowApplication.businessInfo.vatInfo.numberOption,
          };
        }
      }
    }
  }

  function setVatNumberQuestion(app: SelfboardApplication) {
    if (app.scarecrowApplication!.referrerName === 'SELF_BOARD_POL') {
      return undefined;
    }
    return 'true';
  }

  if (application.scarecrowApplication && (application.scarecrowApplication.country === 'POL'
            || application.scarecrowApplication.country === 'GBR')) {
    if (!values.businessVatNumberQuestion) {
      values = {
        ...values,
        businessVatNumberQuestion: setVatNumberQuestion(application),
        vatNumberOption: VatNumberOption.VAT_NBR,
      };
    }
  }
  return values;
}

export function getBankStateFromApplication(application: SelfboardApplication): BankingInformationFormState {
  const values: BankingInformationFormState = {};
  if (application.scarecrowApplication && application.scarecrowApplication.bankAccounts) {
    if (application.scarecrowApplication.bankAccounts.DEPOSIT) {
      if (application.scarecrowApplication.bankAccounts.DEPOSIT.accountNumber) {
        values.bankAccountNumber = application.scarecrowApplication.bankAccounts.DEPOSIT.accountNumber;
      }
      if (application.scarecrowApplication.bankAccounts.DEPOSIT.sortCode) {
        values.bankRoutingNumber = application.scarecrowApplication.bankAccounts.DEPOSIT.sortCode;
      }
      if (application.scarecrowApplication.bankAccounts.DEPOSIT.accountName) {
        values.accountName = application.scarecrowApplication.bankAccounts.DEPOSIT.accountName;
      } else if (application.scarecrowApplication.country === 'POL') {
        if (application.scarecrowApplication && application.scarecrowApplication.principal) {
          const { principal } = application.scarecrowApplication;
          if (principal.name) {
            const { name } = principal;
            if (name.firstName || name.lastName) {
              values.accountName = `${name.firstName} ${name.lastName}`;
            }
          }
        }
      }
      if (application.scarecrowApplication.bankAccounts.DEPOSIT.iban) {
        values.bankIbanNumber = application.scarecrowApplication.bankAccounts.DEPOSIT.iban;
      }
    }
  }
  return values;
}

export function getBusinessStateFromApplication(application: SelfboardApplication): BusinessProfileFormState {
  let values: BusinessProfileFormState = {};

  if (application.businessAddressSameAs === AddressType.PRINCIPAL) {
    values.businessAddressDropDown = 'PRINCIPAL';
  }
  if (application.legalAddressSameAs === AddressType.BUSINESS) {
    values.businessLegalAddressSelection = 'BUSINESS';
  }

  if (application.uiStateMap) {
    values = {
      ...values,
      businessCustomerDropDown: application.uiStateMap.businessCustomerDropDown,
      businessAddressDropDown: application.uiStateMap.businessAddressDropDown,
      businessLegalAddressSelection: application.uiStateMap.businessLegalAddressSelection,
    };
  }

  if (application.scarecrowApplication) {
    if (application.scarecrowApplication.principal) {
      const { principal } = application.scarecrowApplication;
      if (principal.contactInfo) {
        const { contactInfo } = principal;
        if (contactInfo) {
          values = {
            ...values,
            businessEmailAddress: contactInfo.emailAddress ? contactInfo.emailAddress : undefined,
          };
        }
      }
    }
    if (application.scarecrowApplication.contact) {
      if (application.scarecrowApplication.contact.contactInfo) {
        if (application.scarecrowApplication.contact.contactInfo.phone) {
          values = {
            ...values,
            businessPhoneNumber: application.scarecrowApplication.contact.contactInfo.phone,
          };
        }
      }
    }
    if (application.scarecrowApplication.financialInfo) {
      values = {
        ...values,
        businessCustomerServiceEmailAddress: application.scarecrowApplication.financialInfo.businessEmailAddress
          ? application.scarecrowApplication.financialInfo.businessEmailAddress
          : undefined,
      };
      if (application.scarecrowApplication.financialInfo.moneyServices !== undefined) {
        values.moneyServices = `${application.scarecrowApplication.financialInfo.moneyServices}`;
      }
      if (application.scarecrowApplication.financialInfo.paymentServices !== undefined) {
        values.paymentServices = `${application.scarecrowApplication.financialInfo.paymentServices}`;
      }
      if (application.scarecrowApplication.financialInfo.thirdPartyProcessor !== undefined) {
        values.thirdPartyProcessor = `${application.scarecrowApplication.financialInfo.thirdPartyProcessor}`;
      }
      if (application.scarecrowApplication.financialInfo.nonGovernmentNonProfit !== undefined) {
        values.nonGovernmentNonProfit = `${application.scarecrowApplication.financialInfo.nonGovernmentNonProfit}`;
      }
      if (application.scarecrowApplication.financialInfo.customerServicePhone) {
        values = {
          ...values,
          businessCustomerServicePhone: application.scarecrowApplication.financialInfo.customerServicePhone,
        };
      }
    }

    if (application.scarecrowApplication.businessInfo) {
      values = {
        ...values,
        businessLegalName: application.scarecrowApplication.businessInfo.legalNameExtended
          ? application.scarecrowApplication.businessInfo.legalNameExtended
          : undefined,
        businessName: application.scarecrowApplication.businessInfo.dbaName
          ? application.scarecrowApplication.businessInfo.dbaName
          : undefined,
        registrationNumber: application.scarecrowApplication.businessInfo.registrationNumber
          ? application.scarecrowApplication.businessInfo.registrationNumber
          : undefined,
        businessTaxID: application.scarecrowApplication.businessInfo.taxID
          ? application.scarecrowApplication.businessInfo.taxID
          : undefined,
      };
      if (application.scarecrowApplication.businessInfo.businessAddress) {
        const address: Address = application.scarecrowApplication.businessInfo.businessAddress;
        values = {
          ...values,
          businessAddressLineOneField: address.streetName ? address.streetName : undefined,
          businessAddressLineTwoField: address.lineTwo ? address.lineTwo : undefined,
          businessCityField: address.city ? address.city : undefined,
          businessZipCodeField: address.postCode ? address.postCode : undefined,
          businessCountryField: address.country ? address.country : undefined,
          businessStateField: address.state ? address.state : undefined,
          businessAddressClassification: address.classification ? address.classification : undefined,
          businessPhysicalAddressNumber: address.streetNumber ? address.streetNumber : undefined,
        };
      }

      if (
        application.scarecrowApplication.businessInfo.additionalAddresses
                && application.scarecrowApplication.businessInfo.additionalAddresses.LEGAL
      ) {
        const address: Address = application.scarecrowApplication.businessInfo.additionalAddresses.LEGAL;

        values = {
          ...values,
          businessLegalAddress: address.streetName ? address.streetName : undefined,
          businessLegalStreetNumber: address.streetNumber ? address.streetNumber : undefined,
          businessLegalAddressLineTwo: address.lineTwo ? address.lineTwo : undefined,
          businessLegalCity: address.city ? address.city : undefined,
          businessLegalZipCode: address.postCode ? address.postCode : undefined,
          businessLegalCountry: address.country ? address.country : undefined,
          businessLegalStateOrProvince: address.state ? address.state : undefined,
          businessLegalAddressClassification: address.classification ? address.classification : undefined,
        };
      }
      if (application.scarecrowApplication.businessInfo.taxFormType) {
        values = {
          ...values,
          businessTaxFormType: application.scarecrowApplication.businessInfo.taxFormType,
        };
      }
      if (application.scarecrowApplication.businessInfo.taxIDType) {
        values = {
          ...values,
          businessTaxIdType: application.scarecrowApplication.businessInfo.taxIDType,
        };
      }
    }
  }
  return values;
}

export function getCardAcceptanceStateFromApplication(application: SelfboardApplication): CardAcceptanceState {
  let values: CardAcceptanceState = {};

  if (application.scarecrowApplication && application.scarecrowApplication.financialInfo) {
    values = {
      ...values,
      // default this to %100 unless the server says otherwise.
      cardPresentAcceptancePercent: application.scarecrowApplication.financialInfo.cardPresentAcceptancePercent
        ? application.scarecrowApplication.financialInfo.cardPresentAcceptancePercent
        : '100',
      internetAcceptancePercent: application.scarecrowApplication.financialInfo.internetAcceptancePercent
        ? application.scarecrowApplication.financialInfo.internetAcceptancePercent
        : '0',
      motoAcceptancePercent: application.scarecrowApplication.financialInfo.motoAcceptancePercent
        ? application.scarecrowApplication.financialInfo.motoAcceptancePercent
        : '0',
      businessWebsiteURL: application.scarecrowApplication.financialInfo.businessWebsiteURL
        ? application.scarecrowApplication.financialInfo.businessWebsiteURL
        : undefined,
    };
  }
  return values;
}

export function getCreditUnderwritingFormState(application: SelfboardApplication): Partial<CreditUnderwritingFormState> {
  const toReturn: Partial<CreditUnderwritingFormState> = {};
  const todaysDate = new Date();
  const year = todaysDate.getFullYear();
  if (application.scarecrowApplication && application.scarecrowApplication.businessInfo) {
    if (application.scarecrowApplication.businessInfo.establishmentYear === year.toString()) {
      toReturn.businessEstablishmentYear = application.scarecrowApplication.businessInfo.establishmentYear;
      toReturn.currentOwnershipYears = '0';
      toReturn.currentOwnershipMonths = '1';
    } else {
      toReturn.businessEstablishmentYear = application.scarecrowApplication.businessInfo.establishmentYear;
      toReturn.employerId = application.scarecrowApplication.businessInfo.employerId;
    }
  }
  if (application.scarecrowApplication && application.scarecrowApplication.financialInfo) {
    toReturn.projectedMonthlyCardSales = application.scarecrowApplication.financialInfo.monthlyCardSales;
    toReturn.averageTransactionValue = application.scarecrowApplication.financialInfo.avgSaleAmount;
    toReturn.companyAnnualRevenue = application.scarecrowApplication.financialInfo.annualRevenue;
  }
  return toReturn;
}

export function getDataEntryFormStateFromApplication(
  application: SelfboardApplication,
  existingState: DataEntryFormState,
): Partial<DataEntryFormState> {
  const newState = { ...existingState };
  if (application.uiStateMap && application.uiStateMap.activeSection) {
    newState.activeSection = Number(application.uiStateMap.activeSection);
  } else {
    newState.activeSection = 0;
  }
  return newState;
}

export function getEquipmentFormStateFromApplication(application: SelfboardApplication): EquipmentFormState {
  let values: EquipmentFormState = {};
  if (application.scarecrowApplication) {
    if (application.scarecrowApplication.fees) {
      const safeTSoloFee = '92296';
      let subFee = 0;
      let safeTFeeAmt = 0;
      _.forEach(application.scarecrowApplication.fees, (value) => {
        if (value.type === safeTSoloFee) {
          safeTFeeAmt = value.amount;
        } else if (value.frequency === 'MONTHLY') {
          subFee += value.amount;
        }
      });

      values = {
        ...values,
        subscriptionFee: subFee,
        safeTFee: safeTFeeAmt,
        allFees: application.scarecrowApplication.fees,
      };
    }
    if (application.scarecrowApplication.equipmentInfo) {
      const { equipmentInfo } = application.scarecrowApplication;
      if (application.scarecrowApplication.equipmentInfo.equipmentItems) {
        const { equipmentItems } = application.scarecrowApplication.equipmentInfo;
        const codes: string[] = [];
        const costs: number[] = [];
        // Equipment pages should be refactored to use just equipmentItems.
        _.forEach(equipmentItems, (item) => {
          let amount = 0;
          codes.push(item.code);
          amount = item.pricingItems[0].amount * item.quantity;
          costs.push(amount);
        });
        values = {
          ...values,
          equipment: codes,
          terminalPrice: costs,
          equipmentItems,
        };
      }

      values = {
        ...values,
        equipmentInfo,
      };
      // assumes one bax numeber and date pair per application
      if (application.scarecrowApplication.equipmentInfo.equipmentItems) {
        application.scarecrowApplication.equipmentInfo.equipmentItems.forEach((item) => {
          if (item.itemSettings && item.itemSettings.options) {
            item.itemSettings.options.forEach((option) => {
              if (option.baxNumber) {
                values = {
                  ...values,
                  baxNumber: option.baxNumber,
                  baxEffectiveDate: parseDateOfBirth(option.baxEffectiveDate),
                };
              }
            });
          }
        });
      }
    }
    if (application.scarecrowApplication.cardPricing) {
      const { cardPricing } = application.scarecrowApplication;
      const debitCardList: string[] = [];

      if (cardPricing.cardCharges) {
        values = {
          ...values,
          cardCharges: cardPricing.cardCharges,
        };
      }
      if (cardPricing.debitPricing) {
        _.forEach(cardPricing.debitPricing.debitNetworkCharges, (card) => {
          debitCardList.push(card.type);
        });
      }
      if (cardPricing.exceptionCharges) {
        values = {
          ...values,
          cardExceptionCharges: cardPricing.exceptionCharges,
        };
      }

      values = {
        ...values,
        acceptedDebitCards: debitCardList,
      };
    }
  }
  return values;
}

export function getGeographyInfoFormStateFromApplication(application: SelfboardApplication): Partial<GeographyInfoFormState> {
  const toReturn: Partial<GeographyInfoFormState> = {};
  let countryofForm;
  let countryofPrim;
  if (application.scarecrowApplication) {
    if (application.scarecrowApplication.businessInfo) {
      toReturn.countryOfFormation = application.scarecrowApplication.businessInfo.countryOfOrigin;
      toReturn.countryOfPrimaryBusinessOperations = application.scarecrowApplication.businessInfo.countryOfPrimaryOperation;
      const { businessInfo } = application.scarecrowApplication;
      if (businessInfo.countryOfOrigin) {
        countryofForm = businessInfo.countryOfOrigin;
      }
      if (businessInfo.countryOfPrimaryOperation) {
        countryofPrim = businessInfo.countryOfPrimaryOperation;
      }
    }
    toReturn.countryOfPrimaryBusinessOperations = countryofPrim;
    toReturn.countryOfFormation = countryofForm;
  }
  return toReturn;
}

export function getOwnersProfileStateFromApplication(application: SelfboardApplication): OwnersProfileState {
  let values: OwnersProfileState = { owners: [] };
  if (application.scarecrowApplication) {
    values = {
      ...values,
      appCountry: application.scarecrowApplication.country,
    };
    if (application.scarecrowApplication.additionalShareholders) {
      for (let i = 0; i <= application.scarecrowApplication.additionalShareholders.length - 1; i++) {
        const person: Person = application.scarecrowApplication.additionalShareholders[i];

        let owner: OwnersProfile = {
          ownersOwnershipPercentage: person.ownershipPct ? person.ownershipPct : undefined,
          ownersCitizenship: person.primaryNationality ? person.primaryNationality : undefined,
          ownersPrincipalTitle: person.titleType ? person.titleType : undefined,
          ownersDateOfBirth: parseDateOfBirth(person.dob),
        };

        if (person.name) {
          const { name } = person;
          owner = {
            ...owner,
            ownersFirstName: name.firstName ? name.firstName : undefined,
            ownersMiddleName: name.middleName ? name.middleName : undefined,
            ownersLastName: name.lastName ? name.lastName : undefined,
          };
        }

        if (person.contactInfo) {
          if (person.contactInfo.address) {
            const { address } = person.contactInfo;
            owner = {
              ...owner,
              ownersAddressLine1: address.streetName ? address.streetName : undefined,
              ownersAddressNumber: address.streetNumber,
              ownersAddressLine2: address.lineTwo,
              ownersCity: address.city ? address.city : undefined,
              ownersZipCode: address.postCode ? address.postCode : undefined,
              ownersCountry: address.country ? address.country : undefined,
              ownersProvence: address.state ? address.state : undefined,
              ownersEmailAddress: person.contactInfo.emailAddress ? person.contactInfo.emailAddress : undefined,
              ownersPhoneNumber: person.contactInfo.phone ? person.contactInfo.phone : undefined,
            };
          }
        }

        if (person.ids) {
          const id: Identification = person.ids[0];
          if (application.scarecrowApplication
                        && application.scarecrowApplication.country === 'POL') {
            const idList = [
              IDType.ID_CARD,
              IDType.KARTA_POBYTU,
              IDType.PASSPORT,
              IDType.DRIVER_LICENSE,
            ];

            _.forEach(person.ids, (identity) => {
              if (identity.idType === IDType.PESEL) {
                owner = {
                  ...owner,
                  ownersPersonalPeselNumber: identity.idNumber ? identity.idNumber : undefined,
                };
              }
              if (_.includes(idList, identity.idType)) {
                owner = {
                  ...owner,
                  ownersPersonalProofOfIdentity: identity.idType,
                  ownersPersonalDocumentNumber: identity.idNumber,
                  ownersPersonalCountryOfDocIssuance: identity.issuingCountry,
                  ownersPersonalIssueAgency: identity.issuingAgency,
                  ownersIdExpiryDate: identity.expiryDate
                    ? parseDateOfBirth(identity.expiryDate)
                    : undefined,
                };
              }
            });
          } else {
            owner = {
              ...owner,
              ownersIDType: id && id.idType ? id.idType : undefined,
              ownersSSN: id && id.idType === IDType.ID_CARD ? id.idNumber : undefined,
              ownersITIN: id && id.idType === IDType.ITIN ? id.idNumber : undefined,
            };
          }

          if (person.positions) {
            if (_.has(person.positions, PositionType.DIRECTOR)) {
              owner = {
                ...owner,
                ownersPersonalDirectorQuestion: person.positions[PositionType.DIRECTOR] === true ? 'true' : 'false',
              };
            }
          }
        }
        values.owners.push(owner);
      }
    }
  }
  return values;
}

export function getPersonalProfileStateFromApplication(application: SelfboardApplication): PersonalProfileState {
  let values: PersonalProfileState = {};
  if (application.scarecrowApplication && application.scarecrowApplication.principal) {
    const { principal } = application.scarecrowApplication;
    if (principal.contactInfo) {
      const { contactInfo } = principal;
      if (contactInfo.address) {
        const { address } = contactInfo;

        values = {
          ...values,
          personalStreetNumber: address.streetNumber ? address.streetNumber : undefined,
          streetName: address.streetName ? address.streetName : undefined,
          lineTwo: address.lineTwo ? address.lineTwo : undefined,
          city: address.city ? address.city : undefined,
          postCode: address.postCode ? address.postCode : undefined,
          country: address.country ? address.country : undefined,
          state: address.state ? address.state : undefined,
        };
      }
      if (contactInfo.phone) {
        values = {
          ...values,
          personalPhoneNumber: contactInfo.phone,
        };
      }
      values = {
        ...values,
        personalEmailAddress: contactInfo.emailAddress ? contactInfo.emailAddress : undefined,
      };
    }
    if (principal.dob) {
      values = {
        ...values,
        dob: parseDateOfBirth(principal.dob),
      };
    }
    if (_.has(principal.positions, PositionType.DIRECTOR)) {
      values = {
        ...values,
        personalDirectorQuestion: principal.positions[PositionType.DIRECTOR] === true ? 'true' : 'false',
      };
    }
    if (principal.ids) {
      const { ids } = principal;

      if (ids.length >= 1) {
        _.forEach(ids, (id) => {
          if (application.scarecrowApplication && application.scarecrowApplication.country === 'USA') {
            if (id.idType === IDType.ID_CARD) {
              values = {
                ...values,
                personalIDType: id.idType,
                personalSSN: id.idNumber ? id.idNumber : undefined,
              };
            }
          }
          if (id.idType === IDType.ITIN) {
            values = {
              ...values,
              personalIDType: id.idType,
              personalITIN: id.idNumber ? id.idNumber : undefined,
            };
          }
          if (id.idType === IDType.PESEL) {
            values = {
              ...values,
              personalPeselNumber: id.idNumber ? id.idNumber : undefined,
            };
          }
          if (
            application.scarecrowApplication
                        && !(application.scarecrowApplication.country === 'USA')
                        && (id.idType === IDType.ID_CARD
                            || id.idType === IDType.KARTA_POBYTU
                            || id.idType === IDType.PASSPORT
                            || id.idType === IDType.DRIVER_LICENSE)
          ) {
            values = {
              ...values,
              personalProofOfIdentity: id.idType,
              personalDocumentNumber: id.idNumber,
              personalCountryOfDocIssuance: id.issuingCountry,
              personalIssueAgency: id.issuingAgency,
              idIssueDate: id.issueDate ? parseDateOfBirth(id.issueDate) : undefined,
              idExpiryDate: id.expiryDate ? parseDateOfBirth(id.expiryDate) : undefined,
            };
          }
        });
      } else if (
        application.scarecrowApplication
                && !(application.scarecrowApplication.country === 'USA')
                && values.personalCountryOfDocIssuance === undefined
      ) {
        values = {
          ...values,
          personalCountryOfDocIssuance: application.scarecrowApplication.country,
        };
      }
    }
    if (principal.ownershipPct) {
      values = {
        ...values,
        personalOwnersPercentage: principal.ownershipPct,
      };
    }

    if (principal.name) {
      const { name } = principal;
      values = {
        ...values,
        firstName: name.firstName ? name.firstName : undefined,
        middleName: name.middleName ? name.middleName : undefined,
        lastName: name.lastName ? name.lastName : undefined,
      };
    }

    values = {
      ...values,
      personalPrincipleTitle: principal.titleType,
      personalTitleOther: principal.title,
      countryOfCitizenship: principal.primaryNationality
        ? principal.primaryNationality
        : undefined,
    };
  }
  return values;
}

function parseDateOfBirth(dob): string | undefined {
  let date;
  if (dob && dob.day && dob.month && dob.year) {
    const month = Number(Month[dob.month]) < 10 ? `0${Month[dob.month]}` : Month[dob.month];
    date = `${dob.year}-${month}-`;
    if (dob.day < 10) {
      date = `${date}0${dob.day}`;
    } else {
      date += dob.day;
    }
  }
  return date;
}

export function getPlaceHolderStateFromApplication(application: SelfboardApplication): Partial<PlaceHolderFormState> {
  let toReturn: Partial<PlaceHolderFormState> = {};
  if (application.scarecrowApplication && application.scarecrowApplication.businessInfo) {
    toReturn.mccCode = application.scarecrowApplication.businessInfo.mccCode;
  }
  if (application.uiStateMap) {
    toReturn = {
      ...toReturn,
      activeSection: application.uiStateMap.activeSection,
      businessAddressDropDown: application.uiStateMap.businessAddressDropDown,
      businessCustomerDropDown: application.uiStateMap.businessCustomerDropDown,
      businessLegalAddressSelection: application.uiStateMap.businessLegalAddressSelection,
      businessMCC: application.uiStateMap.businessMCC,
      numberOfOwners: application.uiStateMap.numberOfOwners && application.uiStateMap.numberOfOwners !== 'NaN'
        ? application.uiStateMap.numberOfOwners : '0',
      isTaxIdSSN: application.uiStateMap.isTaxIdSSN,
      ownershipType: application.uiStateMap.ownershipType,
      bankName: application.uiStateMap.bankName,
      routingNumStatus: application.uiStateMap.routingNumStatus,
      hasEIN: application.uiStateMap.hasEin,
      hasIban: application.uiStateMap.hasIban,
      responsibleParty: application.uiStateMap.responsibleParty,
    };
  }
  if (application.bundleOfferCode) {
    toReturn.bundleOfferCode = application.bundleOfferCode;
  }
  if (application.bankAccountVerifications && application.bankAccountVerifications[BankAccountTypes.DEPOSIT]) {
    toReturn.bankBranchName = application.bankAccountVerifications[BankAccountTypes.DEPOSIT].bankBranch;
    toReturn.bankName = application.bankAccountVerifications[BankAccountTypes.DEPOSIT].bankName;
  }
  if (
    application.scarecrowApplication
        && application.scarecrowApplication.bankAccounts
        && application.scarecrowApplication.bankAccounts.DEPOSIT
        && application.scarecrowApplication.country === 'NOR'
  ) {
    let bankNumber = '';
    if (
      application.scarecrowApplication.bankAccounts.DEPOSIT.sortCode
            && application.scarecrowApplication.bankAccounts.DEPOSIT.accountNumber
    ) {
      bankNumber = application.scarecrowApplication.bankAccounts.DEPOSIT.sortCode
                + application.scarecrowApplication.bankAccounts.DEPOSIT.accountNumber;
    }
    toReturn.bankAccountNumber = bankNumber;
  }

  return toReturn;
}

export function getJurisdictionInfoStateFromApplication(application: SelfboardApplication): JurisdictionInfoState {
  let values: JurisdictionInfoState = {};
  if (application.brandCode && application.region) {
    values = {
      ...values,
      brandCode: application.brandCode,
      region: application.region,
    };
  }
  if (application.scarecrowApplication) {
    values = {
      ...values,
      referrerName: application.scarecrowApplication.referrerName,
      country: application.scarecrowApplication.country,
    };
  }
  if (application.subJurisdictionCode) {
    values = {
      ...values,
      subJurisdictionCode: application.subJurisdictionCode,
    };
  }
  if (application.subJurisdictionId) {
    values = {
      ...values,
      subJurisdictionId: application.subJurisdictionId,
    };
  }

  return values;
}
