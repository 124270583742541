enum IDType {
  PASSPORT = 'PASSPORT',
  ID_CARD = 'ID_CARD',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  SIN = 'SIN',
  ITIN = 'ITIN',
  FOREIGN_PASSPORT = 'FOREIGN_PASSPORT',
  RESIDENT_ALIEN_CARD = 'RESIDENT_ALIEN_CARD',
  GOVERNMENT_ISSUED_ID = 'GOVERNMENT_ISSUED_ID',
  PESEL = 'PESEL',
  ALIEN_PASSPORT = 'ALIEN_PASSPORT',
  BANK_CARD = 'BANK_CARD',
  ELECTRONIC_FOREIGNER_CARD = 'ELECTRONIC_FOREIGNER_CARD',
  EU_RESIDENCE_CARD_FIVE_YEAR = 'EU_RESIDENCE_CARD_FIVE_YEAR',
  KARTA_POBYTU = 'KARTA_POBYTU',
  FIREARMS_CERTIFICATE = 'FIREARMS_CERTIFICATE',
  NORTH_IRELAND_ELECTORAL_CARD = 'NORTH_IRELAND_ELECTORAL_CARD',
  PASSPORT_CARD = 'PASSPORT_CARD',
  SPANISH_RESIDENT_CARD = 'SPANISH_RESIDENT_CARD',
  OTHER_GOVERNMENT_PHOTO_ID = 'OTHER_GOVERNMENT_PHOTO_ID',
  OTHER_EFCC_APPROVED_DOCUMENT = 'OTHER_EFCC_APPROVED_DOCUMENT',
  CANADIAN_PASSPORT = 'CANADIAN_PASSPORT',
}
export default IDType;
