import OwnershipType, { isOwnershipTaxExempt } from '../models/OwnershipType';
import { AppState } from '../reducers';
import isEmpty from './IsEmpty';

export default function shouldCustomerExit(state: AppState): boolean {
  switch (state.JurisdictionInfo.country) {
    case 'POL':
      return (
        polExitConditions(state)
                    && jurisdictionSpecificInfoFetchComplete(state)
      );
    case 'USA':
      return (
        usaExitConditions(state)
                && jurisdictionSpecificInfoFetchComplete(state)
      );
    case 'CAN':
      return (
        canExitConditions(state)
                && jurisdictionSpecificInfoFetchComplete(state)
      );
    case 'NOR':
      return (
        (state.placeHolderForm.ownershipType === 'OTHER'
                    || state.placeHolderForm.responsibleParty === 'false'
                    || state.placeHolderForm.authorizedSigner === 'false'
                    || state.aboutBusinessForm.businessPercentageQuestion === 'false')
                    && jurisdictionSpecificInfoFetchComplete(state)
      );
    case 'GBR':
      return (
        (state.aboutBusinessForm.businessVatNumberQuestion !== 'true'
                    || state.placeHolderForm.ownershipType === 'OTHER'
                    || state.placeHolderForm.responsibleParty === 'false'
                    || state.aboutBusinessForm.businessPercentageQuestion === 'false')
                    && jurisdictionSpecificInfoFetchComplete(state)
      );
    default:
      return true;
  }
}

export function shouldShowNavButtons(state: AppState): boolean {
  switch (state.JurisdictionInfo.country) {
    case 'USA':
    case 'CAN':
      if (isActiveGetStartedPage(state)) {
        return !naExitConditions(state);
      }
      // eslint-disable-next-line no-fallthrough
    default:
      return !shouldCustomerExit(state);
  }
}

export function usaExitConditions(state) {
  return (
    Number(state.personalProfileForm.personalOwnersPercentage) < (isOwnershipTaxExempt(state.placeHolderForm.ownershipType) ? 0 : 25)
        || naExitConditions(state)
  );
}

export function gbrExitConditions(state) {
  return (
    state.aboutBusinessForm.businessVatNumberQuestion !== 'true'
        || state.placeHolderForm.ownershipType === 'OTHER'
        || state.placeHolderForm.responsibleParty === 'false'
        || state.aboutBusinessForm.businessPercentageQuestion === 'false'
  );
}

export function polExitConditions(state) {
  return (
    state.aboutBusinessForm.businessVatNumberQuestion === 'false'
        || state.placeHolderForm.ownershipType === OwnershipType.OTHER
        || state.aboutBusinessForm.showSearchingProgress === 'true'
        || (
          state.placeHolderForm.ownershipType === OwnershipType.SOLE_TRADER
            && state.aboutBusinessForm.businessPercentageQuestion === 'false'
        )
  );
}

export function norExitConditions(state) {
  return (
    state.placeHolderForm.ownershipType === 'OTHER'
        || state.placeHolderForm.responsibleParty === 'false'
        || state.placeHolderForm.authorizedSigner === 'false'
        || state.aboutBusinessForm.businessPercentageQuestion === 'false'
  );
}

export function canExitConditions(state) {
  return (
    Number(state.personalProfileForm.personalOwnersPercentage) < (isOwnershipTaxExempt(state.placeHolderForm.ownershipType) ? 0 : 25)
        || naExitConditions(state)
  );
}

export function jurisdictionSpecificInfoFetchComplete(state) {
  return (
    (state.placeHolderForm.jurisdictionSpecificFetchComplete === true
            || state.placeHolderForm.jurisdictionSpecificContactInfoError
                === true)
        && isEmpty(state.placeHolderForm.jurisdictionSpecificContactUsInfo)
  );
}

export function jurisdictionSpecificInfoAvailable(state) {
  return (
    state.placeHolderForm.jurisdictionSpecificFetchComplete === true
        && !isEmpty(state.placeHolderForm.jurisdictionSpecificContactUsInfo)
  );
}

const naExitConditions = (state) => (
  state.placeHolderForm.ownershipType === 'OTHER'
        || state.aboutBusinessForm.isUsBusiness === 'false'
        || state.placeHolderForm.responsibleParty === 'false'
);

const isActiveGetStartedPage = (state) => {
  const activePage = state.DataEntryForm.sections.find(({ active }) => active === true);
  if (activePage && !isEmpty(activePage.formLayout)) {
    const formName = activePage.formLayout.name;
    return !isEmpty(formName) && formName === 'aboutBusinessForm';
  }
  return false;
};
