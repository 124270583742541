import { MuiThemeProvider } from '@material-ui/core';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import { AnyAction, applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import ConnectedCustomerFlow from './containers/customer/ConnectedCustomerFlow';
import ErrorBoundaryContainer from './containers/ErrorBoundaryContainer';
import rootEpic from './effects/customer_ui';
import { CookiePolicy } from './models/CookiePolicy';
import combinedReducers, { CustomerAppState } from './reducers/customer_ui';
import './registerIndex.css';
import ARHttp from './services/ARHttp';
import QueryParameterService from './services/QueryParameterService';
import { theme } from './theme/theme';
import ScarecrowPropertiesService from './services/ScarecrowPropertiesService';

require('./signatures.css');

/**
 * Index file for the Assisted Signature Flow, also referred to as the Customer UI.
 */

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, CustomerAppState, any>();
const store = createStore(combinedReducers, applyMiddleware(epicMiddleware));
const queryParameterService = new QueryParameterService(store);

init();

function init(): void {
  epicMiddleware.run(rootEpic);
  const params = new URLSearchParams(window.location.search);
  const locale = params.get('userLanguage') || undefined;
  const isCountry = (theLocale, country) => theLocale.slice(3, 5) === country;
  const isLocaleDefined = typeof (locale) !== 'undefined';
  const isLocaleNorthAmerican = isLocaleDefined && (isCountry(locale, 'US') || isCountry(locale, 'CA'));
  const isLocaleEuropean = isLocaleDefined && !isLocaleNorthAmerican;

  ARHttp({
    method: 'POST',
    url: '/scwebapi/props',
  }).subscribe(
    (res: any) => {
      ScarecrowPropertiesService.setProps(res);
    },
  );
  if (isLocaleEuropean) {
    store.dispatch({ type: 'SET_COOKIE_POLICY', payload: getCookiePolicy() });
  }
  queryParameterService.handleQueryParams(window.location.search);

  testExistingCredentials();

  bootstrapReact();

  if (process.env.NODE_ENV === 'development') {
    enableDebugLog();
  }
}

function testExistingCredentials() {
  const whoamiCall = ARHttp({
    method: 'POST',
    url: '/aws/env/whoami',
  }).pipe(
    map((response: any) => ({
      type: 'WHOAMI_SUCCESS',
      payload: response.userName,
    })),
    catchError(() => of({ type: 'WHOAMI_FAILURE' })),
    take(1),
  );

  const touchCall = ARHttp({
    method: 'POST',
    url: '/aws/env/touch',
  }).pipe(
    map((response: any) => ({
      type: 'TOUCH_SUCCESS',
      payload: response.token,
    })),
    catchError(() => of({ type: 'TOUCH_FAILURE' })),
    take(1),
  );

  forkJoin([whoamiCall, touchCall]).subscribe(([whoamiResults, touchResults]) => {
    store.dispatch(whoamiResults);
    store.dispatch(touchResults);
    store.dispatch({ type: 'INITIAL_TEST_COMPLETE' });
  });
}

function bootstrapReact() {
  ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ErrorBoundaryContainer>
          <MemoryRouter>
            <ConnectedCustomerFlow />
          </MemoryRouter>
        </ErrorBoundaryContainer>
      </MuiThemeProvider>
    </Provider>,
    document.getElementById('self-board-root'),
  );
}

function enableDebugLog() {
  store.subscribe(() => {
    // eslint-disable-next-line no-console
    console.info(store.getState());
  });
}

function getCookiePolicy(): CookiePolicy {
  const cookiePolicy = document.cookie.split('cookiePolicy=');
  if (cookiePolicy.length === 2) {
    const obj = JSON.parse(cookiePolicy[1].split(';')[0]);
    if (obj) {
      return {
        analytics: obj.analytics,
        essential: obj.essential,
      } as CookiePolicy;
    }
  }
  return {} as CookiePolicy;
}

const target = document.querySelector('#self-board-root');
if (target) {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.attributeName === 'aria-hidden') {
        if (target.hasAttribute('aria-hidden') && target.getAttribute('aria-hidden') === 'true') {
          target.setAttribute('aria-hidden', 'false');
        }
      }
    });
  });
  const config = { attributes: true };
  observer.observe(target, config);
}
