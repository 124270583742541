import { getSettings } from './Settings';

function convertImages(image) {
  if (image.includes('data:image/png;base64,')) {
    return image;
  } if (getSettings().host) {
    return getSettings().host + image;
  }
  return image;
}

export default convertImages;
