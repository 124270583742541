const lowercasePattern = '.*[a-z].*';
const uppercasePattern = '.*[A-Z].*';
const digitPattern = '.*[0-9].*';
const specialPattern = '.*[!#$%&*].*';

const consumeCharsBeforeHyphenPattern = '[^-]*$';

const validPasswordCharsPattern = '^[A-Za-z0-9!#$&*]+$';

const canadianPostalCodePattern = '^[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]$';

const lowercaseRegex = new RegExp(lowercasePattern);
const uppercaseRegex = new RegExp(uppercasePattern);
const digitsRegex = new RegExp(digitPattern);
const specialCharsRegex = new RegExp(specialPattern);

const validPasswordCharsRegex = new RegExp(validPasswordCharsPattern);

const canadianPostalCodeRegex = new RegExp(canadianPostalCodePattern);

const consumeCharsBeforeHyphenRegex = new RegExp(consumeCharsBeforeHyphenPattern);

export default {
  lowercaseRegex,
  uppercaseRegex,
  digitsRegex,
  specialCharsRegex,
  validPasswordCharsRegex,
  canadianPostalCodeRegex,
  consumeCharsBeforeHyphenRegex,
};
