import { Button, FormControl, Grid } from '@material-ui/core';
import { StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';
import { CheckCircle, ChevronLeft } from '@material-ui/icons';
import * as React from 'react';

interface Props extends WithStyles {
  handleClick: () => void;
  initials: string;
}
interface State {
  initialed: boolean;
}

const styles: StyleRules = {
  input: {
    width: '100%',
    marginTop: '16px',
    marginLeft: '0px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: '0',
    fontSize: '1rem',
    paddingBottom: '2px',
    borderBottom: '1px solid rgba(0, 0, 0, .42)',
    fontFamily: 'kristen, kristen_ie, serif',
  },
  completeCheck: {
    position: 'absolute',
    right: '5px',
    top: '20px',
    color: '#07c67e',
    height: '16px',
    width: '16px',
  },
};

class InitialsDisclosure extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initialed: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  public render() {
    const { classes, initials } = this.props;
    if (this.state.initialed) {
      return (
        <Grid container spacing={2}>
          <Grid item md={3}>
            <FormControl fullWidth>
              <div>
                <input
                  id="signaturePiece"
                  className={classes.input}
                  type="text"
                  disabled
                  value={initials}
                />
                <CheckCircle className={classes.completeCheck} />
              </div>
            </FormControl>
          </Grid>
        </Grid>
      );
    }

    return (
      <Button variant="contained" color="primary" onClick={this.handleClick}>
        <ChevronLeft />
        <span>Click To Initial</span>
      </Button>
    );
  }

  private handleClick() {
    this.setState({
      initialed: true,
    });
    this.props.handleClick();
  }
}

export default withStyles(styles)(InitialsDisclosure);
