import {
  Card, CardHeader, FormControl, FormHelperText, Grid, InputLabel,
} from '@material-ui/core';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import i18next from 'i18next';
import * as _ from 'lodash';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import ARHttp from '../../services/ARHttp';

interface Props {
  optionalGetSignersUrl?: string;
  appId: number;
  onError?: () => void;
  fontNames: string[];
}

interface SignerItem {
  firstName: string;
  middleName: string;
  lastName: string;
  fontType: string;
  signerId: string;
  state: string;
  signerActionTimestamp: number;
  itSigner: boolean;
}

interface GuidedFlowSigners {
  appId: number;
  signers: SignerItem[];
}

interface State {
  signatures?: GuidedFlowSigners[];
  signers?: SignerItem[];
}

const styles: StyleRules = {
  inputSigned: {
    width: '100%',
    marginTop: '16px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: '0',
    fontSize: '1rem',
    paddingBottom: '2px',
    borderBottom: '2px solid rgba(220, 220, 220)',
  },
  inputUnSigned: {
    width: '100%',
    marginTop: '16px',
    border: '0',
    fontSize: '1rem',
    paddingBottom: '2px',
    borderBottom: '2px solid rgba(220, 220, 220)',
    backgroundColor: 'white',
  },
  completeCheck: {
    position: 'absolute',
    right: '5px',
    top: '20px',
    color: '#07c67e',
    height: '16px',
    width: '16px',
  },
};

class PacketMultiSigners extends Component<Props & StyledComponentProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      signatures: [],
      signers: [],
    };
  }

  public componentDidMount() {
    if (this.props.optionalGetSignersUrl) {
      ARHttp({
        method: 'POST',
        url: this.props.optionalGetSignersUrl,
      }).subscribe((response: any) => {
        this.setState({
          signers: response.signers,
        });
      });
    } else {
      ARHttp({
        method: 'POST',
        url: '/psfwebapi/documentsignature/getsignersguidedflow',
        body: {
          appId: this.props.appId,
        },
      }).subscribe((response: any) => {
        this.setState({
          ...this.state,
          signatures: response.signatures,
        });
      });
    }
  }

  public render() {
    const classes = this.props.classes!;
    const placeholder = i18next.t('pending_signature');

    const additionalSignatures: any[] = [];

    let signers;

    if (this.props.optionalGetSignersUrl) {
      signers = this.state.signers;
    } else {
      const signatureFound = _.find(this.state.signatures, (signature: GuidedFlowSigners) => signature.appId === this.props.appId);
      if (signatureFound) {
        signers = signatureFound.signers;
      } else {
        signers = [];
      }
    }

    _.forEach(signers, (signer) => {
      if (!signer.itSigner) {
        let fullName = `${signer.firstName} `;
        fullName += (_.isEmpty(signer.middleName)) ? signer.lastName : `${signer.middleName} ${signer.lastName}`;
        // const dateSigned = moment(signer.signerActionTimestamp).format("DD-MMM-YYYY hh:mm:ss A");

        const unsigned = (
          <Grid key={`item${fullName}`} container spacing={2}>
            <Grid item md={6} style={{ padding: '20px', marginBottom: '15px' }}>
              <InputLabel htmlFor="signaturePiece" shrink style={{ color: 'black' }}>
                <Trans>signature</Trans>
              </InputLabel>
              <FormControl className={classes.formControl} fullWidth>
                <input
                  id="signaturePiece"
                  className={classes.inputUnSigned}
                  disabled
                  type="text"
                  value=""
                  placeholder={`${placeholder} ${fullName}`}
                />
              </FormControl>
            </Grid>
          </Grid>
        );

        const signed = (
          <Grid key={`item${fullName}`} container spacing={2}>
            <Grid item md={6} style={{ padding: '20px', marginBottom: '15px' }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="signaturePiece" shrink style={{ color: 'black' }}>
                  <Trans>signature</Trans>
                </InputLabel>
                <div>
                  <input
                    id="signaturePiece"
                    className={classes.inputSigned}
                    type="text"
                    disabled
                    value={fullName}
                    style={{ fontFamily: signer.fontType }}
                  />
                  <CheckCircle className={classes.completeCheck} />
                </div>
                <FormHelperText style={{ color: 'black' }}>
                  <Trans values={{ fullName }}>digital_signature_caption</Trans>
                </FormHelperText>
                <FormHelperText style={{ color: 'black' }}>
                  <Trans values={{ date: this.dateTimeSigned(signer) }}>
                    digital_signature_date
                  </Trans>
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        );

        additionalSignatures.push(this.hasSigned(signer) ? signed : unsigned);
      }
    });

    return additionalSignatures.length > 0 ? (
      <Grid container>
        <Card style={{ width: '100%' }}>
          <CardHeader title="Additional Signers" />
          {additionalSignatures}
        </Card>
      </Grid>
    ) : (<>{undefined}</>);
  }

  private dateTimeSigned(item: SignerItem): string {
    if (!item.signerActionTimestamp || (item.state !== 'SIGNED')) {
      return '';
    }
    const signDate = new Date(item.signerActionTimestamp);
    return signDate.toLocaleString(); // browser local specific
  }

  private hasSigned(item: SignerItem): boolean {
    return item.state === 'SIGNED';
  }
}
export default withStyles(styles)(PacketMultiSigners);
