import {
  createStyles, Typography, WithStyles, withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { Component } from 'react';
import { Trans } from 'react-i18next';

interface Props extends WithStyles {
  id: string;
  label: string;
  subtext?: string;
  tooltip?: string;
  icon?: string;
  extras: Extras;
  shouldRender?: boolean;
}

interface Extras {
  bulleted?: boolean,
  indent?: boolean,
  header?: boolean,
}

const style = (theme) => createStyles({
  margins: {
    marginTop: '0',
    marginBottom: '0',
  },
  indent: {
    paddingLeft: '.7em',
  },
  header: {
    fontSize: '.9rem',
    fontWeight: 'bold',
  },
});

class FormText extends Component<Props, any> {
  public render() {
    const {
      classes, extras, label, shouldRender,
    } = this.props;

    let className = extras.indent ? [classes.margins, classes.indent].join(' ') : classes.margins;
    className = extras.header ? [className, classes.header].join(' ') : className;

    return shouldRender || shouldRender === undefined ? (
      <Typography variant={extras.header ? 'h2' : 'body2'} className={className}>
        <Trans>
          {extras.bulleted ? '• ' : undefined}
          {label}
        </Trans>
      </Typography>
    ) : null;
  }
}

export default withStyles(style)(FormText);
