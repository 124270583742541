import { Card, CardContent, CardHeader } from '@material-ui/core';
import * as React from 'react';

// TODO: Replace strings with string keys. Should also have them translated.
export default class SignaturePageGeneralFailure extends React.Component {
  public render() {
    return (
      <Card>
        <CardHeader title="Something went wrong..." role="heading" aria-level={1} />
        <CardContent>
          <p>Something on this page has gone wrong. You can try again in a few minutes.</p>
          <p>Our engineers have been notified.</p>
        </CardContent>
      </Card>
    );
  }
}
