import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { ajax, AjaxRequest } from 'rxjs/ajax';
import { map, share } from 'rxjs/operators';
import { getHttpConfig } from './ARHttpConfig';
import { getSettings } from './Settings';

export interface ScarecrowRequest extends AjaxRequest {
  method: 'POST' | 'GET';
  url: string;
  body?: any;
  headers?: any;
  cache?: boolean;
  noErrorHandling?: boolean;
}

export interface ScarecrowResponse {
  responseId: number;
  error?: any;
  body?: any;
}

const cache: Map<string, Observable<ScarecrowResponse>> = new Map<string, Observable<ScarecrowResponse>>();

export default function ARHttp(options: ScarecrowRequest): Observable<ScarecrowResponse> {
  if (options.method === 'POST') {
    options.headers = _.assign(options.headers, { 'Content-Type': 'application/json' });
  }

  const config = getHttpConfig();

  if (config.usePortalSettings) {
    const settings = getSettings();

    if (settings.host) {
      options.url = settings.host + options.url;
    }
    if (settings.tokenKey) {
      options.headers = _.assign(options.headers, { authorization: window.sessionStorage.getItem(settings.tokenKey) });
    }
  }

  if (options.cache) {
    const key = JSON.stringify(options);
    if (!cache.has(key)) {
      cache.set(key, getAjax(options));
    }
    return cache.get(key)!;
  }
  return getAjax(options);
}

function getAjax(options: ScarecrowRequest): Observable<ScarecrowResponse> {
  return ajax(options).pipe(
    map((response) => {
      if (!options.noErrorHandling) {
        if (response.response.responseId === 13) {
          throw new Error('Not Allowed');
        }

        if (response.response.responseId === 9) {
          throw new Error('Maximum Document Size Exceeded');
        }

        if (response.response.responseId === 10) {
          throw new Error('Unsupported Document Type');
        }

        if (response.response.responseId === 11) {
          throw new Error('Expired');
        }

        if (response.response.responseId === 1) {
          throw new Error('Invalid Role');
        }

        if (response.response.responseId === 8) {
          throw new Error('Account does not exist');
        }

        if (response.response.responseId !== 0) {
          throw new Error('Server Failure Response');
        }
      }

      if (options.cache) {
        const key = JSON.stringify(options);
        cache.set(key, of(response.response).pipe(share()));
      }
      return response.response;
    }),
    share(),
  );
}
