import {
  Card, Grid, ListItem, ListItemText, Typography,
} from '@material-ui/core';
import { createStyles, StyledComponentProps, withStyles } from '@material-ui/core/styles';
import Classnames from 'classnames';
import i18next from 'i18next';
import * as _ from 'lodash';
import * as React from 'react';
import { Trans } from 'react-i18next';
import App, { LogicalAppStatus } from '../../models/App';
import GroupApp from '../../models/GroupApp';

const forwardArrow = require('../../assets/images/forward_arrow.png');

const styles = (theme) => createStyles({
  logo: {
    padding: '16px',
    maxHeight: '64px',
    maxWidth: '140px',
  },
  card: {
    width: '100%',
    padding: '5px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  header: {
    fontSize: 'large',
  },
  container: {
    marginTop: '32px',
    [theme.breakpoints.up('sm')]: {
      padding: '24px',
    },
  },
  appListItems: {
    fontWeight: 'bold',
  },
  forwardArrowImg: {
    backgroundPositionX: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
  },
  wordWrap: {
    wordWrap: 'break-word',
  },
});

interface Props {
  appList?: App[];
  groupList?: GroupApp[];
  onSelect: (id: string, isGroup?: string) => void;
}
class ApplicationList extends React.Component<Props & StyledComponentProps> {
  public constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    let numberOfApps = 0;
    if (this.props.appList) {
      numberOfApps += this.props.appList.length;
    }
    if (this.props.groupList) {
      numberOfApps += this.props.groupList.length;
    }
    if (numberOfApps === 1) {
      if (this.props.appList && this.props.appList[0] && this.props.appList[0].id) {
        this.props.onSelect(this.props.appList[0].id, 'false');
      } else if (this.props.groupList && this.props.groupList[0] && this.props.groupList[0].id) {
        this.props.onSelect(this.props.groupList[0].id, 'true');
      }
    }
  }

  public render() {
    const classes = this.props.classes!;

    const appListItems = _.map(this.props.appList, (app: App, index: number) => this.buildListItem(
      `App: ${index}`,
      app.businessNameExtended!,
      app.logicalStatus,
      app.businessAddress!,
      app.id!,
      this.props.appList!.length - 1 === index,
      classes,
      'false',
    ));

    const groupListItemsUnarchived = _.filter(this.props.groupList, (group) => {
      const isArchived = !!group.appInfo.archived;
      return !isArchived;
    });

    const groupListItems = _.map(groupListItemsUnarchived, (group: GroupApp, index: number) => this.buildListItem(
      `GroupApp: ${index}`,
      group.appInfo.businessNameExtended!,
      group.logicalStatus,
      `${i18next.t('groups.group_size')} ${group.groupInfo.groupSize}`,
      group.id,
      groupListItemsUnarchived.length - 1 === index,
      classes,
      'true',
    ));

    const groupListRender = (
      <Grid item xs={12}>
        <Typography variant="h5">
          <Trans>groups.select_group</Trans>
        </Typography>
        <div id="groupAppList">{groupListItems}</div>
      </Grid>
    );

    const appListRender = (
      <Grid item xs={12} className={classes.cardPadding}>
        <Typography variant="h5">
          <Trans>selectApplication.title</Trans>
        </Typography>
        <div id="appList">{appListItems}</div>
      </Grid>
    );

    return (
      <Grid item xs={12}>
        <Card className={classes.container}>
          {this.props.appList && this.props.appList.length > 0 ? appListRender : undefined}
          {this.props.groupList && this.props.groupList.length > 0 ? groupListRender : undefined}
        </Card>
      </Grid>
    );
  }

  private buildListItem(
    key: string,
    title: string,
    logicalStatus: LogicalAppStatus,
    secondaryText: string,
    id: string,
    last: boolean,
    classes: Partial<Record<string, string>>,
    isGroup?: string,
  ) {
    return (
      <ListItem
        button
        divider={!last}
        key={key}
        onClick={this.onClick}
        id={id}
        data-isGroup={isGroup}
        className={classes.appListItems}
      >
        <ListItemText secondary={secondaryText} secondaryTypographyProps={{ variant: 'body2' }}>
          <Typography variant="body1" className={Classnames(classes.boldText, classes.wordWrap)}>
            {title}
          </Typography>
          <Typography variant="body1">
            <Trans i18nKey={`applicationStatus.${logicalStatus}`} />
          </Typography>
        </ListItemText>
        <img src={forwardArrow} alt="Forward Arrow" className={classes.forwardArrowImg} />
      </ListItem>
    );
  }

  private onClick(event) {
    this.props.onSelect(event.currentTarget.id, event.currentTarget.getAttribute('data-isGroup'));
  }
}

export default withStyles(styles)(ApplicationList);
