import {
  Backdrop, Button, Card, CardContent, Slide, Typography,
} from '@material-ui/core';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { Redirect } from 'react-router';
import { CookiePolicy } from '../../models/CookiePolicy';

interface Props {
  cookiePolicy: CookiePolicy;
  acceptCookies: () => void;
  removeAnalyticsCookie: () => void;
}

interface State {
  backDrop: boolean;
  backDropAcceptedCookies: boolean;
  cookiePreferencesRedirect: boolean;
}

const styles: StyleRules = {
  cookieBar: {
    top: '0%',
    width: '100%',
    margin: 0,
    backgroundColor: 'rgba(255, 255, 255)',
    color: '#FFF',
    position: 'absolute',
  },
  content: {
    margin: '0px 150px 0px 150px',
  },
  disclaimer: {
    color: '#2C7BCC',
    fontSize: '28px',
    lineHeight: '34px',
    fontWeight: 300,
  },
  text: {
    display: 'inline-block',
    color: '#58585a',
    marginBottom: '2rem',
    fontSize: '18px',
    lineHeight: '26px',
  },
  link: {
    display: 'inline-block',
    fontSize: '18px',
    lineHeight: '26px',
    textTransform: 'none',
  },
  buttons: {
    marginRight: '13px',
    backgroundColor: '#4a851f',
    marginBottom: '1rem',
    fontSize: '16px',
    fontWeight: 700,
    height: '40px',
    lineHeight: '40px',
    borderRadius: '4px',
    '& .MuiButton-label': {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    '&:hover': {
      backgroundColor: '#025c1a',
    },
    color: 'white',
    textTransform: 'none',
  },
  backdrop: {
    zIndex: 1,
    color: '#FFF',
  },
};

class CustomerFlowGDPRCookieBar extends Component<Props & StyledComponentProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      backDrop: true,
      backDropAcceptedCookies: false,
      cookiePreferencesRedirect: false,
    };
    this.acceptCookies = this.acceptCookies.bind(this);
    this.closeCookieBanner = this.closeCookieBanner.bind(this);
    this.goToCookiePreferences = this.goToCookiePreferences.bind(this);
  }

  public UNSAFE_componentWillMount() {
    if (this.props.cookiePolicy && this.props.cookiePolicy.analytics) {
      this.setState({ backDrop: false, backDropAcceptedCookies: false });
      this.props.acceptCookies();
    }
    if (!this.props.cookiePolicy || (this.props.cookiePolicy && !this.props.cookiePolicy.analytics)) {
      this.props.removeAnalyticsCookie();
    }
  }

  public render() {
    const classes = this.props.classes!;
    if (!this.props.cookiePolicy || !this.props.cookiePolicy.essential) {
      return <>{this.cookieBanner(classes)}</>;
    }
    return <>{this.cookieAcceptedBanner(classes)}</>;
  }

  private cookieBanner(classes) {
    return (
      <>
        <Backdrop className={classes.backdrop} open={this.state.backDrop} aria-hidden="false">
          <Slide
            direction="down"
            in
            mountOnEnter
            unmountOnExit
            aria-modal="true"
            aria-describedby="modal_desc"
          >
            <Card className={classes.cookieBar}>
              <CardContent className={classes.content}>
                <h1 className={classes.disclaimer}>
                  <Trans values={{ domain: document.location.hostname }}>gdprCookieUrl</Trans>
                </h1>
                <p className={classes.text} id="modal_desc">
                  <Trans i18nKey="gdprCookieDisclaimer" />
                  <br />
                  <br />
                  <Trans values={{ domain: document.location.hostname }}>gdprCookieBody</Trans>
                </p>
                <br />
                <br />
                <Button
                  id="acceptCookies"
                  variant="contained"
                  onClick={this.acceptCookies}
                  className={classes.buttons}
                  endIcon={<ChevronRight />}
                >
                  <Trans i18nKey="gdprAcceptAllCookies" />
                </Button>
                <Button
                  id="cookiePreferences"
                  variant="contained"
                  onClick={this.goToCookiePreferences}
                  className={classes.buttons}
                  endIcon={<ChevronRight />}
                >
                  <Trans i18nKey="gdprSetCookiePreferences" />
                </Button>
              </CardContent>
            </Card>
          </Slide>
        </Backdrop>
        {this.state.cookiePreferencesRedirect ? <Redirect to="/cookiepreferences" /> : null}
      </>
    );
  }

  private cookieAcceptedBanner(classes) {
    return (
      <>
        <Backdrop className={classes.backdrop} open={this.state.backDropAcceptedCookies} aria-hidden="false">
          <Slide
            direction="down"
            in
            mountOnEnter
            unmountOnExit
            aria-modal="true"
            aria-describedby="modal_desc"
          >
            <Card className={classes.cookieBar}>
              <CardContent className={classes.content}>
                <Typography className={classes.text} style={{ marginBottom: '0px' }} id="modal_desc">
                  <Trans i18nKey="gdprAcceptedCookies">
                    <Button style={{ marginTop: '-5px' }} className={classes.link} onClick={this.goToCookiePreferences} />
                  </Trans>
                </Typography>
                <Button
                  className={classes.link}
                  onClick={this.closeCookieBanner}
                  style={{ textDecoration: 'underline', float: 'right' }}
                >
                  <Trans i18nKey="hide" />
                </Button>
              </CardContent>
            </Card>
          </Slide>
        </Backdrop>
        {this.state.cookiePreferencesRedirect ? <Redirect to="/cookiepreferences" /> : null}
      </>
    );
  }

  private acceptCookies() {
    this.setState({ backDrop: false, backDropAcceptedCookies: true });
    this.props.acceptCookies();
  }

  private closeCookieBanner() {
    this.setState({ backDrop: false, backDropAcceptedCookies: false });
  }

  private goToCookiePreferences() {
    this.setState({ backDrop: false, backDropAcceptedCookies: false, cookiePreferencesRedirect: true });
  }
}

export default withStyles(styles)(CustomerFlowGDPRCookieBar);
