/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from '@material-ui/core';
import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import CustomerFlowForgotPassword from './CustomerFlowForgotPassword';

interface Props {
  country?: string;
  emailSent: boolean;
  pending: boolean;
  roleError: boolean;
  userError: boolean;
  onSubmit: (email: string) => void;
}

interface State {
  open: boolean;
}

class CustomerFlowForgotPasswordModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  public render() {
    const {
      pending, emailSent, country, roleError, userError,
    } = this.props;
    const { open } = this.state;
    return (
      <>
        <a href="javascript: void(0);" onClick={this.handleOpen} role="button">
          <Trans i18nKey="login_forgot_password" />
        </a>
        <Modal
          open={open}
          onClose={this.handleClose}
          onBackdropClick={this.handleClose}
          aria-modal="true"
          role="dialog"
        >
          <CustomerFlowForgotPassword
            onCloseModal={this.handleClose}
            onSubmit={this.onSubmit}
            pending={pending}
            emailSent={emailSent}
            country={country}

            roleError={roleError}
            userError={userError}
          />
        </Modal>
      </>
    );
  }

  private onSubmit(email: string) {
    this.props.onSubmit(email);
  }

  private handleOpen() {
    this.setState({
      open: true,
    });
  }

  private handleClose() {
    this.setState({
      open: false,
    });
  }
}

export default withTranslation()(CustomerFlowForgotPasswordModal);
