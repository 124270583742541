import {
  Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid,
} from '@material-ui/core';
import i18next from 'i18next';
import * as _ from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import EIDQuizQuestion from '../../components/review/EIDQuizQuestion';
import App from '../../models/App';
import GroupApp from '../../models/GroupApp';
import { CustomerAppState } from '../../reducers/customer_ui';
import ARHttp from '../../services/ARHttp';
import {
  customerAnswerVerificationQuiz, getCustomerVerificationQuiz, GetQuizResponse, QuizAnswer,
} from '../../services/EIDQuizService';

const mapStateToProps = (state: CustomerAppState) => ({
  applicationCountry: state.JurisdictionInfo.country,
  brand: state.JurisdictionInfo.brandCode,
  token: state.auth.ctoken,
  appId: state.rest.appId,
  faceToFace: state.rest.faceToFace,
  groupApp: state.rest.groupApp,
  app: state.rest.app,
});

const mapDispatchToProps = (dispatch) => ({
  loadJurisdictionInfo: (jurisdictionInfo) => {
    dispatch({ type: 'SET_JURISDICTION_VALUES', payload: jurisdictionInfo });
  },
});

interface Props {
  applicationCountry: string;
  brand: string;
  token: string;
  appId: string;
  groupApp: GroupApp;
  faceToFace?: string;
  app?: App;
  loadJurisdictionInfo: (jurisdictionInfo: any) => void;
}

interface State {
  quiz?: GetQuizResponse;
  quizAnswers: { [quizAnswerIndex: number]: string };
  isNextDisabled: boolean;
  redirect: boolean;
  pending: boolean;
}

class CustomerEIDContainer extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      quizAnswers: {},
      isNextDisabled: true,
      redirect: false,
      pending: true,
    };

    this.renderQuizQuestions = this.renderQuizQuestions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.next = this.next.bind(this);
  }

  public componentDidMount() {
    const id = this.props.groupApp ? this.props.groupApp.appInfo.id! : this.props.appId;
    const isThirdPartyApp = this.props.groupApp ? this.props.groupApp.thirdParty : this.props.app ? this.props.app.thirdParty : undefined;
    let eidCall = ARHttp({
      method: 'POST',
      url: '/aws/shouldshoweidquiz',
      headers: {
        'X-XSRF-TOKEN': this.props.token,
      },
      body: {
        appId: id,
      },
    });

    if (isThirdPartyApp === true) {
      eidCall = ARHttp({
        method: 'POST',
        url: '/aws/shouldshoweidquizforthirdparty',
        headers: {
          'X-XSRF-TOKEN': this.props.token,
        },
        body: {
          appId: id,
        },
      });
    }
    eidCall.subscribe((response: any) => {
      if (response.needToPresentQuiz) {
        window.document.title = i18next.t('customer_ui_quiz');
        getCustomerVerificationQuiz(
          id,
          this.props.applicationCountry,
          this.props.brand,
          this.props.token,
          isThirdPartyApp,
        ).subscribe(
          (quiz) => {
            if (quiz.quizQuestions) {
              this.setState({
                ...this.state,
                quiz,
              });
            }
          },
          undefined,
          () => {
            this.setState({
              pending: false,
            });
          },
        );
      } else {
        this.setState({ redirect: true });
      }
    });

    let getAppDetails = ARHttp({
      method: 'POST',
      url: '/aws/getappdetails',
      body: {
        appId: id,
      },
      headers: {
        'X-XSRF-TOKEN': this.props.token,
      },
    });
    if (isThirdPartyApp === true) {
      getAppDetails = ARHttp({
        method: 'POST',
        url: '/aws/getthirdpartyappdetails',
        body: {
          appId: id,
        },
        headers: {
          'X-XSRF-TOKEN': this.props.token,
        },
      });
    }
    getAppDetails.subscribe((response: any) => {
      this.props.loadJurisdictionInfo(response);
    });
  }

  public handleChange(questionNumber: number, event) {
    const newState = { ...this.state };
    newState.quizAnswers[questionNumber] = event.target.value;
    if (this.state.quiz) {
      newState.isNextDisabled = _.size(newState.quizAnswers) !== _.size(this.state.quiz.quizQuestions);
    }
    this.setState(newState);
  }

  public render() {
    if (this.state.redirect) {
      return <Redirect to="/application" />;
    }

    let content: React.ReactNode = <CircularProgress variant="indeterminate" />;
    if (!this.state.pending) {
      content = this.renderQuizQuestions(this.state.quiz);
    }
    return (
      <Card>
        <CardHeader title={i18next.t('verify_your_identity')} subheader={i18next.t('kyc_purpose')} />
        <CardContent>
          <Grid container>{content}</Grid>
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={this.next} disabled={this.state.isNextDisabled}>
            <span>
              <Trans>submit</Trans>
            </span>
          </Button>
        </CardActions>
      </Card>
    );
  }

  private renderQuizQuestions(quiz?: GetQuizResponse): React.ReactNode {
    if (quiz) {
      return <EIDQuizQuestion quiz={this.state.quiz} handleChange={this.handleChange} quizAnswers={this.state.quizAnswers} />;
    }
    return <div />;
  }

  private next() {
    const isThirdPartyApp = this.props.groupApp ? this.props.groupApp.thirdParty : this.props.app ? this.props.app.thirdParty : undefined;
    if (this.state.quiz) {
      const quizAnswers: QuizAnswer[] = _.map(this.state.quizAnswers, (quizChoiceNumber, quizAnswerNumber) => {
        const quizAnswer: QuizAnswer = {
          questionNumber: parseInt(quizAnswerNumber, 10),
          answerNumber: parseInt(quizChoiceNumber, 10),
        };
        return quizAnswer;
      });
      const id = this.props.groupApp ? this.props.groupApp.appInfo.id! : this.props.appId;
      customerAnswerVerificationQuiz(
        id,
        this.props.applicationCountry,
        this.props.brand,
        this.state.quiz.quizId,
        quizAnswers,
        this.props.token,
        isThirdPartyApp,
      ).subscribe(
        undefined, // TODO: Set submission pending to true, and show a spinner on the submit button.
        undefined,
        () => {
          this.setState({ redirect: true });
        },
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerEIDContainer);
