/* eslint-disable import/no-dynamic-require */
// This cannot be easily tested in a mock environment.
// istanbul ignore next
export function getBrandedLogo(country, brand, subJurisdictionCode) {
  let logo;
  try {
    logo = require(`../assets/images/customer/${country}/${brand}/${subJurisdictionCode}/logo.png`);
  } catch {
    try {
      logo = require(`../assets/images/customer/${country}/${brand}/logo.png`);
    } catch {
      logo = require('../assets/images/customer/logo.png');
    }
  }
  return logo;
}
