import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import ConnectedGDPRCookieBar from '../../containers/customer/ConnectedGDPRCookieBar';
import CustomerFlowHeaderMenu from './CustomerFlowHeaderMenu';
import CustomerFlowPartnerLogo from './CustomerFlowPartnerLogo';

interface Props {
  brandLogo: any;
  country: any;
}

const useStyles = makeStyles({
  header: {
    width: '100%',
    margin: 0,
  },
  logo: {
    margin: '7px 0',
    padding: '0 16px',
    maxHeight: '64px',
  },
});

function CustomerFlowHeader(props: Props) {
  const { header, logo } = useStyles();
  const { brandLogo, country } = props;

  const cookieBanner = (country === 'USA' || country === 'CAN')
    ? <></> : <ConnectedGDPRCookieBar />;

  return (
    <header>
      {cookieBanner}
      <Card className={header}>
        <CardContent>
          <Grid container>
            <Grid item xs={5}>
              <img src={brandLogo} alt="icon" className={logo} />
              <CustomerFlowPartnerLogo />
            </Grid>
            <Grid item xs={7}>
              <CustomerFlowHeaderMenu />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </header>
  );
}

export default (CustomerFlowHeader);
