// Formik and Enzyme are incompatible, which explains some of the very strange sidestepping of istanbul and ts-lint present in this file.

import { CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Formik } from 'formik';
import i18next from 'i18next';
import * as React from 'react';
import { Component } from 'react';
import { Trans } from 'react-i18next';
import RegexService from '../../services/RegexService';
import ScarecrowPropertiesService from '../../services/ScarecrowPropertiesService';

const styles: StyleRules = {
  form: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  formControl: {
    marginTop: '18px',
    marginBottom: '4px',
    minWidth: '300px',
  },
  spinner: {
    marginLeft: '10px',
    color: 'white',
  },
  errorText: {
    color: '#83380c',
  },
};

// "login_error"
// "password_expired_error"

interface Props {
  onSubmit: (username: string, password: string) => void;
  error: boolean;
  pending: boolean;
  passwordExpired: boolean;
}

interface State {
  passwordMinLength: number;
}

class AssistedLogInForm extends Component<Props & StyledComponentProps, State> {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      passwordMinLength: 14,
    };
  }

  public componentDidMount() {
    ScarecrowPropertiesService.subscribe({
      next: (function (props) {
        this.setState({
          passwordMinLength: props.loginPasswordMinLength || this.state.passwordMinLength,
        });
      }).bind(this),
    });
  }

  public render() {
    const classes = this.props.classes!;
    const submitDecorator = this.props.pending ? (
      <CircularProgress variant="indeterminate" size={15} className={classes.spinner} />
    ) : (
      <ChevronRight />
    );

    const loginError = !this.props.error ? (
      undefined
    ) : (
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.errorText} id="loginError">
          <Trans>login_error</Trans>
        </Typography>
      </Grid>
    );

    const passExpired = !this.props.passwordExpired ? (
      undefined
    ) : (
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.errorText} id="passwordExpired">
          <Trans>password_expired_error</Trans>
        </Typography>
      </Grid>
    );

    return (
      <Formik initialValues={this.generateInitialValues()} validate={this.validate} onSubmit={this.onSubmit}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className={classes.form}>
            {loginError}
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="email"
                  label={i18next.t('email_address_label')}
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={/* istanbul ignore next */ formik.touched.email && !!formik.errors.email}
                  helperText={/* istanbul ignore next */ formik.touched.email && formik.errors.email}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="pass"
                  label={i18next.t('password')}
                  type="password"
                  value={formik.values.pass}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={/* istanbul ignore next */ formik.touched.pass && !!formik.errors.pass}
                  helperText={/* istanbul ignore next */ formik.touched.pass && formik.errors.pass}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <Button variant="contained" color="primary" type="submit" disabled={this.shouldDisableSubmit(formik)}>
                  <Trans i18nKey="submit" />
                  {submitDecorator}
                </Button>
              </FormControl>
            </Grid>
            {passExpired}
          </form>
        )}
      </Formik>
    );
  }

  private validate(values) {
    let errors = {};

    if (values.email === '') {
      errors = { email: i18next.t('required') };
    }

    errors = { ...errors, ...this.validatePassword(values.pass) };
    return errors;
  }

  private validatePassword(password: string) {
    let error = {};

    const minLength = Number(this.state.passwordMinLength);
    const max = 128;

    if (password === '') {
      error = { pass: i18next.t('required_field') };
    } else if (password.length > max) {
      error = { pass: i18next.t('max_length', { max }) };
    } else if (password.toLowerCase().indexOf('password') !== -1) {
      error = { pass: i18next.t('password_cannot_contain_password') };
    } else if (!RegexService.validPasswordCharsRegex.test(password)) {
      error = { pass: i18next.t('password_invalid_characters') };
    } else if (!this.satisfiesPasswordCharCategories(password)) {
      error = { pass: i18next.t('password_complexity_error') };
    } else if (password.length < minLength) {
      error = { pass: i18next.t('min_length', { minLength }) };
    }

    return error;
  }

  private satisfiesPasswordCharCategories(password: string) {
    let count = 0;
    if (RegexService.digitsRegex.test(password)) {
      count++;
    }
    if (RegexService.lowercaseRegex.test(password)) {
      count++;
    }
    if (RegexService.uppercaseRegex.test(password)) {
      count++;
    }
    if (RegexService.specialCharsRegex.test(password)) {
      count++;
    }
    return count >= 3;
  }

  private shouldDisableSubmit(formik) {
    return !formik.isValid || !formik.dirty;
  }

  private generateInitialValues() {
    return { pass: '', email: '' };
  }

  private onSubmit(values) {
    this.props.onSubmit(values.email, values.pass);
  }
}

export default withStyles(styles)(AssistedLogInForm);
