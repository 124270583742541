import BankingInfo from './BankingInfo';

enum BankAccountTypes {
  BILLING = 'BILLING',
  DEPOSIT = 'DEPOSIT',
  LEASE = 'LEASE',
  CHARGEBACK = 'CHARGEBACK',
}
export default BankAccountTypes;

export type BankAccounts = { [accountType in BankAccountTypes]?: BankingInfo };
