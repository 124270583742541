import {
  Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid,
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Component } from 'react';
import { Trans } from 'react-i18next';

interface Props extends WithStyles {
  fullName: string;
  onAccept: (style: number) => void;
  onDecline: () => void;
  fontNames: string[];
  country: string;
  shouldShowDeclineButton: boolean;
}

interface State {
  selectedStyle: number;
  binderAgreed: boolean;
}

const styles = (theme) => createStyles({
  radioGroup: {
    marginLeft: '-14px',
  },
  radioLabel: {
    fontSize: '16px',
    marginLeft: '-10px',
    marginTop: '2px',
  },
  buttonContainer: {
    marginTop: '32px',
  },
  '@media (max-width: 766px)': {
    modalContainer: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 'none',
      margin: '16px',
    },
  },
  '@media (min-width: 767px)': {
    modalContainer: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
});

class SignatureStylePicker extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selectedStyle: 0,
      binderAgreed: false,
    };

    this.toggleBinder = this.toggleBinder.bind(this);
    this.onAccept = this.onAccept.bind(this);
  }

  public render() {
    const { classes, shouldShowDeclineButton, onDecline } = this.props;
    const { binderAgreed } = this.state;
    let signatureApprovalText: React.ReactNode;

    if (this.props.country && (this.props.country === 'USA' || this.props.country === 'CAN')) {
      signatureApprovalText = <Trans>document_signature_item_agreement_na</Trans>;
    } else if (this.props.country === 'CAN') {
      signatureApprovalText = <Trans>document_signature_item_agreement_can</Trans>;
    } else {
      signatureApprovalText = <Trans>document_signature_item_agreement</Trans>;
    }
    const signatureStylePrompt: React.ReactNode = <Trans>document_signature_style_prompt</Trans>;
    const signatureStyleText: React.ReactNode = <Trans>document_signature_text</Trans>;
    const acceptStyle: React.ReactNode = <Trans>document_signature_style_accept_label</Trans>;
    const declineStyle: React.ReactNode = <Trans>document_signature_decline_label</Trans>;

    const check = <Checkbox checked={binderAgreed} onClick={this.toggleBinder} />;

    return (
      <Card className={classes.modalContainer}>
        <CardHeader title={signatureStylePrompt} role="heading" aria-level={1} />
        <CardContent>
          <hr />
          {signatureStyleText}
          <br />
          <br />
          <FormControlLabel control={check} label={signatureApprovalText} />
          <Grid container direction="row" spacing={2} className={classes.buttonContainer}>
            <Grid item>
              <Button variant="contained" disabled={!binderAgreed} onClick={this.onAccept}>
                <span>{acceptStyle}</span>
              </Button>
            </Grid>

            {
              shouldShowDeclineButton
                            && (
                              <Grid item style={{ display: 'inherit' }}>
                                <Button variant="contained" color="secondary" onClick={onDecline}>
                                  {declineStyle}
                                </Button>
                              </Grid>
                            )
            }
          </Grid>
        </CardContent>
      </Card>
    );
  }

  private toggleBinder() {
    this.setState({
      binderAgreed: !this.state.binderAgreed,
    });
  }

  private onAccept() {
    this.props.onAccept(this.state.selectedStyle);
    this.setState({
      ...this.state,
    });
  }
}

export default withStyles(styles)(SignatureStylePicker);
