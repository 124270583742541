import { createMuiTheme, Theme } from '@material-ui/core/styles';

export const theme: Theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      color: 'primary',
    },
    MuiPaper: {},
    MuiButton: {
      color: 'primary',
    },
    MuiInput: {
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiCardContent: {
      style: {
        padding: '12px',
      },
    },
    MuiCardHeader: {
      style: {
        padding: '12px',
      },
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '2px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '2px',
      },
    },
    MuiCard: {
      root: {
        margin: '8px',
      },
    },
    MuiFormHelperText: {
      root: {
        bottom: 'auto',
        position: 'relative',
      },
    },
    MuiInput: {
      root: {
        position: 'sticky',
      },
    },
    MuiPopover: {
      paper: {
        overflowX: 'auto',
        '& ul': {
          width: 'auto !important',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0, sm: 768, md: 960, lg: 1280, xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    button: {
      fontWeight: 500,
    },
    h1: {
      fontSize: '1.8em',
    },
  },
  palette: {
    primary: {
      main: '#2c7bbc',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#565656',
      // light: '#0066ff',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    error: {
      main: '#83380c',
    },
  },
});
