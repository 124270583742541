import {
  Card, CardContent, CardHeader, Grid, Modal, Typography, CircularProgress,
} from '@material-ui/core';
import i18next from 'i18next';
import * as _ from 'lodash';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import AdditionalDocuments from '../../components/signature/AdditionalDocuments';
import DocumentSubmission from '../../components/signature/DocumentSubmission';
import SignatureStylePicker from '../../components/signature/SignatureStylePicker';
import ARDocumentUpload from '../../forms/components/ARDocumentUpload';
import ARDocumentUploadContainer, { DocumentRecord } from '../../forms/components/ARDocumentUploadContainer';
import FormText from '../../forms/components/FormText';
import Spacer from '../../forms/components/Spacer';
import conditionalRendererLookup from '../../forms/lookups/conditionalRendererLookups';
import {
  DocumentDisplayOrder,
  DocumentDisplayType,
  DocumentSignatureType,
  DocumentType,
  getDocumentDisplayString,
  getDocumentSignatureType,
} from '../../models/DocumentType';
import { TermState } from '../../models/TermState';
import { getAgreements } from '../../services/AgreementsTermsService';
import ARHttp from '../../services/ARHttp';
import ConnectedDocumentUpload from '../customer/ConnectedDocumentUpload';
import PacketMultiSigners from './PacketMultiSigners';
import SignableDocumentContainer from './SignableDocumentContainer';

interface State {
  signatureStyle?: number;
  isSignaturePickerOpen: boolean;
  documents: Document[];
  termStates?: TermState[];
  termsOfService?: boolean;
}
interface Props {
  fontNames: string[];
  fullName?: string;
  getDocumentListUrl: string;
  getDocumentUrl: string;
  submitting: boolean;
  onError: () => void;
  onSubmit: (style: number, termStates?: TermState[]) => void;
  onDecline: () => void;
  shouldShowDeclineButton: boolean;
  optionalGetSignersUrl?: string;
  country?: string;
  referrerName?: string;
  supportingDocuments?: DocumentRecord[];
  appState?: any;
  isPartnerSignatureFlow?: boolean;
  isCustomerSignatureFlow?: boolean;
  isGroupApp?: boolean;
  shouldRender?: boolean;
  shouldDisplayStylePicker?: boolean;
  appId?: number;
  isThirdPartyApp?: boolean;
  cToken?: string;
  addRequiredSignature?: (signature: DocumentSignatureType) => void;
  setSignatureStatus?: (complete: boolean) => void;
  isSelfboard?: boolean;
  brandCode: string;
  signAllCheckbox?: (document: DocumentType, cardinalNumber: number) => void;
  agreementsAllSigned?: DocumentInfo[];
  setDocuments?: (document: Document[], appId: number) => void;
  onDocumentSign?: (index: number, appId: number) => void;
  getSignableDocuments?: (appId: number) => Document[]
  allSingleAppsSigned?: boolean;
  updateTermState?: any;
  setTermState?: (termState: TermState[]) => void;
  updateSpinnerState?: boolean;
  groupAppSize?: number | undefined;
  optOut?: boolean,
  ownershipType?: string;
  isThirdPartyStandalone?: boolean;
  disableSignAll: (document: DocumentType) => boolean;
}
interface DocumentInfo {
  documentType: DocumentType;
  cardinalNumber?: number;
}

export interface Document {
  signed: boolean;
  link: string;
  documentType: DocumentType;
  signType: DocumentDisplayType;
  cardinalNumber?: number;
  partnerDocumentName?: string;
}

export default class SignaturePageContainer extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSignatureDecline = this.onSignatureDecline.bind(this);
    this.onSignatureStyleAccept = this.onSignatureStyleAccept.bind(this);
    this.updateTermState = this.updateTermState.bind(this);
    this.toggleTermsOfService = this.toggleTermsOfService.bind(this);
    this.togglecheckbox = this.togglecheckbox.bind(this);

    this.state = {
      signatureStyle: undefined,
      documents: [],
      isSignaturePickerOpen: true,
      termsOfService: false,
    };
  }

  public componentDidMount() {
    let request;
    if (this.props.isSelfboard) {
      request = ARHttp({
        method: 'POST',
        url: this.props.getDocumentListUrl,
      });
    } else {
      request = ARHttp({
        method: 'POST',
        url: this.props.getDocumentListUrl,
        headers: {
          'X-XSRF-TOKEN': this.props.cToken,
        },
        body: {
          appId: this.props.appId,
          thirdPartyApp: this.props.isThirdPartyApp,
        },
      });
    }
    request.subscribe(
      (data: any) => {
        let documents: any[] = [];
        _.map(data.documentLinkInfoList, (documentDetails) => {
          if ((documentDetails.documentCode === DocumentType.PARTNER_DOCUMENTS
                            || documentDetails.documentCode === DocumentType.PARTNER_STATIC_DOCUMENTS)
                            && !!documentDetails.userDocumentInfo && !!documentDetails.userDocumentInfo.partnerDocumentDescriptions) {
            _.map(documentDetails.userDocumentInfo.partnerDocumentDescriptions, (index, value) => {
              documents.push({
                link: `${documentDetails.link}&cardinalNumber=${value}`,
                signType: documentDetails.userDocumentInfo ? documentDetails.userDocumentInfo.signType
                  : DocumentDisplayType.NOT_SIGNABLE,
                documentType: documentDetails.documentCode,
                partnerDocumentName: index,
                cardinalNumber: value,
              });
            });
          } else {
            documents.push({
              signed: false,
              link: documentDetails.link,
              signType: documentDetails.userDocumentInfo ? documentDetails.userDocumentInfo.signType
                : DocumentDisplayType.NOT_SIGNABLE,
              documentType: documentDetails.documentCode,
            });
          }
        });
        documents = documents.sort((a, b) => DocumentDisplayOrder[a.documentType] - DocumentDisplayOrder[b.documentType]);
        const { addRequiredSignature } = this.props;
        if (this.props.isCustomerSignatureFlow) {
          _.forEach(documents, (document) => {
            if (document.signType === DocumentDisplayType.SIGNABLE) {
              const signature = getDocumentSignatureType(document.documentType);
              if (signature && addRequiredSignature) {
                addRequiredSignature(signature);
              }
            }
          });
        }
        if (this.props.setDocuments && this.props.appId) {
          this.props.setDocuments(documents, this.props.appId);
        }

        this.setState({
          ...this.state,
          documents,
        });
      },
      () => {
        this.props.onError();
      },
    );
    if (this.props.country && !((this.props.country === 'GBR' || this.props.country === 'POL') && this.props.isThirdPartyStandalone)) {
      getAgreements(
        this.props.country,
        Boolean(this.props.optOut),
        this.isMarketingDataConsent2Required(),
        this.isMarketingDataConsent2Disabled(),
      ).subscribe((termStates: TermState[]) => {
        this.setState({
          ...this.state,
          termStates,
        });
        if (this.props.setTermState) {
          this.props.setTermState(termStates);
        }
      });
    }
  }

  public render() {
    // Group signature pages should not render unless currently active.
    if (this.props.isGroupApp && !this.props.shouldRender) {
      return null;
    }

    let additionalDocuments;
    let signersContent;

    let documentList;
    if (this.props.isSelfboard || this.props.isPartnerSignatureFlow) {
      documentList = _.map(this.getSignableDocuments(), (document, i) => (
        <Fragment key={`document${i}`}>
          {i > 0 ? <hr /> : undefined}
          <SignableDocumentContainer
            document={document.documentType}
            signatureStyle={this.state.signatureStyle || 0}
            fontNames={this.props.fontNames}
            signed={document.signed}
            fullName={this.props.fullName}
            onSign={this.onDocumentSignSignature(i)}
            onError={this.props.onError}
            getDocumentUrl={this.props.getDocumentUrl}
            isPartnerSignatureFlow={this.props.isPartnerSignatureFlow}
            isCustomerSignatureFlow={this.props.isCustomerSignatureFlow}
            appId={this.props.appId}
            applicationCountry={this.props.country}
            documentKey={`document${i}`}
            cToken={this.props.cToken}
            updateSpinnerState={!!this.props.updateSpinnerState}
            cardinalNumber={document.cardinalNumber}
            disableSigning={this.waitForPowerToBind(document.documentType)}
            disableSignAll={this.props.disableSignAll}
          />
        </Fragment>
      ));
    } else if (this.props.getSignableDocuments && this.props.appId && this.props.onDocumentSign) {
      documentList = _.map(this.props.getSignableDocuments(this.props.appId), (document, i) => (
        <Fragment key={`document${i}`}>
          {i > 0 ? <hr /> : undefined}
          <SignableDocumentContainer
            document={document.documentType}
            signatureStyle={this.state.signatureStyle || 0}
            fontNames={this.props.fontNames}
            signed={document.signed}
            fullName={this.props.fullName}
            onSign={this.props.onDocumentSign!(i, this.props.appId!)}
            onError={this.props.onError}
            getDocumentUrl={this.props.getDocumentUrl}
            isPartnerSignatureFlow={this.props.isPartnerSignatureFlow}
            isCustomerSignatureFlow={this.props.isCustomerSignatureFlow}
            appId={this.props.appId}
            applicationCountry={this.props.country}
            documentKey={`document${i}`}
            cToken={this.props.cToken}
            isGroupApp={this.props.isGroupApp}
            agreementsAllSigned={this.props.agreementsAllSigned}
            updateSpinnerState={!!this.props.updateSpinnerState}
            isThirdPartyApp={this.props.isThirdPartyApp}
            signAllCheckbox={this.togglecheckbox}
            groupAppSize={this.props.groupAppSize}
            cardinalNumber={document.cardinalNumber}
            disableSigning={this.waitForPowerToBind(document.documentType)}
            disableSignAll={this.props.disableSignAll}
          />
        </Fragment>
      ));
    }

    if (this.hasAdditionalDocuments()) {
      const documentDetailsMap = _.map(this.getAdditionalDocuments(), (doc) => {
        let titleKey = getDocumentDisplayString(doc.documentType);
        if (
          this.props.country === 'CAN'
                    && (doc.documentType === DocumentType.TERMS_OF_SERVICE || doc.documentType === DocumentType.OPERATING_GUIDE)
        ) {
          if (doc.link.toLowerCase().includes('english')) {
            titleKey = `${titleKey}_english`;
          } else if (doc.link.toLowerCase().includes('french')) {
            titleKey = `${titleKey}_french`;
          }
        }
        return {
          titleKey,
          displayType: DocumentDisplayType.NOT_SIGNABLE,
          link: doc.link,
          partnerDocName: doc.partnerDocumentName,
        };
      });
      additionalDocuments = (
        <Grid item style={{ marginTop: '16px', width: '100%' }}>
          <AdditionalDocuments
            documents={documentDetailsMap}
            country={this.props.country}
            toggleTermsOfService={this.toggleTermsOfService}
            terms={this.state.termStates}
            onTermsChanged={this.updateTermState}
            cToken={this.props.cToken}
          />
        </Grid>
      );
    }

    const signaturePageHeader: React.ReactNode = <Trans>document_signature_page_header</Trans>;
    const signaturePagePrompt: React.ReactNode = <Trans>document_signature_page_prompt</Trans>;
    const documentListSpinner = (
      <div style={{ margin: '4% auto', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress variant="indeterminate" />
      </div>
    );

    const pageCardContent = this.isElavonGermany() ? (
      <CardContent>
        {documentList}
      </CardContent>
    ) : (
      <>
        <CardHeader title={signaturePageHeader} />
        <CardContent>
          {signaturePagePrompt}
          {documentList.length > 0 ? documentList : documentListSpinner}
        </CardContent>
      </>
    );
    const pageContent = (
      <Grid item style={{ marginTop: '16px' }}>
        <Card>
          {pageCardContent}
        </Card>
      </Grid>
    );

    let submitContent;
    if (!this.props.isGroupApp) {
      submitContent = (
        <Grid item style={{ marginTop: '16px' }}>
          <DocumentSubmission
            onSubmit={this.onSubmit}
            spinnerStatus={!!this.props.updateSpinnerState}
            brandCode={this.props.brandCode}
            enabled={this.props.allSingleAppsSigned ? (this.props.allSingleAppsSigned === true)
              : this.allDocumentsSigned() && this.agreementTermsChecked() && this.termsOfServiceChecked()
                            && this.hasUploadedSupportingDocuments()}
            submitting={this.props.submitting}
            isThirdPartyStandalone={this.props.isThirdPartyStandalone}
            country={this.props.country}
          />
        </Grid>
      );
    }

    let documentUpload;
    if ((this.props.isCustomerSignatureFlow && !this.props.isThirdPartyStandalone)
            || (this.props.isThirdPartyApp && !this.props.isPartnerSignatureFlow && !this.props.isThirdPartyStandalone)) {
      documentUpload = (
        <Grid container>
          <Card style={{ width: '100%' }}>
            <CardHeader title={i18next.t('upload_file')} />
            <div style={{ padding: '12px' }}>
              <Typography variant="body1">
                <Trans>upload_file_instruction</Trans>
                {' '}
                <Trans>supported_files</Trans>
              </Typography>
              <br />
              <ConnectedDocumentUpload appId={this.props.appId} country={this.props.country} isThirdPartyApp={this.props.isThirdPartyApp} />
            </div>
          </Card>
        </Grid>
      );
    } else if (this.props.isSelfboard && (this.isElavonPoland() || this.isSantanderPoland() || this.isElavonUK())) {
      const requiredSuppDocuments = this.buildEUDocumentUploadSubSection();
      documentUpload = (
        <Grid container>
          <Card style={{ width: '100%', padding: '12px' }}>
            <Typography variant="h6">
              <Trans>documents_upload_header</Trans>
            </Typography>
            <Typography variant="body1">
              <Trans>documents_upload_subheader</Trans>
            </Typography>
            <br />
            {requiredSuppDocuments}
          </Card>
        </Grid>
      );
    }

    let stylePickerModal;
    if (!this.props.isGroupApp || (this.props.isGroupApp && this.props.shouldDisplayStylePicker)) {
      stylePickerModal = (
        <Modal open={this.state.isSignaturePickerOpen}>
          <SignatureStylePicker
            onAccept={this.onSignatureStyleAccept}
            onDecline={this.onSignatureDecline}
            fullName={this.props.fullName}
            fontNames={this.props.fontNames}
            country={this.props.country}
            shouldShowDeclineButton={this.props.shouldShowDeclineButton}
          />
        </Modal>
      );
    }

    if (this.props.isThirdPartyApp && !this.props.isPartnerSignatureFlow) {
      signersContent = (
        <PacketMultiSigners
          appId={this.props.appId}
          fontNames={this.props.fontNames}
        />
      );
    }

    if (this.props.optionalGetSignersUrl) {
      signersContent = (
        <PacketMultiSigners
          appId={this.props.appId}
          fontNames={this.props.fontNames}
          optionalGetSignersUrl={this.props.optionalGetSignersUrl}
        />
      );
    }

    return (
      <>
        {stylePickerModal}
        {pageContent}
        {signersContent}
        {documentUpload}
        {additionalDocuments}
        {submitContent}
      </>
    );
  }

  private onSignatureStyleAccept(style: number) {
    this.setState({
      ...this.state,
      signatureStyle: style,
      isSignaturePickerOpen: false,
    });
  }

  private onDocumentSignSignature(index: number) {
    const documentSignedType = this.getSignableDocuments()[index].documentType;
    const { cardinalNumber } = this.getSignableDocuments()[index];

    return () => {
      this.setState(
        {
          ...this.state,
          documents: _.forEach(this.state.documents, (document) => {
            if (document.documentType === documentSignedType && document.cardinalNumber === cardinalNumber) {
              document.signed = true;
            }
          }),
        },
        () => {
          if (this.props.setSignatureStatus) {
            this.props.setSignatureStatus(this.allDocumentsSigned() && this.agreementTermsChecked());
          }
        },
      );
    };
  }

  // Power To Bind should block other documents from being signed until itself has been signed
  /* istanbul ignore next */
  private waitForPowerToBind(targetDocType: DocumentType): boolean {
    if (targetDocType !== DocumentType.POWER_TO_BIND_RESOLUTION) {
      const powerToBind = this.getSignableDocuments()
        .find(({ documentType }) => documentType === DocumentType.POWER_TO_BIND_RESOLUTION);

      if (powerToBind) {
        return !powerToBind.signed;
      }
    }
    return false;
  }

  private allDocumentsSigned(): boolean {
    return (
      _.filter(this.getSignableDocuments(), { signed: true }).length === this.getSignableDocuments().length
            && this.getSignableDocuments().length >= 1
    );
  }

  private agreementTermsChecked(): boolean {
    if (this.state.termStates) {
      let valid = true;
      _.forEach(this.state.termStates, (term) => {
        if (term.required === true && term.checked === false) {
          valid = false;
        }
      });
      return valid;
    }
    return true;
  }

  private termsOfServiceChecked(): boolean {
    if (this.requiredTermsOfService(this.props.country)) {
      if (this.state.termsOfService === true) {
        return true;
      }
      return false;
    }
    return true;
  }

  private isMarketingDataConsent2Required(): boolean {
    if (this.props.country === 'POL') {
      const ownershipTypes = ['SOLE_TRADER', 'PARTNERSHIP'];
      return _.includes(ownershipTypes, this.props.ownershipType);
    }
    return true;
  }

  private isMarketingDataConsent2Disabled(): boolean {
    if (this.props.country === 'POL') {
      return !this.isMarketingDataConsent2Required();
    }
    return false;
  }

  private hasUploadedSupportingDocuments(): boolean {
    const size = _.size(this.props.supportingDocuments);
    if (this.props.isSelfboard) {
      if (this.isElavonPoland() || this.isSantanderPoland()) {
        return size === 3;
      } if (this.isElavonUK()) {
        return size === 1;
      }
    }
    return true;
  }

  private hasAdditionalDocuments(): boolean {
    return this.getAdditionalDocuments().length > 0;
  }

  private requiredTermsOfService(country): boolean {
    return country === 'NOR';
  }

  private toggleTermsOfService() {
    this.setState({
      ...this.state,
      termsOfService: !this.state.termsOfService,
    });
  }

  private getAdditionalDocuments(): Document[] {
    return _.filter(this.state.documents, (document: Document) => (
      (document.signType === DocumentDisplayType.NOT_SIGNABLE) || !!document.cardinalNumber
    ));
  }

  private getSignableDocuments(): Document[] {
    return _.filter(this.state.documents, (document: Document) => document.signType === DocumentDisplayType.SIGNABLE);
  }

  private onSubmit() {
    this.props.onSubmit(this.state.signatureStyle || 0, this.state.termStates);
  }

  private onSignatureDecline() {
    this.props.onDecline();
  }

  private togglecheckbox(type: DocumentType, cardinalNumber: number) {
    if (this.props.signAllCheckbox) {
      this.props.signAllCheckbox(type, cardinalNumber);
    }
  }

  private updateTermState(mapping: string, value: boolean) {
    if (this.props.updateTermState) {
      this.props.updateTermState(mapping, value);
    }
    this.setState({
      termStates: _.map(this.state.termStates, (term) => {
        if (term.mapping === mapping) {
          return {
            ...term,
            checked: value,
          };
        }
        return term;
      }),
    }, () => {
      if (this.props.setSignatureStatus) {
        this.props.setSignatureStatus(this.allDocumentsSigned() && this.agreementTermsChecked());
      }
    });
  }

  private isElavonGermany(): boolean {
    return this.props.brandCode === 'ELAVON' && this.props.country === 'DEU';
  }

  private isSantanderPoland(): boolean {
    return this.props.brandCode === 'SANTANDER' && this.props.country === 'POL';
  }

  private isElavonPoland(): boolean {
    return this.props.brandCode === 'ELAVON' && this.props.country === 'POL';
  }

  private isElavonUK(): boolean {
    return this.props.brandCode === 'ELAVON' && this.props.country === 'GBR';
  }

  private buildEUDocumentUploadSubSection() {
    if (this.isElavonPoland()) {
      return (
        <>
          <FormText
            id="idProof"
            label={i18next.t('documents_upload_id_docs_all_partners_header')}
            extras={{ header: true, bulleted: true, indent: false }}
            shouldRender={conditionalRendererLookup.shouldRenderTextForPrtnrLmtdStText(this.props.appState)}
          />
          <FormText
            id="idProof"
            label={i18next.t('documents_upload_id_card_header')}
            extras={{ header: true, bulleted: true, indent: false }}
            shouldRender={conditionalRendererLookup.shouldRenderForSoleProp(this.props.appState)}
          />
          <FormText
            id="idProofException"
            label={i18next.t('documents_upload_id_docs_header')}
            extras={{ header: true, bulleted: true, indent: false }}
            shouldRender={conditionalRendererLookup.shouldRenderExceptionThreeAndFourText(this.props.appState)}
          />
          <FormText
            id="idProofInstructions"
            label={i18next.t('documents_upload_id_docs_all_partners_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
            shouldRender={conditionalRendererLookup.shouldRenderForAllOwnershipTypes(this.props.appState)}
          />
          <FormText
            id="idProofInstructions"
            label={i18next.t('documents_upload_id_card_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
            shouldRender={conditionalRendererLookup.shouldRenderForSoleProp(this.props.appState)}
          />
          <FormText
            id="residenceProof"
            label={i18next.t('documents_upload_home_address_dob_header')}
            extras={{ header: true, bulleted: true, indent: false }}
            shouldRender={conditionalRendererLookup.shouldRenderForAllOwnershipTypes(this.props.appState)}
          />
          <FormText
            id="residenceProof"
            label={i18next.t('documents_upload_proof_of_address_header')}
            extras={{ header: true, bulleted: true, indent: false }}
            shouldRender={conditionalRendererLookup.shouldRenderForSoleProp(this.props.appState)}
          />
          <FormText
            id="residenceProofInstructions"
            label={i18next.t('documents_upload_home_address_dob_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
            shouldRender={conditionalRendererLookup.shouldRenderForAllOwnershipTypes(this.props.appState)}
          />
          <FormText
            id="residenceProofInstructions"
            label={i18next.t('documents_upload_proof_of_address_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
            shouldRender={conditionalRendererLookup.shouldRenderForSoleProp(this.props.appState)}
          />
          <FormText
            id="bankingProof"
            label={i18next.t('documents_upload_proof_of_banking_header')}
            extras={{ header: true, bulleted: true, indent: false }}
            shouldRender={conditionalRendererLookup.shouldRenderForAllOwnershipTypes(this.props.appState)}
          />
          <FormText
            id="bankingProof"
            label={i18next.t('documents_upload_proof_of_banking_header')}
            extras={{ header: true, bulleted: true, indent: false }}
            shouldRender={conditionalRendererLookup.shouldRenderForSoleProp(this.props.appState)}
          />
          <FormText
            id="bankingProofInstructions"
            label={i18next.t('documents_upload_proof_of_bank_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
            shouldRender={conditionalRendererLookup
              .shouldRenderForExceptionOneTwoAndThreeIncludingSoleTrader(this.props.appState)}
          />
          <FormText
            id="bankingProofInstructions"
            label={i18next.t('documents_upload_proof_of_banking_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
            shouldRender={conditionalRendererLookup.shouldRenderForSoleProp(this.props.appState)}
          />
          <FormText
            id="bankingProofInstructionsException"
            label={i18next.t('documents_upload_proof_of_banking_exception_three_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
            shouldRender={conditionalRendererLookup.shouldRenderGeneralPartnerText(this.props.appState)}
          />
          <FormText
            id="attorneyDocs"
            label={i18next.t('documents_upload_attorney_header')}
            extras={{ header: true, bulleted: true, indent: false }}
            shouldRender={conditionalRendererLookup.shouldRenderForAllOwnershipTypes(this.props.appState)}
          />
          <FormText
            id="attorneyDocsInstructions"
            label={i18next.t('documents_upload_attorney_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
            shouldRender={conditionalRendererLookup.shouldRenderForAllOwnershipTypes(this.props.appState)}
          />
          <FormText
            id="companyAgreement"
            label={i18next.t('documents_upload_company_agreement_header')}
            extras={{ header: true, bulleted: true, indent: false }}
            shouldRender={conditionalRendererLookup
              .shouldRenderCompanyAgreementHeaderForOwnershipTypes(this.props.appState)}
          />
          <Spacer />
          <ARDocumentUploadContainer
            id="docUpload"
            label="document"
            error={false}
            fieldValue={this.props.supportingDocuments}
            extras={{ supportingDocumentsIndex: 0, reviewMode: false }}
          />
        </>
      );
    } if (this.isSantanderPoland()) {
      return (
        <>
          <FormText
            id="idProof"
            label={i18next.t('documents_upload_id_card_header')}
            extras={{ header: true, bulleted: true, indent: false }}
          />
          <FormText
            id="idProofInstructions"
            label={i18next.t('documents_upload_id_card_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
          />
          <FormText
            id="residenceProof"
            label={i18next.t('documents_upload_proof_of_address_header')}
            extras={{ header: true, bulleted: true, indent: false }}
          />
          <FormText
            id="residenceProofInstructions"
            label={i18next.t('documents_upload_proof_of_address_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
          />
          <FormText
            id="bankingProof"
            label={i18next.t('documents_upload_proof_of_banking_header')}
            extras={{ header: true, bulleted: true, indent: false }}
          />
          <FormText
            id="bankingProofInstructions"
            label={i18next.t('documents_upload_proof_of_banking_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
          />
          <Spacer />
          <ARDocumentUploadContainer
            id="docUpload"
            label="document"
            error={false}
            fieldValue={this.props.supportingDocuments}
            extras={{ supportingDocumentsIndex: 0, reviewMode: false }}
          />
        </>
      );
    } if (this.isElavonUK()) {
      return (
        <>
          <FormText
            id="bankingProof"
            label={i18next.t('documents_upload_proof_of_banking_header')}
            extras={{ header: true, bulleted: true, indent: false }}
          />
          <FormText
            id="bankingProofInstructions"
            label={i18next.t('documents_upload_proof_of_banking_subheader')}
            extras={{ header: false, bulleted: false, indent: true }}
          />
          <Spacer />
          <ARDocumentUpload
            id="DocUpload"
            label="document"
            fieldValue={this.props.supportingDocuments}
            reviewMode={false}
            extras={{ supportingDocumentsIndex: 0 }}
          />
        </>
      );
    }
  }
}
