import {
  Button, FormControl, FormHelperText, Grid, InputLabel,
} from '@material-ui/core';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import { CheckCircle, ChevronLeft } from '@material-ui/icons';
import * as moment from 'moment';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';

interface Props {
  signed: boolean;
  fullName: string;
  fontSelection: number;
  fontNames: string[];
  dateSigned?: Date;
  applicationCountry?: string;
  onSign: () => void;
  allInitialsChecked: boolean;
  hasInitials: boolean;
  readyToSign: boolean;
  disabled: boolean;
}

interface State {
  acceptedTOS: boolean;
}

const styles: StyleRules = {
  inputSigned: {
    width: '100%',
    marginTop: '16px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: '0',
    fontSize: '1rem',
    paddingBottom: '2px',
    borderBottom: '2px solid rgba(220, 220, 220)',
  },
  inputUnSigned: {
    width: '100%',
    marginTop: '16px',
    border: '0',
    fontSize: '1rem',
    paddingBottom: '2px',
    borderBottom: '2px solid rgba(220, 220, 220)',
    backgroundColor: 'white',
  },
  completeCheck: {
    position: 'absolute',
    right: '5px',
    top: '20px',
    color: '#07c67e',
    height: '16px',
    width: '16px',
  },
};

class CustomerFlowSignatureAffirmation extends Component<Props & StyledComponentProps, State> {
  constructor(props) {
    super(props);
  }

  public render() {
    const classes = this.props.classes!;
    const dateSigned = moment(this.props.dateSigned).format('DD-MMM-YYYY hh:mm:ss A');

    const readyToSign = (
      <Grid container spacing={2} style={{ padding: '20px', marginBottom: '15px' }}>
        <Grid item md={6}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="signaturePiece" shrink style={{ color: 'black' }}>
              <Trans>signature</Trans>
            </InputLabel>
            <input id="signaturePiece" className={classes.inputUnSigned} type="text" disabled value="-" />
          </FormControl>
        </Grid>
        <Grid item md={6} style={{ paddingTop: '20px', paddingLeft: '50px' }}>
          <Button variant="contained" onClick={this.props.onSign} disabled={this.props.disabled} style={{ width: '250px' }}>
            <ChevronLeft />
            <span>
              <Trans>click_to_sign</Trans>
            </span>
          </Button>
        </Grid>
      </Grid>
    );

    const signed = (
      <Grid container spacing={2}>
        <Grid item md={6} style={{ padding: '20px', marginBottom: '15px' }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="signaturePiece" shrink style={{ color: 'black' }}>
              <Trans>signature</Trans>
            </InputLabel>
            <div>
              <input
                id="signaturePiece"
                className={classes.inputSigned}
                type="text"
                disabled
                value={this.props.fullName}
                style={{ fontFamily: this.getFont(this.props.fontSelection) }}
              />
              <CheckCircle className={classes.completeCheck} />
            </div>
            <FormHelperText style={{ color: 'black' }}>
              <Trans values={{ fullName: this.props.fullName }}>digital_signature_caption</Trans>
            </FormHelperText>
            <FormHelperText style={{ color: 'black' }}>
              <Trans values={{ date: this.props.signed && dateSigned }}>
                digital_signature_date
              </Trans>
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    );

    return <>{this.props.signed ? signed : readyToSign}</>;
  }

  private getFont(style: number): string {
    return this.props.fontNames[style];
  }
}

export default withStyles(styles)(CustomerFlowSignatureAffirmation);
