/* eslint-disable jsx-a11y/anchor-is-valid */
import { IconButton, Snackbar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Close } from '@material-ui/icons';
import { History } from 'history';
import i18next from 'i18next';
import * as React from 'react';
import { Component } from 'react';
import { Trans } from 'react-i18next';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import ConnectedLanguageSelector from '../../containers/customer/ConnectedLanguageSelector';
import { SalesPersonContact } from '../../models/SalesPersonContact';
import ARHttp, { ScarecrowResponse } from '../../services/ARHttp';
import { areCookiesEnabled } from '../../services/CookieService';
import CustomerFlowRegistrationForm, { RegistrationSubmissionValues } from './CustomerFlowRegistrationForm';

interface RegistrationProps {
  country: string;
  userLanguage: string;
  createAccountToken: string;
  userAcceptedCookies: boolean;
  confCode: string;
  toggleCookieModal: () => void;
  setIsForThirdPartyAndSalesContact: (thirdParty: boolean, salesPersonContact?: SalesPersonContact) => void;
  registerSuccess: (token: string, history: History) => void;
}

interface RegistrationState {
  formHasLoaded: boolean;
  pending: boolean;
  type2Verification: boolean;
  loadingError: boolean;
  customerExists: boolean;
  isThirdPartyApp: boolean;
  confCodeExpired: boolean;
  salesPersonContact: any;
  passwordErrorKey?: string;
  emailAddress: string;
}

interface CustomerStatusResponse extends ScarecrowResponse {
  userExists: boolean;
  confCodeExpired: boolean;
  type2Verification: boolean;
  isThirdParty: boolean;
  salesPersonContact: any;
  emailAddress: string;
}

const styles: StyleRules = {
  pageContent: {
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  cardContent: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    width: 'fit-content',
  },
  link: {
    color: '#2b7bb9',
    textDecoration: 'none',
    '&:hover': {
      color: '#2a6496',
      textDecoration: 'underline',
    },
  },
  '@media (min-width: 767px)': {
    snackBar: {
      maxWidth: '100%',
    },
  },
};

class CustomerFlowRegistration extends Component<RegistrationProps & RouteComponentProps & StyledComponentProps, RegistrationState> {
  constructor(props) {
    super(props);

    this.state = {
      formHasLoaded: false,
      type2Verification: false,
      pending: false,
      loadingError: false,
      customerExists: false,
      isThirdPartyApp: false,
      salesPersonContact: { name: { firstName: '', lastName: '' }, emailAddress: '', phone: { areaCode: '', number: '' } },
      confCodeExpired: false,
      emailAddress: '',
    };

    this.submitForm = this.submitForm.bind(this);
    this.navigateToLogin = this.navigateToLogin.bind(this);
    this.closePasswordError = this.closePasswordError.bind(this);
  }

  public componentDidMount() {
    window.document.title = i18next.t('customer_ui_register');
    ARHttp({
      method: 'POST',
      url: '/aws/checkCustomerStatus',
      body: {
        createAccountTokenString: this.props.createAccountToken,
      },
      cache: false,
    }).subscribe(
      (res: CustomerStatusResponse) => {
        this.props.setIsForThirdPartyAndSalesContact(res.isThirdParty, res.salesPersonContact);
        if (res.userExists) {
          if (res.isThirdParty) {
            this.setState({ isThirdPartyApp: true });
          }
          this.setState({ customerExists: true });
        } else {
          if (res.isThirdParty) {
            this.setState({ isThirdPartyApp: true });
          }
          this.setState({
            confCodeExpired: res.confCodeExpired,
            salesPersonContact: res.salesPersonContact,
            formHasLoaded: true,
            type2Verification: res.type2Verification,
            emailAddress: res.emailAddress,
          });
        }
      },
      () => this.setState({ loadingError: true }),
    );
  }

  public render() {
    const classes = this.props.classes!;

    if (this.state.loadingError || this.state.customerExists) {
      return <Redirect to="/login" />;
    }

    window.document.title = 'Set Password';

    let languageSelector;
    if (this.props.country === 'CAN') {
      languageSelector = <ConnectedLanguageSelector />;
    }
    if (this.state.confCodeExpired && !this.state.customerExists) {
      if (this.state.salesPersonContact === undefined || this.state.salesPersonContact === null) {
        return (
          <Card className={classes.pageContent} style={{ padding: '24px', marginTop: '32px' }}>
            <CardHeader title={<Trans i18nKey="enterPassword.title" />} component="h1" />
            <CardContent className={classes.cardContent}>
              <Typography><Trans i18nKey="contactSales.body" /></Typography>
            </CardContent>
          </Card>
        );
      }
      const { firstName = '', lastName = '' } = this.state.salesPersonContact.name;
      const { areaCode = '', number: phoneNumber = '' } = this.state.salesPersonContact.phone;
      const name = `${firstName} ${lastName}`.trim();
      const phone = `${areaCode} ${phoneNumber}`.trim();
      const { emailAddress } = this.state.salesPersonContact;
      return (
        <Card className={classes.pageContent} style={{ padding: '24px', marginTop: '32px' }}>
          <CardHeader title={<Trans i18nKey="enterPassword.title" />} component="h1" />
          <CardContent className={classes.cardContent}>
            <Typography><Trans i18nKey="contactSales.body" /></Typography>
            <br />
            {name}
            ,
            {emailAddress}
            ,
            {phone}
          </CardContent>
        </Card>
      );
    } if (this.state.formHasLoaded) {
      return (
        <Card className={classes.pageContent} style={{ padding: '24px', marginTop: '32px' }}>
          <CardHeader title={<Trans i18nKey="setpassword.title" />} component="h1" />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            ContentProps={{ className: classes.snackBar }}
            open={!!this.state.passwordErrorKey}
            onClose={this.closePasswordError}
            message={<Trans i18nKey={this.state.passwordErrorKey} />}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" onClick={this.closePasswordError}>
                <Close />
              </IconButton>,
            ]}
          />
          <CardContent className={classes.cardContent}>
            { this.state.isThirdPartyApp
              ? (
                <Typography variant="body1">
                  <Trans i18nKey="setpassword.body_guided_flow_1" />
                </Typography>
              )
              : (
                <Typography variant="body1">
                  <Trans i18nKey="setpassword.body_1" />
                </Typography>
              )}
            {languageSelector}
            <CustomerFlowRegistrationForm
              pending={this.state.pending}
              isType2Verification={this.state.type2Verification}
              country={this.props.country}
              areCookiesAccepted={this.props.userAcceptedCookies}
              toggleCookieModal={this.props.toggleCookieModal}
              onSubmit={this.submitForm}
              confirmationCode={this.props.confCode}
              emailAddress={this.state.emailAddress}
            />
          </CardContent>
          <Typography variant="subtitle1">
            <span>
              <Trans>already_have_an_account</Trans>
            </span>
            {' '}
            <a href="javascript:void(0)" onClick={this.navigateToLogin} className={classes.link}>
              <Trans>sign_in</Trans>
            </a>
          </Typography>
        </Card>
      );
    }
    return (
      <Card className={classes.pageContent}>
        <CardContent className={classes.cardContent}>
          <CircularProgress variant="indeterminate" />
        </CardContent>
      </Card>
    );
  }

  private submitForm(values: RegistrationSubmissionValues) {
    if ((this.props.country !== 'CAN' && this.props.country !== 'USA') && (!areCookiesEnabled() || !this.props.userAcceptedCookies)) {
      this.props.toggleCookieModal();
      return;
    }

    this.setState({ pending: true });

    ARHttp({
      method: 'POST',
      url: '/aws/register',
      body: {
        resetId: this.props.createAccountToken,
        password: values.password,
        birthDay: values.birthDay,
        birthMonth: values.birthMonth,
        birthYear: values.birthYear,
        phoneLast4: values.phoneLast4,
        confCode: values.confirmationCode,
        userLanguage: this.props.userLanguage,
      },
    }).subscribe(
      (res: any) => {
        if (res.errorResourceKey) {
          this.setState({ passwordErrorKey: res.errorResourceKey });
        } else {
          this.props.registerSuccess(res.csrfToken, this.props.history);
        }
      },
      () => this.setState({ pending: false }),
    );
  }

  private navigateToLogin(event) {
    event.preventDefault();
    this.props.history.push('/login');
  }

  private closePasswordError() {
    this.setState({ passwordErrorKey: undefined });
  }
}

export default withRouter(withStyles(styles)(CustomerFlowRegistration) as any);
