import {
  Button, FormControl, FormHelperText, Grid, InputLabel,
} from '@material-ui/core';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import { CheckCircle, ChevronLeft } from '@material-ui/icons';
import * as moment from 'moment';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import TermsOfService from './TermsOfService';

interface Props {
  signed: boolean;
  fullName: string;
  fontSelection: number;
  fontNames: string[];
  dateSigned?: Date;
  applicationCountry?: string;
  onSign: () => void;
  documentKey: string;
  allInitialsChecked: boolean;
  hasInitials: boolean;
  disabled: boolean;
}

interface State {
  acceptedTOS: boolean;
}

const styles: StyleRules = {
  inputSigned: {
    width: '100%',
    marginTop: '16px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: '0',
    fontSize: '1rem',
    paddingBottom: '2px',
    borderBottom: '1px solid rgba(0, 0, 0, .42)',
  },
  inputUnSigned: {
    width: '100%',
    marginTop: '16px',
    backgroundColor: '#F6BEBF',
    border: '0',
    fontSize: '1rem',
    paddingBottom: '2px',
    borderBottom: '1px solid rgba(0, 0, 0, .42)',
  },
  completeCheck: {
    position: 'absolute',
    right: '5px',
    top: '20px',
    color: '#07c67e',
    height: '16px',
    width: '16px',
  },
};

class SignatureAffirmation extends Component<Props & StyledComponentProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      acceptedTOS: !this.isTOSCheckboxRequired(this.props.applicationCountry),
    };

    this.onCheck = this.onCheck.bind(this);
    this.disableClickToSign = this.disableClickToSign.bind(this);
  }

  public render() {
    const classes = this.props.classes!;
    const dateSigned = moment(this.props.dateSigned).format('DD-MMM-YYYY hh:mm:ss A');

    const acceptTOS = (
      <Grid item md={12}>
        <TermsOfService toggleTermsOfService={this.onCheck} country={this.props.applicationCountry} />
      </Grid>
    );

    const unsigned = (
      <Grid container spacing={2}>
        {this.isTOSCheckboxRequired(this.props.applicationCountry) ? acceptTOS : null}
        <Grid item md={6}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor={this.props.documentKey} shrink>
              <Trans>signature</Trans>
            </InputLabel>
            <input id={this.props.documentKey} className={classes.inputUnSigned} type="text" disabled value="-" />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <Button variant="contained" onClick={this.props.onSign} disabled={this.disableClickToSign()}>
            <ChevronLeft />
            <span>
              <Trans>click_to_sign</Trans>
            </span>
          </Button>
        </Grid>
      </Grid>
    );

    const signed = (
      <Grid container spacing={2}>
        <Grid item md={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor={this.props.documentKey} shrink>
              <Trans>signature</Trans>
            </InputLabel>
            <div>
              <input
                id={this.props.documentKey}
                className={classes.inputSigned}
                type="text"
                disabled
                value={this.props.fullName}
                style={{ fontFamily: this.getFont(this.props.fontSelection) }}
              />
              <CheckCircle className={classes.completeCheck} />
            </div>
            <FormHelperText>
              <Trans values={{ fullName: this.props.fullName }}>digital_signature_caption</Trans>
            </FormHelperText>
            <FormHelperText>
              <Trans values={{ date: this.props.signed && dateSigned }}>
                digital_signature_date
              </Trans>
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    );

    return <>{this.props.signed ? signed : unsigned}</>;
  }

  private getFont(style: number): string {
    return this.props.fontNames[style];
  }

  private onCheck() {
    this.setState({ acceptedTOS: !this.state.acceptedTOS });
  }

  private disableClickToSign() {
    if (this.props.disabled) {
      return true;
    }

    if (!this.props.hasInitials) {
      return !this.state.acceptedTOS;
    }
    return !this.state.acceptedTOS || !this.props.allInitialsChecked;
  }

  private isTOSCheckboxRequired(country: string | undefined): boolean {
    return country === 'POL';
  }
}

export default withStyles(styles)(SignatureAffirmation);
