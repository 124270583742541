enum PositionType {
  OFFICER = 'OFFICER',
  PARTNER = 'PARTNER',
  DIRECTOR = 'DIRECTOR',
  OWNER = 'OWNER',
  SECRETARY = 'SECRETARY',
  OTHER = 'OTHER',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  AUTHORIZED_SIGNER = 'AUTHORIZED_SIGNER',
  SOLE_PROP = 'SOLE_PROP',
}
export default PositionType;

export type Positions = { [accountType in PositionType]?: boolean };
