import { ListItem, ListItemText, Typography } from '@material-ui/core';
import * as React from 'react';

export enum DocumentDownloadIcon {
  FILE,
  ZIP_FILE,
  EXTERNAL_LINK,
}

interface Props {
  description: string;
  title: string;
  link: string;
  icon: DocumentDownloadIcon;
  last: boolean;
  onClick: (link: string) => void;
}

export default class DocumentDownloadItem extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  public render() {
    const {
      last, icon, description, title,
    } = this.props;
    return (
      <ListItem button divider={!last} onClick={this.onClick}>
        <img
          // eslint-disable-next-line import/no-dynamic-require
          src={require(`../../assets/images/customer/${this.getIcon(icon)}`)}
          alt="icon"
          style={{ maxHeight: '48px' }}
        />
        <ListItemText secondary={description} secondaryTypographyProps={{ variant: 'body2' }}>
          <Typography variant="body1">{title}</Typography>
        </ListItemText>
      </ListItem>
    );
  }

  public onClick() {
    this.props.onClick(this.props.link);
  }

  // We don't test data dictionaries.
  // istanbul ignore next
  private getIcon(icon: DocumentDownloadIcon) {
    switch (icon) {
      case DocumentDownloadIcon.ZIP_FILE:
        return 'zip_file.png';
      case DocumentDownloadIcon.EXTERNAL_LINK:
        return 'external_link.png';
      case DocumentDownloadIcon.FILE:
      default:
        return 'pdf_file.png';
    }
  }
}
