import { Grid } from '@material-ui/core';
import i18next from 'i18next';
import * as _ from 'lodash';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { combineLatest } from 'rxjs';
import App, { isApp, LogicalAppStatus } from '../../models/App';
import GroupApp, { isGroupApp } from '../../models/GroupApp';
import { SalesPersonContact } from '../../models/SalesPersonContact';
import ARHttp from '../../services/ARHttp';
import { getLogicalStatusFromApp, getLogicalStatusFromGroup } from '../../services/CustomerLogicalAppStatus';
import SignaturePageFailure from '../signature/SignaturePageFailure';
import SignaturePageLoading from '../signature/SignaturePageLoading';
import ApplicationSelectionInfo from './ApplicationSelectionInfo';

interface State {
  loading: boolean;
  loadingError: boolean;
  appList?: App[];
  groupList?: GroupApp[];
}

interface Props {
  cToken: string;
  userLanguage: string;
  country: string;
  user: string;
  skipToApp?: string;
  selectApplication: (id: App | GroupApp) => void;
  loadJurisdictionInfo: (jurisdictionInfo: any) => void;
  clearSkipToApp: () => void;
  appListSize: (appsListedSize: number) => void;
  clearSelectedApplication: () => void;
  setIsForThirdPartyAndSalesContact: (thirdParty: boolean, salesPersonContact?: SalesPersonContact) => void;
}

class SelectApplication extends React.Component<Props & RouteComponentProps, State> {
  public constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingError: false,
    };

    this.onSelect = this.onSelect.bind(this);
  }

  public componentDidMount() {
    window.document.title = i18next.t('customer_ui_app_list');
    this.props.clearSelectedApplication();
    combineLatest(
      ARHttp({
        method: 'POST',
        url: '/aws/dashboard/listapp',
        headers: {
          'X-XSRF-TOKEN': this.props.cToken,
        },
        body: {},
      }),
      ARHttp({
        method: 'POST',
        url: '/aws/dashboard/listgroup',
        headers: {
          'X-XSRF-TOKEN': this.props.cToken,
        },
        body: {},
      }),
      ARHttp({
        method: 'POST',
        url: '/aws/dashboard/listThirdPartyApp',
        headers: {
          'X-XSRF-TOKEN': this.props.cToken,
        },
        body: {},
      }),
      ARHttp({
        method: 'POST',
        url: '/aws/dashboard/listGroupThirdPartyApp',
        headers: {
          'X-XSRF-TOKEN': this.props.cToken,
        },
        body: {},
      }),
    ).subscribe(
      ([appListResponse, groupAppListResponse, thirdPartyListResponse, groupThirdPartyListResponse]) => {
        const appList: App[] = _.chain((appListResponse as any).apps)
          .map((app) => ({
            ...app,
            logicalStatus: getLogicalStatusFromApp(app),
            thirdParty: false,
          }))
          .filter((app) => {
            if (this.isTooOld(app)) {
              return false;
            }
            return app.logicalStatus !== LogicalAppStatus.ERROR;
          })
          .value();

        const groupList: GroupApp[] = _.chain((groupAppListResponse as any).groupAppInfoList)
          .map((group) => ({
            ...group,
            logicalStatus: getLogicalStatusFromGroup(group),
            id: group.groupInfo.groupId,
            thirdParty: false,
          }))
          .filter((app) => {
            if (this.isTooOld(app.appInfo)) {
              return false;
            }
            return app.logicalStatus !== LogicalAppStatus.ERROR;
          })
          .value();

        const thirdPartyAppList: App[] = _.chain((thirdPartyListResponse as any).apps)
          .map((thirdPartyApp) => ({
            ...thirdPartyApp,
            logicalStatus: getLogicalStatusFromApp(thirdPartyApp),
            thirdParty: true,
          }))
          .filter((app) => {
            if (this.isTooOld(app)) {
              return false;
            }
            return app.logicalStatus !== LogicalAppStatus.ERROR;
          })
          .value();

        const groupThirdPartyAppList: GroupApp[] | undefined = _.chain((groupThirdPartyListResponse as any).groups)
          .map((thirdPartyGroupApp) =>
          // third party groups apps come in slightly differently than regular groups
            ({
              appInfo: thirdPartyGroupApp.appInfo,
              groupInfo: { groupId: thirdPartyGroupApp.groupId, groupSize: thirdPartyGroupApp.appNumber },
              index: thirdPartyGroupApp.index,
              logicalStatus: getLogicalStatusFromGroup(thirdPartyGroupApp),
              thirdParty: true,
              id: `${thirdPartyGroupApp.groupId}`,
            }))
          .filter((app) => {
            if (this.isTooOld(app.appInfo)) {
              return false;
            }
            return app.logicalStatus !== LogicalAppStatus.ERROR;
          })
          .value();

        if ((groupList.length === 0 && appList.length === 0 && thirdPartyAppList.length === 0
                    && groupThirdPartyAppList.length === 0) || (appList.length !== 0 && appList[0].archived)) {
          // Show an error page. All of their apps are sort of busted.
          this.setState({
            ...this.state,
            loadingError: true,
          });
          return;
        }

        if (!_.isEmpty(appList) || !_.isEmpty(groupList)) {
          this.props.setIsForThirdPartyAndSalesContact(false);
        } else if (!_.isEmpty(thirdPartyAppList) || !_.isEmpty(groupThirdPartyAppList)) {
          this.props.setIsForThirdPartyAndSalesContact(true, !_.isEmpty(groupThirdPartyAppList)
            ? _.sortBy(groupThirdPartyAppList, (groupApp) => groupApp.appInfo.id)[groupThirdPartyAppList.length - 1]
              .appInfo.salesPersonContact
            : _.sortBy(thirdPartyAppList, (appInfo) => appInfo.id)[thirdPartyAppList.length - 1].salesPersonContact);
        }

        this.setState({
          ...this.state,
          loading: false,
          appList: this.sortApps(appList.concat(thirdPartyAppList)),
          groupList: this.sortGroups(groupList.concat(groupThirdPartyAppList)),
        });

        if (!this.state.loadingError && !this.state.loading) {
          let appId;
          let isThirdPartyApp: any = false;
          if (this.state.appList && this.state.appList.length > 0) {
            appId = this.state.appList[0].id;
            isThirdPartyApp = this.state.appList[0].thirdParty;
          } else if (this.state.groupList && this.state.groupList.length > 0) {
            appId = this.state.groupList[0].id;
            isThirdPartyApp = this.state.groupList[0].thirdParty;
          }
          let appDetails = ARHttp({
            method: 'POST',
            url: '/aws/getappdetails',
            body: {
              appId,
            },
            headers: {
              'X-XSRF-TOKEN': this.props.cToken,
            },
          });
          if (isThirdPartyApp) {
            appDetails = ARHttp({
              method: 'POST',
              url: '/aws/getthirdpartyappdetails',
              body: {
                appId,
              },
              headers: {
                'X-XSRF-TOKEN': this.props.cToken,
              },
            });
          }
          appDetails.subscribe((response: any) => {
            this.props.loadJurisdictionInfo(response);
          });
        }

        // Skip directly to the proper app in Partial App
        if (this.props.skipToApp) {
          this.onSelect(this.props.skipToApp, 'false');
          this.props.clearSkipToApp();
        }
      },
      () => {
        this.setState({
          ...this.state,
          loadingError: true,
        });
      },
    );
  }

  public render() {
    let content;

    if (this.state.loading) {
      content = (
        <Grid item>
          <SignaturePageLoading />
        </Grid>
      );
    }

    // TODO: Show a different failure state.
    if (this.state.loadingError) {
      content = (
        <Grid item>
          <SignaturePageFailure />
        </Grid>
      );
    }

    if (!this.state.loading && !this.state.loadingError) {
      content = <ApplicationSelectionInfo appList={this.state.appList} groupList={this.state.groupList} onSelect={this.onSelect} />;
    }

    return content;
  }

  private onSelect(id: string, isGroup: string) {
    // Determine destination from the state of the applciation
    // check which list should be searched
    const app: App | GroupApp = (isGroup === 'true') ? _.find(this.state.groupList, { id }) as GroupApp
      : _.find(this.state.appList, { id }) as App;

    let allSigned = true;
    let hasSigned = true;
    const { user } = this.props;
    let signerChecker;
    if (app) {
      const appSize = ((this.state.appList) ? this.state.appList.length : 0)
                + ((this.state.groupList) ? this.state.groupList.length : 0);
      this.props.selectApplication(app);
      this.props.appListSize(appSize);

      switch (app.logicalStatus) {
        case LogicalAppStatus.TO_SIGNER:
          if (isApp(app) && app.registrationDetailList && app.registrationDetailList.length > 1) {
            signerChecker = app.registrationDetailList;
          }
          if (isGroupApp(app) && app.appInfo.registrationDetailList && app.appInfo.registrationDetailList.length > 1) {
            signerChecker = app.appInfo.registrationDetailList;
          }
          if (signerChecker !== undefined) {
            signerChecker.forEach((signer) => {
              if (signer.signatureCaptured === false) {
                allSigned = false;
              }
              if (signer.emailAddress === user && signer.signatureCaptured === false) {
                hasSigned = false;
              }
            });
            if (allSigned === true) {
              this.props.history.push('/complete');
              break;
            }
            if (hasSigned === true) {
              this.props.history.push('/submitted');
              break;
            }
          }
          this.props.history.push('/quiz');
          break;
        case LogicalAppStatus.TO_COMPLETER:
          window.location.href = '/paws/#/home'
                        + `?ctoken=${this.props.cToken}`
                        + `&multiapp=${this.state.appList!.length + this.state.groupList!.length > 1}`
                        + `&ac=${this.props.country}`
                        + `&userLanguage=${this.convertUserLanguageToLanguageTag(this.props.userLanguage)}`
                        + `&aid=${id}`;
          break;
        case LogicalAppStatus.ERROR:
          // TODO: Go to an error state
          break;
        case LogicalAppStatus.HOLD:
          if ((isApp(app) && app.wetSignFinalized) || (isGroupApp(app) && app.appInfo.wetSignFinalized)) {
            window.location.href = '/paws/#/home'
                            + `?ctoken=${this.props.cToken}`
                            + `&multiapp=${this.state.appList!.length + this.state.groupList!.length > 1}`
                            + `&ac=${this.props.country}`
                            + `&userLanguage=${this.convertUserLanguageToLanguageTag(this.props.userLanguage)}`
                            + `&aid=${id}`;
            break;
          }
          // eslint-disable-next-line no-fallthrough
        case LogicalAppStatus.HOLD_EXPIRED:
        case LogicalAppStatus.PROCESSING:
          this.props.history.push('/submitted');
          break;
        case LogicalAppStatus.COMPLETED:
          this.props.history.push('/complete');
          break;
        case LogicalAppStatus.DECLINED:
          this.props.history.push('/declined');
          break;
        case LogicalAppStatus.EXPIRED:
        case LogicalAppStatus.DATA_REMOVED:
          this.props.history.push('/dataremoved');
          break;
      }
    }
  }

  private convertUserLanguageToLanguageTag(language: string) {
    if (language) {
      const languageTag: string = language.substring(0, language.indexOf('_'));
      return languageTag;
    }
    return '';
  }

  private isTooOld(thirdPartyApp) {
    // apps should be less than 90 days old to show up.
    let timeSinceModified;
    const time = new Date().getTime();
    if (thirdPartyApp.modifiedDate === undefined || thirdPartyApp.modifiedDate === 0) {
      timeSinceModified = time - thirdPartyApp.creationDate;
    } else {
      timeSinceModified = time - thirdPartyApp.modifiedDate;
    }
    return (timeSinceModified / 1000 / 60 / 60 / 24 > 90);
  }

  private sortApps(appList): App[] {
    const sortedList = appList.sort((a, b) => {
      const sortByA = a.modifiedDate ? a.modifiedDate : a.creationDate;
      const sortByB = b.modifiedDate ? b.modifiedDate : b.creationDate;
      return (sortByB - sortByA);
    });
    return sortedList;
  }

  private sortGroups(groupList: GroupApp[]): GroupApp[] {
    const sortedList = groupList.sort((a, b) => {
      const sortByA = a.appInfo.modifiedDate ? a.appInfo.modifiedDate : a.appInfo.creationDate;
      const sortByB = b.appInfo.modifiedDate ? b.appInfo.modifiedDate : b.appInfo.creationDate;
      return (sortByB - sortByA);
    });
    return sortedList;
  }
}

export default withRouter(SelectApplication);
