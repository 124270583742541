import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const cookieBanner = require('../../assets/images/customer/cookiebanner.png');

const styles: StyleRules = {
  container: {
    width: '100vw',
    padding: '0px',
    marginTop: '0px',
    backgroundColor: '#FFF',
    position: 'relative',
  },
  header: {
    margin: '0px',
    width: '100vw',
    position: 'relative',
    backgroundImage: `url(${cookieBanner})`,
    color: 'white',
    textAlign: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  headerTitle: {
    color: 'white',
    fontSize: '52px',
    lineHeight: '62px',
    fontWeight: 700,
  },
  paragraph: {
    padding: '0px 50px 0px 50px',
    maxWidth: '90%',
    margin: '0 auto 20px',
    fontFamily: 'sans-serif',
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 400,
  },
  buttons: {
    marginRight: '13px',
    backgroundColor: '#4a851f',
    marginBottom: '2rem',
    fontSize: '16px',
    fontWeight: 700,
    height: '40px',
    lineHeight: '40px',
    borderRadius: '4px',
    '& .MuiButton-label': {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    '&:hover': {
      backgroundColor: '#025c1a',
    },
    color: 'white',
    textTransform: 'none',
  },
  cardHeader: {
    color: 'rgba(44, 123, 188)',
    marginRight: '8px',
  },
  body: {
    marginRight: '8px',
  },
  tableContainer: {
    width: 'fit-content',
  },
  table: {
    '& .MuiTableCell-root': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  tableHeader: {
    backgroundColor: 'rgba(44, 123, 188)',
    '& .MuiTableCell-head': {
      color: 'white',
    },
  },
  policy: {
    position: 'relative',
    top: '50%',
    left: '50%',
    outline: 0,
    marginRight: '50%',
    transform: 'translate(-50%)',
    maxHeight: '85%',
    width: '75%',
    overflow: 'auto',
    padding: '.25rem',
  },
  subtitle: {
    color: '#21b523',
    fontSize: '18px',
    fontWeight: 300,
  },
  policyChangeText: {
    color: '#2c7bbc',
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 700,
  },
  sectionTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  dismiss: {
    color: 'white',
    marginTop: '.5%',
    marginRight: '.5%',
    marginLeft: 'auto',
    fontFamily: 'sans-serif',
    fontSize: '20px',
    float: 'right',
    display: 'block',
    textDecoration: 'none',
    border: '2px solid #fff',
    borderRadius: '20%',
    padding: '4px',
    WebkitBoxShadow: '4px 4px 4px #404040',
    boxShadow: '4px 4px 4px #404040',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#091B4D',
    },
  },
};

interface Props {
  destinationStr: string;
}

function EmailSection(): React.JSX.Element {
  const { t } = useTranslation();
  const dataProtectEmail = t('privacy_policy_data_protection_email');
  const queryEmail = t('privacy_policy_query_email');
  return (
    <Typography paragraph>
      <Trans i18nKey="privacy_policy_sec_11_sen_3a" />
      <a href={`mailto:${dataProtectEmail}`} style={{ color: '#21b523', textDecoration: 'none' }}>{dataProtectEmail}</a>
      <Trans i18nKey="privacy_policy_sec_11_sen_3b" />
      <a href={`mailto:${queryEmail}`} style={{ color: '#21b523', textDecoration: 'none' }}>{queryEmail}</a>
    </Typography>
  );
}

class CustomerFlowPrivacyPolicyInfo extends Component<Props & StyledComponentProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const classes = this.props.classes!;

    return (
      <>
        <Card className={classes.header}>
          <CardContent>
            <Link to={this.props.destinationStr} className={classes.dismiss} aria-label="dismiss">X</Link>
            <h1 className={classes.headerTitle}>
              <Trans i18nKey="privacy_policy_header" />
            </h1>
          </CardContent>
        </Card>
        <Card className={classes.container}>
          <div className={classes.policy}>
            <CardContent className={classes.body}>
              <div className={classes.paragraph} style={{ color: '#58585a' }}>
                <Typography className={classes.policyChangeText} paragraph>
                  <strong><Trans i18nKey="privacy_policy_change_date" /></strong>
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sentence_1" /></Typography>
                <Typography className={classes.policyChangeText} paragraph>
                  <strong><Trans i18nKey="privacy_policy_header_1" /></strong>
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sentence_2" /></Typography>
                <br />
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section1" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    1.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_1" />
                  </a>
                </Typography>
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section2" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    2.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_2" />
                  </a>
                </Typography>
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section3" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    3.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_3" />
                  </a>
                </Typography>
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section4" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    4.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_4" />
                  </a>
                </Typography>
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section5" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    5.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_5" />
                  </a>
                </Typography>
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section6" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    6.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_6" />
                  </a>
                </Typography>
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section7" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    7.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_7" />
                  </a>
                </Typography>
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section8" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    8.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_8" />
                  </a>
                </Typography>
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section9" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    9.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_9" />
                  </a>
                </Typography>
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section10" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    10.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_10" />
                  </a>
                </Typography>
                <Typography className={classes.subtitle} paragraph>
                  <a href="#section11" role="button" style={{ color: '#21b523', textDecoration: 'none' }}>
                    11.
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_11" />
                  </a>
                </Typography>
                <br />
                <Typography paragraph>
                  <Trans i18nKey="privacy_policy_sentence_3a" />
                  <a href="#personalInfo" role="button"><Trans i18nKey="privacy_policy_sentence_3b" /></a>
                  <Trans i18nKey="privacy_policy_sentence_3c" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sentence_4" /></Typography>
                <Typography paragraph><strong><Trans i18nKey="privacy_policy_sentence_5" /></strong></Typography>
                <Typography paragraph><strong><Trans i18nKey="privacy_policy_sentence_6" /></strong></Typography>

                <Typography id="section1" className={classes.sectionTitle}>
                  1.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_1" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_1_sen_1" /></Typography>
                <Typography id="personalInfo" paragraph><Trans i18nKey="privacy_policy_sec_1_sen_2" /></Typography>
                <ul>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_1_bullet_1" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_1_bullet_2" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_1_bullet_3" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_1_bullet_4" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_1_bullet_5" /></Typography>
                  </li>
                </ul>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_1_sen_3" /></Typography>
                <Typography id="section2" className={classes.sectionTitle}>
                  2.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_2" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_2_sen_1" /></Typography>
                <Typography paragraph>
                  <Trans i18nKey="privacy_policy_sec_2_sen_2a" />
                  <a href="#useOfcookie" role="button" style={{ textDecoration: 'none' }}>
                                         &nbsp;
                    {' '}
                    <Trans i18nKey="privacy_policy_sec_2_sen_2b" />
                  </a>
                  <Trans i18nKey="privacy_policy_sec_2_sen_2c" />
                </Typography>
                <Typography id="section3" className={classes.sectionTitle}>
                  3.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_3" />
                </Typography>
                <Typography id="useOfcookie" paragraph><Trans i18nKey="privacy_policy_sec_3_sen_1" /></Typography>
                <Typography id="section4" className={classes.sectionTitle}>
                  4.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_4" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_sen_1" /></Typography>
                <ul>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_1" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_2" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_3" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_4" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_5" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_6" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_7" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_8" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_9" /></Typography>
                  </li>
                </ul>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_sen_2" /></Typography>
                <ul>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_10" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_4_bullet_11" /></Typography>
                  </li>
                </ul>
                <Typography id="section5" className={classes.sectionTitle}>
                  5.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_5" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_5_sen_1" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_5_sen_2" /></Typography>
                <Typography id="section6" className={classes.sectionTitle}>
                  6.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_6" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_6_sen_1" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_6_sen_2" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_6_sen_3" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_6_sen_4" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_6_sen_5" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_6_sen_6" /></Typography>
                <Typography id="section7" className={classes.sectionTitle}>
                  7.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_7" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_7_sen_1" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_7_sen_2" /></Typography>
                <Typography id="section8" className={classes.sectionTitle}>
                  8.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_8" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_8_sen_1" /></Typography>
                <ul>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_8_bullet_1" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_8_bullet_2" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_8_bullet_3" /></Typography>
                  </li>
                </ul>
                <Typography id="section9" className={classes.sectionTitle}>
                  9.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_9" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_9_sen_1" /></Typography>
                <ul>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_9_bullet_1" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_9_bullet_2" /></Typography>
                  </li>
                </ul>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_9_sen_2" /></Typography>
                <Typography id="section10" className={classes.sectionTitle}>
                  10.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_10" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_sen_1" /></Typography>
                <ul>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_bullet_1" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_bullet_2" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_bullet_3" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_bullet_4" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_bullet_5" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_bullet_6" /></Typography>
                  </li>
                  <li>
                    <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_bullet_7" /></Typography>
                  </li>
                </ul>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_sen_2" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_sen_3" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_sen_4" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_10_sen_5" /></Typography>
                <Typography id="section11" className={classes.sectionTitle}>
                  11.
                  {' '}
                  <Trans i18nKey="privacy_policy_sec_11" />
                </Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_11_sen_1" /></Typography>
                <Typography paragraph><Trans i18nKey="privacy_policy_sec_11_sen_2" /></Typography>
                <EmailSection />
              </div>
            </CardContent>
          </div>
        </Card>
      </>
    );
  }
}

export default withStyles(styles)(CustomerFlowPrivacyPolicyInfo);
