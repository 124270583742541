import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { DocumentSignatureType, DocumentType } from '../models/DocumentType';
import ARHttp, { ScarecrowResponse } from './ARHttp';
import { getStore } from './StoreService';

export interface UploadDocument {
  documentType: DocumentType | DocumentSignatureType;
  base64Body?: string;
  mimeType: string;
  description?: string;
  thirdPartyApp?: boolean
}

const root = '/sb/documents';

function getSupportingDocuments(successCallBack?: (res) => void): void {
  ARHttp({
    method: 'POST',
    url: `${root}/getdocuments`,
  }).subscribe(
    (res: any) => {
      const supportingDocs = _.filter(res.documents, { docType: DocumentType.SUPPORTING_DOCUMENT });
      getStore().dispatch({ type: 'UPDATE_SUPPORTING_DOCUMENTS', payload: supportingDocs });
      if (successCallBack) {
        successCallBack(res);
      }
    },
    (error: Error) => {
      getStore().dispatch({ type: 'SET_ERROR', payload: error });
    },
  );
}

function uploadSupportingDocument(document: UploadDocument, errorCallBack?: (error) => void, successCallBack?: (res) => void): void {
  ARHttp({
    method: 'POST',
    url: `${root}/uploaddocuments`,
    body: {
      documents: [document],
    },
  }).subscribe(
    (res: any) => {
      getSupportingDocuments(successCallBack);
    },
    (error: Error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    },
  );
}

function removeSupportingDocument(documentID: number, successCallBack?: (res) => void): void {
  ARHttp({
    method: 'POST',
    url: `${root}/removedocuments`,
    body: {
      documentIds: [documentID],
    },
  }).subscribe(
    (res: any) => {
      getSupportingDocuments(successCallBack);
    },
    (error: Error) => {
      getStore().dispatch({ type: 'SET_ERROR', payload: error });
    },
  );
}

function uploadScarecrowDocuments(
  cToken: string,
  appId: number,
  documents: UploadDocument[],
  selectedFontName?: string,
  signedDate?: Date,
  thirdPartyApp?: boolean,
): Observable<ScarecrowResponse> {
  return ARHttp({
    method: 'POST',
    url: '/scwebapi/apps/uploadattachments',
    headers: {
      'X-XSRF-TOKEN': cToken,
    },
    body: {
      id: appId.toString(),
      thirdPartyApp,
      documents,
      selectedFontName,
      signedDate,
    },
  });
}

function removeScarecrowDocuments(cToken: string, appId: number, documentIds: number[], thirdPartyApp?: boolean)
  : Observable<ScarecrowResponse> {
  return ARHttp({
    method: 'POST',
    url: '/scwebapi/apps/removeattachments',
    headers: {
      'X-XSRF-TOKEN': cToken,
    },
    body: {
      id: appId.toString(),
      thirdPartyApp,
      documentIds,
    },
  });
}

function listScarecrowDocuments(cToken: string, appId: number, linkType: string, thirdPartyApp?: boolean): Observable<ScarecrowResponse> {
  return ARHttp({
    method: 'POST',
    url: '/scwebapi/merchant/listuploadeddocument',
    headers: {
      'X-XSRF-TOKEN': cToken,
    },
    body: {
      appId: appId.toString(),
      linkType,
      thirdPartyApp,
    },
  });
}

export default {
  getSupportingDocuments,
  uploadSupportingDocument,
  removeSupportingDocument,
  uploadScarecrowDocuments,
  removeScarecrowDocuments,
  listScarecrowDocuments,
};
