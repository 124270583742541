import {
  CardContent,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CookiePolicyContactInfo from './CookiePolicyContactInfo';

const useStyles = makeStyles({
  paragraph: {
    fontFamily: 'sans-serif',
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 400,
  },
  tableContainer: {
    width: 'fit-content',
  },
  table: {
    '& .MuiTableCell-root': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  tableHeader: {
    backgroundColor: 'rgba(44, 123, 188)',
    '& .MuiTableCell-head': {
      color: 'white',
    },
  },
  subtitle: {
    color: '#2c7bbc',
    fontSize: '28px',
    fontWeight: 300,
  },
  cookieChangeText: {
    color: '#2c7bbc',
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 700,
  },
});

function CookiePolicyTableRows(): React.JSX.Element {
  const tableRows: React.JSX.Element[] = [];
  const { i18n } = useTranslation();

  let rowIndex = 1;
  while (i18n.exists(`cookie_policy_table_row_${rowIndex}_col_1`)) {
    const rowElement = (
      <TableRow key={`cookie_policy_table_row_${rowIndex}`}>
        <TableCell>
          <Trans i18nKey={`cookie_policy_table_row_${rowIndex}_col_1`} />
        </TableCell>
        <TableCell>
          <Trans i18nKey={`cookie_policy_table_row_${rowIndex}_col_2`} />
        </TableCell>
        <TableCell>
          <Trans i18nKey={`cookie_policy_table_row_${rowIndex}_col_3`} />
        </TableCell>
        <TableCell>
          <Trans i18nKey={`cookie_policy_table_row_${rowIndex}_col_4`} />
        </TableCell>
        <TableCell>
          <Trans i18nKey={`cookie_policy_table_row_${rowIndex}_col_5`} />
        </TableCell>
      </TableRow>
    );
    tableRows.push(rowElement);
    rowIndex++;
  }

  return <>{tableRows}</>;
}

function CookiePolicyContent(): React.JSX.Element {
  const classes = useStyles();

  const cookiesUrl = 'www.allaboutcookies.org';

  return (
    <CardContent className={classes.body}>
      <div className={classes.paragraph} style={{ color: '#58585a' }}>
        <Typography className={classes.cookieChangeText} paragraph>
          <strong>
            <Trans i18nKey="cookie_policy_change_date" />
          </strong>
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_domain" values={{ domain: document.location.hostname }} />
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_cookie_definition" />
        </Typography>
        <Typography>
          <Trans i18nKey="cookie_policy_list" />
        </Typography>
        <ul>
          <li>
            <Typography>
              <Trans i18nKey="cookie_policy_cookie_necessary" />
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans i18nKey="cookie_policy_cookie_performance" />
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans i18nKey="cookie_policy_cookie_functional" />
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans i18nKey="cookie_policy_cookie_targeting" />
            </Typography>
          </li>
        </ul>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_cookies_used" />
        </Typography>
        <Typography className={classes.subtitle} paragraph>
          <Trans i18nKey="cookie_policy_cookie_necessary" />
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_cookie_necessary_1" />
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_cookie_necessary_2" />
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_cookie_necessary_3" />
        </Typography>
        <Typography className={classes.subtitle} paragraph>
          <Trans i18nKey="cookie_policy_table_title" />
        </Typography>
        <Paper className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>
                  <Trans i18nKey="cookie_policy_table_header_1" />
                </TableCell>
                <TableCell>
                  <Trans i18nKey="cookie_policy_table_header_2" />
                </TableCell>
                <TableCell>
                  <Trans i18nKey="cookie_policy_table_header_3" />
                </TableCell>
                <TableCell>
                  <Trans i18nKey="cookie_policy_table_header_4" />
                </TableCell>
                <TableCell>
                  <Trans i18nKey="cookie_policy_table_header_5" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <CookiePolicyTableRows />
            </TableBody>
          </Table>
        </Paper>
        <br />
        <Typography className={classes.subtitle} paragraph>
          <Trans i18nKey="cookie_policy_cookie_management_title" />
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_cookie_management_1" />
        </Typography>
        <Typography paragraph>
          <Trans i18nKey="cookie_policy_cookie_management_2" />
        </Typography>
        <Typography paragraph>
          <Trans
            i18nKey="cookie_policy_cookie_management_url_description"
            values={{ url: cookiesUrl }}
          >
            <Link href={`https://${cookiesUrl}`} target="_blank" rel="noopener">{cookiesUrl}</Link>
          </Trans>
        </Typography>
        <CookiePolicyContactInfo />
      </div>
    </CardContent>
  );
}

export default CookiePolicyContent;
