export enum BoardingStatus {
  UNKNOWN = 'UNKNOWN',
  COMPLETE = 'APPROVED',
  INPROGRESS = 'INPROGRESS',
  DECLINED = 'DECLINED',
  WITHDRAWN = 'WITHDRAWN',
  ERROR = 'ERROR',
  TIMED_OUT = 'TIMED_OUT',
  PEND = 'PEND',
}
