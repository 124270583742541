import i18next from 'i18next';
import { of } from 'rxjs';
import { TermState } from '../models/TermState';

export function getAgreements(country: string, ticked: boolean,
  isMarketingDataConsent2Required?: boolean|undefined, isMarketingDataConsent2Disabled?: boolean|undefined) {
  // Opt Out
  const gdprMarketingOptOut = {
    required: false,
    disabled: false,
    checked: ticked,
    header: i18next.t('marketing_header'),
    label: [i18next.t('marketing_opt_out_body'), i18next.t('marketing_opt_out_checkbox'), i18next.t('marketing_opt_out_additional')],
    mapping: 'gdprOptInOut',
  };

  // Opt In
  const gdprMarketingOptIn = {
    required: false,
    disabled: false,
    checked: !ticked,
    header: i18next.t('marketing_header'),
    label: [i18next.t('marketing_opt_in_body'), i18next.t('marketing_opt_in_checkbox')],
    mapping: 'gdprOptInOut',
  };

  const hiddenOptOut = {
    required: false,
    disabled: false,
    checked: ticked,
    header: '',
    label: ['', '', ''],
    mapping: 'gdprOptInOut',
  };

  const gdprMarketingOptOutTermsState: TermState[] = [
    gdprMarketingOptOut,
  ]

  const hiddenTermState: TermState[] = [
    hiddenOptOut,
  ];

  const polishTermStates: TermState[] = [
    {
      required: true,
      disabled: false,
      checked: false,
      header: i18next.t('marketing_consent_one_header'),
      label: [i18next.t('marketing_consent_one_body')],
      mapping: 'marketingDataConsent1',
    },
    {
      required: isMarketingDataConsent2Required ?? true,
      disabled: isMarketingDataConsent2Disabled ?? false,
      checked: false,
      header: i18next.t('marketing_consent_two_header'),
      label: [i18next.t('marketing_consent_two_body')],
      mapping: 'marketingDataConsent2',
    },
    gdprMarketingOptIn,
  ];

  switch (country) {
    case 'USA':
    case 'CAN':
    case '-':
      return of(hiddenTermState);
    case 'POL':
      return of(polishTermStates);
    default:
      return of(gdprMarketingOptOutTermsState);
  }

  // TODO: add this call later when we check boxes on the back end
  // return ARHttp({
  //     method: "POST",
  //     url: "/sb/documents",
  //     body: getAgreementsRequest,
  // }).pipe(
  //     map((response) => {
  //         return response;
  //     }),
  //     catchError ((error) => {
  //         if (country === "POL") {
  //             return of(polishTermStates);
  //         } else {
  //             return of(error);
  //         }
  //     }),
  // );
}
