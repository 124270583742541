import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import App from '../../models/App';
import GroupApp from '../../models/GroupApp';
import { CustomerAppState } from '../../reducers/customer_ui';
import {
  checkLockStatus, checkPeriodicallyLockStatus, getIsPeriodicallyCheckActive,
  LockStatusCheckServiceModel, resolveLockStatus, shouldCheckLockStatus, ShouldShowLockPageResponse,
} from '../../services/LockStatusCheckService';

const mapStateToProps = (state: CustomerAppState) => ({
  app: state.rest.app,
  groupApp: state.rest.groupApp,
  appId: state.rest.appId,
  ctoken: state.auth.ctoken,
});

interface Props {
  app: App,
  groupApp: GroupApp,
  location: any,
  history: any,
  appId: number,
  ctoken: string,
}

class CustomerLockedApplicationStatusCheck extends Component<Props & RouteComponentProps> {
  public lockedStatusServiceInstance: LockStatusCheckServiceModel;

  constructor(props) {
    super(props);
    this.lockedStatusServiceInstance = { onComponent: false };
  }

  public componentDidUpdate(previousProps) {
    if (shouldCheckLockStatus(this.props.app, this.props.groupApp)) {
      if (!this.lockedStatusServiceInstance.onComponent) {
        this.checkStatusFromService();
      }
    } else if (this.lockedStatusServiceInstance.onComponent) {
      this.lockedStatusServiceInstance.onComponent = false;
    }
  }

  public render() {
    const { children } = this.props;

    return (
      <>
        {children}
      </>
    );
  }

  private checkStatusFromService() {
    this.lockedStatusServiceInstance.onComponent = true;
    const appId: number = (this.props.groupApp) ? +(this.props.groupApp.appInfo.id ?? NaN) : +(this.props.app.id ?? NaN);
    checkLockStatus(appId, this.props.ctoken).subscribe((response: ShouldShowLockPageResponse) => {
      resolveLockStatus(response, this.props.location.pathname, this.props.history);
      if (!getIsPeriodicallyCheckActive()) {
        checkPeriodicallyLockStatus(
          appId,
          this.props.ctoken,
          this,
          this.lockedStatusServiceInstance,
        );
      }
    });
  }
}

export default connect(mapStateToProps)(withRouter(CustomerLockedApplicationStatusCheck));
