import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {
  catchError, map, switchMap, tap,
} from 'rxjs/operators';
import { CustomerAction } from '../../reducers/customer_ui';
import ARHttp from '../../services/ARHttp';

export function loginSuccessNavigator(action$: Observable<CustomerAction>): Observable<CustomerAction> {
  return action$.pipe(
    ofType('LOGIN_SUCCESS'),
    tap((action) => {
      action.payload.history.push('/applist');
    }),
    switchMap((action) => ARHttp({
      url: '/aws/env/whoami',
      method: 'POST',
    }).pipe(
      map((response: any) => ({
        type: 'WHOAMI_SUCCESS',
        payload: response.userName,
      })),
      catchError(() => of({
        type: 'WHOAMI_FAILURE',
      })),
    )),
  );
}
