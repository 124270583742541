import { forEach, reduce } from 'lodash';
import { Store } from 'redux';
import i18n from '../i18n';

export enum QueryParameter {
  APP_COUNTRY = 'ac',
  BRAND_CODE = 'br',
  SUBJURISDICTION_CODE = 'sj',
  REFERRER_NAME = 'rn',
  DESTINATION = 'dst',
  FACE_TO_FACE_TOKEN = 'ff',
  RESET_ID = 'ID',
  CONFIRMATION_CODE = 'cn',
  UNLOCK_ACCOUNT_FLAG = 'uns',
  APP_ID = 'aid',
  USER_LANGUAGE = 'userLanguage',
}

type QueryParameters = {
  [key in QueryParameter]?: string;
};

interface QueryParameterSettings {
  type: string;
  consumable: boolean;
}

/**
 * Dictionary of configurable settings for query parameters.
 *
 * Type denotes the dispatch action to be used when persisting the query parameter with Redux.
 *
 * Consumable query parameters are removed from the URL as soon as they're parsed into the system.
 * This is done so that certain query parameters are not kept around to mess with things if the user
 * bookmarks the page or refreshes the page.
 */
export const QueryParameterConfigurations: {[key in QueryParameter]: QueryParameterSettings} = {
  [QueryParameter.APP_COUNTRY]: { type: 'SET_COUNTRY', consumable: false },
  [QueryParameter.BRAND_CODE]: { type: 'SET_BRAND', consumable: false },
  [QueryParameter.SUBJURISDICTION_CODE]: { type: 'SET_SUB_JURISDICTION_CODE', consumable: false },
  [QueryParameter.REFERRER_NAME]: { type: 'SET_REFERRER', consumable: false },
  [QueryParameter.DESTINATION]: { type: 'SET_DESTINATION', consumable: false },
  [QueryParameter.FACE_TO_FACE_TOKEN]: { type: 'SET_FACE_TO_FACE', consumable: true },
  [QueryParameter.RESET_ID]: { type: 'SET_CREATE_ACCOUNT_STRING', consumable: true },
  [QueryParameter.CONFIRMATION_CODE]: { type: 'SET_CONFIRMATION_CODE', consumable: true },
  [QueryParameter.UNLOCK_ACCOUNT_FLAG]: { type: 'SET_UNLOCK_SUCCESS_MESSAGE', consumable: true },
  [QueryParameter.APP_ID]: { type: 'SET_APPLICATION_ID', consumable: true },
  [QueryParameter.USER_LANGUAGE]: { type: 'SET_USER_LANGUAGE', consumable: false },
};

export default class QueryParameterService {
  private store: Store;

  constructor(selectedStore: Store) {
    this.store = selectedStore;
  }

  public handleQueryParams = (url: string): void => {
    const params: QueryParameters = this.parseQueryParams(url);

    if (params[QueryParameter.USER_LANGUAGE] || this.getCookie('userLanguage')) {
      // If the user already has a language cookie saved, use the cookie instead of the query parameter.
      if (this.getCookie('userLanguage')) {
        params[QueryParameter.USER_LANGUAGE] = this.getCookie('userLanguage');
      }
      i18n.changeLanguage(params[QueryParameter.USER_LANGUAGE]!.split('_')[0]);
    }

    forEach(params, this.applyQueryParameterConfiguration);

    this.cleanURL(params);
  };

  private parseQueryParams = (url: string): QueryParameters => {
    const query = url.substring(url.indexOf('?') + 1);
    const params = query.split('&');

    return reduce(params, (values, param) => {
      const [paramName, paramValue] = param.split('=');
      values[paramName] = paramValue;
      return values;
    }, {});
  };

  // Should read the query parameter configuration dictionary and handle passed in parameters accordingly
  private applyQueryParameterConfiguration = (payload: string, param: QueryParameter, params: QueryParameters): void => {
    // Remove unconfigured parameters from the url and otherwise ignore them.
    if (!QueryParameterConfigurations[param]) {
      delete params[param];
      return;
    }

    const { type, consumable } = QueryParameterConfigurations[param];
    this.store.dispatch({ type, payload });

    if (consumable) {
      delete params[param];
    }
  };

  // Replace the URL with the remaining query parameters after consumption
  // This will remove the query parameters from the URL that should not stick around, like tokens and actions
  private cleanURL = (params: QueryParameters): void => {
    const queryParams = reduce(
      params,
      (memo, value, key) => {
        memo.push(`${key}=${value}`);
        return memo;
      },
      [] as string[],
    ).join('&');

    window.history.replaceState({ elavon: true }, '', `${window.location.pathname}?${queryParams}`);
  };

  // https://stackoverflow.com/questions/10730362/get-cookie-by-name
  private getCookie = (name: string): string | undefined => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts
        .pop()!
        .split(';')
        .shift();
    }
  };
}
