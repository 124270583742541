import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader, CircularProgress, FormControl,
  IconButton, TextField, Typography,
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import * as React from 'react';
import { Trans } from 'react-i18next';

const pageStyles = (theme) => createStyles({
  exitIcon: {
    margin: '0 10px',
  },
  cardActionsArea: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  formControl: {
    minWidth: '300px',
  },
  circularProgress: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  '@media (max-width: 766px)': {
    modalContainer: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 'none',
      margin: '16px',
    },
  },
  '@media (min-width: 767px)': {
    modalContainer: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
});

interface Props extends WithStyles {
  country?: string;
  pending: boolean;
  emailSent: boolean;
  roleError: boolean;
  userError: boolean;
  onSubmit: (emailAddress: string) => void;
  onCloseModal: () => void;
}

class CustomerFlowForgotPassword extends React.Component<Props, any> {
  private initialValues;

  constructor(props) {
    super(props);

    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.initialValues = {
      forgotPasswordEmail: '',
    };
  }

  public render() {
    const {
      classes, pending, emailSent, roleError, userError, onCloseModal,
    } = this.props;

    const spinner = pending && <CircularProgress variant="indeterminate" size={14} className={classes.circularProgress} />;

    let content;
    if (emailSent) {
      content = (
        <CardContent>
          <Typography variant="body1">
            <Trans>forgot_password_email_sent</Trans>
          </Typography>
        </CardContent>
      );
    } else if (roleError) {
      content = (
        <CardContent>
          <Typography variant="body1">
            <Trans>forgot_password_role_error</Trans>
          </Typography>
        </CardContent>
      );
    } else if (userError) {
      content = (
        <CardContent>
          <Typography variant="body1">
            <Trans>forgot_password_user_error</Trans>
          </Typography>
        </CardContent>
      );
    } else {
      content = (
        <Formik initialValues={this.initialValues} validate={this.validate} onSubmit={this.onSubmit}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <CardContent>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="forgotPasswordEmail"
                    label={this.props.country == 'CAN'
                      ? <Trans i18nKey="email_address_can" />
                      : <Trans i18nKey="email_address" />}
                    type="email"
                    value={formik.values.forgotPasswordEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={/* istanbul ignore next */ formik.touched.email && !!formik.errors.email}
                    helperText={
                      // istanbul ignore next
                      formik.touched.forgotPasswordEmail && formik.errors.forgotPasswordEmail ? (
                        <Trans i18nKey={formik.errors.forgotPasswordEmail as string} values={{ max: 55 }} />
                      ) : (
                        undefined
                      )
                    }
                  />
                </FormControl>
              </CardContent>
              <CardActions disableSpacing className={classes.cardActionsArea}>
                <Button variant="contained" color="primary" type="submit" disabled={this.shouldDisableSubmit(formik)}>
                  <Trans i18nKey="submit" />
                  {spinner}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      );
    }

    const closeIconButton = (
      <IconButton aria-label="Close" onClick={onCloseModal} className={classes.exitIcon}>
        <CloseIcon />
      </IconButton>
    );

    return (
      <Card style={{ marginTop: '16px' }} className={classes.modalContainer}>
        <CardHeader
          title={this.props.country == 'CAN'
            ? <Trans i18nKey="forgot_password_title_can" />
            : <Trans i18nKey="forgot_password_title" />}
          subheader={this.props.country == 'CAN'
            ? <Trans i18nKey="forgot_password_instructions1_can" />
            : <Trans i18nKey="forgot_password_instructions1" />}
          action={closeIconButton}
          component="h1"
        />
        {content}
      </Card>
    );
  }

  private validate(values) {
    return { ...this.validateEmail(values.forgotPasswordEmail) };
  }

  private validateEmail(email: string) {
    let error = {};
    // eslint-disable-next-line max-len
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      error = { email: 'required_field' };
    } else if (email.length > 55) {
      error = { email: 'max_length' };
    } else if (!re.test(String(email).toLocaleLowerCase())) {
      error = { email: 'invalid_email' };
    }
    return error;
  }

  // istanbul ignore next
  private shouldDisableSubmit(formik) {
    return !formik.isValid || !formik.dirty || this.props.pending;
  }

  private onSubmit(values) {
    this.props.onSubmit(values.forgotPasswordEmail);
  }
}
export default withStyles(pageStyles)(CustomerFlowForgotPassword);
