import * as _ from 'lodash';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { TermState } from '../../models/TermState';
import TermCheckbox from './TermCheckbox';

interface Props {
  terms: TermState[];
  onTermsChanged: (mapping: string, value: boolean) => void;
}

export default class AgreementTerms extends Component<Props> {
  public render() {
    const checkboxes = _.map(this.props.terms, (term: TermState, i) => (
      <TermCheckbox
        key={i}
        labels={term.label}
        checked={term.checked}
        onChange={this.createChangeFor(term.mapping)}
        header={term.header}
        required={term.required}
        disabled={term.disabled}
      />
    ));

    return (
      <>
        {[checkboxes]}
      </>
    );
  }

  private createChangeFor(mapping: string) {
    return (value: boolean) => {
      this.props.onTermsChanged(mapping, value);
    };
  }
}
