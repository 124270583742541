import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CustomerAction } from '../../reducers/customer_ui';
import ARHttp from '../../services/ARHttp';

export default function (action$: Observable<CustomerAction>): Observable<CustomerAction> {
  return action$.pipe(
    ofType('SUBMIT_LOGIN'),
    switchMap((action: CustomerAction) =>
    // if action.payload has transmit token then call finalize login
      ARHttp({
        method: 'POST',
        url: '/aws/login',
        body: {
          userName: action.payload.username,
          password: action.payload.password,
          transmitToken: action.payload.transmitToken,
        },
      }).pipe(
        map((response: any) => ({
          type: 'LOGIN_SUCCESS',
          payload: {
            token: response.token,
            history: action.payload.history,
          },
        })),
        catchError((error) => of({ type: 'LOGIN_FAILURE', payload: error })),
      )),
  );
}
