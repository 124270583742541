import { connect } from 'react-redux';
import CustomerDocumentUpload from '../../components/customer/CustomerDocumentUpload';
import { CustomerAppState } from '../../reducers/customer_ui';

const mapStateToProps = (state: CustomerAppState, props) => ({
  appId: props.appId,
  country: props.country,
  cToken: state.auth.ctoken,
  isThirdPartyApp: props ? props.isThirdPartyApp : false,
});

const mapDispatchToProps = (dispatch) => ({
  setAppError: () => {
    dispatch({ type: 'SET_ERROR', payload: true });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerDocumentUpload);
