import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import * as React from 'react';
import { Component } from 'react';
import { Trans } from 'react-i18next';
import App from '../../models/App';
import GroupApp from '../../models/GroupApp';

interface Props {
  appId: number;
  cToken: string;
  app: App;
  groupApp: GroupApp;
  history: any,
  appListSize: number;
  clearSkipToApp: () => void;
}

const styles: StyleRules = {
  pageContent: {
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  cardContent: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    width: 'fit-content',
  },
};

class CustomerFlowUpdateInProgress extends Component<Props & StyledComponentProps> {
  constructor(props) {
    super(props);
    this.returnToApplicationList = this.returnToApplicationList.bind(this);
  }

  public render() {
    const classes = this.props.classes!;
    return (
      <Card className={classes.pageContent} style={{ padding: '24px', marginTop: '32px' }}>
        <CardHeader title={<Trans i18nKey="lockedApplication.updateIsInProgressTitle" />} component="h1" />
        <CardContent className={classes.cardContent}>
          <Typography><Trans i18nKey="lockedApplication.updateIsInProgressBody" /></Typography>
        </CardContent>
        {this.shouldHideBackToApplicationListButton() ? null : this.returnToListButton()}
      </Card>
    );
  }

  private returnToListButton() {
    return (
      <Button variant="contained" type="submit" onClick={this.returnToApplicationList}>
        <ChevronLeft />
        <Trans>Return_to_Application_List</Trans>
      </Button>
    );
  }

  private returnToApplicationList() {
    this.props.clearSkipToApp();
    this.props.history.push('/applist');
  }

  private shouldHideBackToApplicationListButton() {
    return (this.props.appListSize && this.props.appListSize === 1);
  }
}

export default withStyles(styles)(CustomerFlowUpdateInProgress);
