import {
  Button, CircularProgress, createStyles, IconButton, Typography, withStyles, WithStyles,
} from '@material-ui/core';
import { CheckCircle, CloudUploadOutlined, DeleteForeverOutlined } from '@material-ui/icons';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { noop } from 'rxjs';
import { DocumentType } from '../../models/DocumentType';
import ApplicationDocumentService, { UploadDocument } from '../../services/ApplicationDocumentService';
import ARNotification from './ARNotification';
import ARTextField from './ARTextField';

interface Props extends WithStyles {
  id: string;
  label: string;
  document?: DocumentRecord;
  fieldValue?: DocumentRecord[];
  reviewMode: boolean;
  extras?: any;
}

interface State {
  isUploading: boolean;
  error: string;
}

export interface DocumentRecord {
  documentId: number,
  description: string,
}

const style = (theme) => createStyles({
  deleteButton: {
    position: 'absolute',
    right: '5px',
    top: '-3px',
  },
  buttonBorder: {
    backgroundColor: '#EEEEEE',
    borderWidth: '1px',
    borderColor: '#868E96',
    borderRadius: 5,
  },
  dashedBorder: {
    borderStyle: 'dashed',
  },
  iconMargin: {
    marginRight: '5px',
  },
  checkIcon: {
    color: '#07c67e',
  },
  uploadIcon: {
    color: '#868E96',
  },
  progressIcon: {
    maxHeight: '20px',
    maxWidth: '20px',
  },
  fileInput: {
    display: 'none',
  },
  uploadContainer: {
    position: 'relative',
    margin: '5px',
  },
  error: {
    color: '#83380c',
    textAlign: 'center',
    marginTop: '2px',
  },
});

class ARDocumentUpload extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isUploading: false,
      error: '',
    };

    this.onChange = this.onChange.bind(this);
    this.hasDocument = this.hasDocument.bind(this);
    this.removeDocument = this.removeDocument.bind(this);
    this.handleUploadResponse = this.handleUploadResponse.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.clearError = this.clearError.bind(this);
  }

  public render() {
    const {
      extras, fieldValue, reviewMode, document, id, label, classes,
    } = this.props;
    const { error } = this.state;

    // TODO: Rework to get rid of these oldWay and extras values.
    let uploadDoc;
    let oldWay = false;
    if (extras && (extras.supportingDocumentsIndex || extras.supportingDocumentsIndex === 0)) {
      oldWay = true;
      uploadDoc = fieldValue![extras.supportingDocumentsIndex];
    } else {
      uploadDoc = document;
    }

    if (reviewMode) {
      return (
        <ARTextField
          id={id}
          label={label}
          fieldValue={uploadDoc ? uploadDoc.description : '—'}
          error={!uploadDoc}
          onBlur={noop}
          onFocus={noop}
          onChange={noop}
        />
      );
    }

    let deleteIcon: React.JSX.Element | undefined;
    let buttonLabel: string;
    let buttonBorderClass: string;
    if (this.hasDocument(oldWay)) {
      buttonLabel = uploadDoc.description;
      buttonBorderClass = classes.buttonBorder;
      deleteIcon = (
        <IconButton onClick={this.removeDocument} className={classes.deleteButton}>
          <DeleteForeverOutlined />
        </IconButton>
      );
    } else {
      buttonLabel = 'upload_file';
      buttonBorderClass = [classes.buttonBorder, classes.dashedBorder].join(' ');
    }

    let buttonIcon: React.JSX.Element;
    if (this.hasDocument(oldWay)) {
      buttonIcon = (
        <CheckCircle className={[classes.iconMargin, classes.checkIcon].join(' ')} />
      );
    } else if (this.state.isUploading) {
      buttonIcon = (
        <CircularProgress
          variant="indeterminate"
          className={[classes.iconMargin, classes.progressIcon].join(' ')}
          aria-label="Circular Progress"
        />
      );
    } else {
      buttonIcon = (
        <CloudUploadOutlined className={[classes.iconMargin, classes.uploadIcon].join(' ')} />
      );
    }

    return (
      <>
        <ARNotification
          open={!!error}
          message={error}
          onClose={this.clearError}
          autoHideDuration={3000}
        />
        <input
          id={id}
          type="file"
          onChange={this.onChange}
          className={classes.fileInput}
        />
        <div className={classes.uploadContainer}>
          <label
            htmlFor={id}
          >
            <Button
              id={`${id}: button`}
              variant="outlined"
              component="span"
              fullWidth
              className={buttonBorderClass}
            >
              {buttonIcon}
              <Typography variant="body2">
                <Trans>{buttonLabel}</Trans>
              </Typography>
            </Button>
          </label>
          {deleteIcon}
        </div>
      </>
    );
  }

  private onChange(event): void {
    this.removeDocument();

    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();

    reader.addEventListener('load', () => {
      this.setState({ isUploading: true, error: '' });
      const uploadDocument: UploadDocument = {
        documentType: DocumentType.SUPPORTING_DOCUMENT,
        base64Body: (reader.result as string),
        mimeType: file.type,
        description: file.name,
      };

      ApplicationDocumentService.uploadSupportingDocument(uploadDocument, this.handleUploadError, this.handleUploadResponse);
    });
    reader.readAsDataURL(file);
  }

  private handleUploadError(error: Error): void {
    if (error.message === 'Unsupported Document Type') {
      this.setState({ isUploading: false, error: 'upload_failed_invalid_mime_type' });
    } else if (error.message === 'Maximum Document Size Exceeded') {
      this.setState({ isUploading: false, error: 'upload_failed_file_too_big' });
    } else {
      this.setState({ isUploading: false, error: 'upload_failed_generic' });
    }
  }

  private handleUploadResponse(response): void {
    this.setState({ isUploading: false });
  }

  private hasDocument(oldWay?: boolean): boolean {
    const { fieldValue, extras, document } = this.props;
    if (oldWay === true) {
      return fieldValue!.length >= extras.supportingDocumentsIndex + 1;
    }
    return !!document;
  }

  private removeDocument(): void {
    const { extras, document, fieldValue } = this.props;
    const oldWayEvent = extras && (extras.supportingDocumentsIndex || extras.supportingDocumentsIndex === 0);
    if (this.hasDocument(oldWayEvent)) {
      let uploadDoc = document;
      if (oldWayEvent) {
        uploadDoc = fieldValue![extras.supportingDocumentsIndex];
      }
      ApplicationDocumentService.removeSupportingDocument(uploadDoc!.documentId);
    }
  }

  private clearError() {
    this.setState({ error: '' });
  }
}

export default withStyles(style)(ARDocumentUpload);
