import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CustomerFlowUpdateInProgress from '../../components/customer/CustomerFlowUpdateInProgress';
import { CustomerAppState } from '../../reducers/customer_ui';

const mapStateToProps = (state: CustomerAppState) => ({
  appId: state.rest.appId,
  cToken: state.auth.ctoken,
  app: state.rest.app,
  groupApp: state.rest.groupApp,
  appListSize: state.rest.appListSize,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSkipToApp: () => {
    dispatch({ type: 'CLEAR_SKIP_TO_APP' });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerFlowUpdateInProgress);
