import { connect } from 'react-redux';
import CustomerFlowResetPassword from '../../components/customer/CustomerFlowResetPassword';
import { CustomerAppState } from '../../reducers/customer_ui';

const mapStateToProps = (state: CustomerAppState) => ({
  country: state.JurisdictionInfo.country,
  brand: state.JurisdictionInfo.brandCode,
  subJurisdictionCode: state.JurisdictionInfo.subJurisdictionCode,
  referrer: state.JurisdictionInfo.referrerName,
  createAccountString: state.rest.createAccountString,
});

const mapDispatchToProps = (dispatch) => ({
  setResetPasswordSuccess: (value: boolean) => {
    dispatch({
      type: 'SET_RESET_PASSWORD_SUCCESS',
      payload: value,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerFlowResetPassword);
