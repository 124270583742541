import { CustomerAction } from '.';

export interface CustomerLoginState {
  pending: boolean;
  error: boolean;
  success: boolean;
  expired: boolean;
  passwordExpired: boolean;
}

const initialState: CustomerLoginState = {
  pending: false,
  error: false,
  success: false,
  expired: false,
  passwordExpired: false,
};

export function customerLoginReducer(state: CustomerLoginState = initialState, action: CustomerAction) {
  switch (action.type) {
    case 'SUBMIT_LOGIN':
      return {
        pending: true,
        error: false,
        success: false,
      };

    case 'LOGIN_SUCCESS':
      return {
        pending: false,
        error: false,
        success: true,
      };

    case 'LOGIN_FAILURE':
      if (action.payload && action.payload.message === 'Expired') {
        return {
          pending: false,
          success: false,
          passwordExpired: true,
        };
      }
      return {
        pending: false,
        error: true,
        success: false,
      };

    case 'EXPIRE_SESSION':
      return {
        ...initialState,
        expired: true,
      };

    case 'CLEAR_EXPIRED_STATUS':
      return {
        ...state,
        expired: false,
      };

    default:
      return state;
  }
}
