import { Snackbar } from '@material-ui/core';
import * as React from 'react';
import { Trans } from 'react-i18next';

interface Props {
  open: boolean,
  message: string,
  onClose: () => void,
  autoHideDuration?: number,
}

class ARNotification extends React.Component<Props, any> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      open, onClose, autoHideDuration, message,
    } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={onClose}
        autoHideDuration={autoHideDuration}
        message={<Trans>{message}</Trans>}
      />
    );
  }
}

export default ARNotification;
