/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {
  createMuiTheme, MuiThemeProvider, StyledComponentProps, StyleRules, withStyles,
} from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import i18next from 'i18next';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { CookiePolicy } from '../../models/CookiePolicy';

const cookieBanner = require('../../assets/images/customer/cookiebanner.png');

interface Props {
  cookiePolicy: CookiePolicy;
  acceptCookies: () => void;
  removeAnalyticsCookie: () => void;
  destinationStr: string;
}

interface State {
  cookiesAccepted: boolean;
  changesMade: boolean;
  redirectToLogin: boolean;
  redirectToCookiePolicy: boolean
}
const styles: StyleRules = {
  container: {
    width: '100vw',
    padding: '0px',
    marginTop: '0px',
    backgroundColor: '#FFF',
    position: 'relative',
  },
  header: {
    margin: '0px',
    width: '100vw',
    position: 'relative',
    backgroundImage: `url(${cookieBanner})`,
    color: 'white',
    textAlign: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  headerTitle: {
    color: 'white',
    fontSize: '52px',
    lineHeight: '62px',
    fontWeight: 700,
  },
  bodyTitle: {
    color: '#0a1c76',
    fontSize: '48px',
    fontWeight: 300,
    lineHeight: '32px',
    fontFamily: 'sans-serif',
  },
  bodyContent: {
    textAlign: 'center',
    color: '#58585a',
  },
  paragraph: {
    padding: '0px 50px 0px 50px',
    maxWidth: '90%',
    margin: '0 auto 20px',
    fontFamily: 'sans-serif',
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 400,
  },
  buttons: {
    marginRight: '13px',
    backgroundColor: '#4a851f',
    marginBottom: '2rem',
    fontSize: '16px',
    fontWeight: 700,
    height: '40px',
    lineHeight: '40px',
    borderRadius: '4px',
    '& .MuiButton-label': {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    '&:hover': {
      backgroundColor: '#025c1a',
    },
    color: 'white',
    textTransform: 'none',
  },
  saveChangesBox: {
    border: '3px solid #4a851f',
    maxWidth: '450px',
    margin: '10px auto 20px auto',
  },
  radioLinks: {
    fontFamily: 'sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  backLink: {
    color: '#337ab7',
    textDecoration: 'none',
    fontSize: '14px',
  },
  dismiss: {
    color: 'white',
    marginTop: '.5%',
    marginRight: '.5%',
    marginLeft: 'auto',
    fontFamily: 'sans-serif',
    fontSize: '20px',
    float: 'right',
    display: 'block',
    textDecoration: 'none',
    border: '2px solid #fff',
    borderRadius: '20%',
    padding: '4px',
    WebkitBoxShadow: '4px 4px 4px #404040',
    boxShadow: '4px 4px 4px #404040',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#091B4D',
    },
  },
};

const theme = createMuiTheme({
  palette: {
    primary: { main: '#4a851f' },
  },
});

class CustomerFlowGDPRCookiePreferences extends Component<Props & StyledComponentProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      cookiesAccepted: false, changesMade: false, redirectToLogin: false, redirectToCookiePolicy: false,
    };
    this.saveSettings = this.saveSettings.bind(this);
    this.acceptCookiesCheckbox = this.acceptCookiesCheckbox.bind(this);
    this.declineCookiesCheckbox = this.declineCookiesCheckbox.bind(this);
    this.redirectToLogin = this.redirectToLogin.bind(this);
    this.acceptCookies = this.acceptCookies.bind(this);
    this.redirectToCookiePolicyPage = this.redirectToCookiePolicyPage.bind(this);
  }

  public UNSAFE_componentWillMount() {
    if (this.props.cookiePolicy && this.props.cookiePolicy.analytics) {
      this.setState({ cookiesAccepted: true });
    }
  }

  public render() {
    const classes = this.props.classes!;
    const acceptCookie = (
      <Radio
        id="acceptCookiesRadio"
        checked={this.state.cookiesAccepted}
        onChange={this.acceptCookiesCheckbox}
        className={classes.radio}
        color="primary"
      />
    );
    const notAcceptCookie = (
      <Radio
        id="declineCookiesRadio"
        checked={!this.state.cookiesAccepted}
        onChange={this.declineCookiesCheckbox}
        className={classes.radio}
        color="primary"
      />
    );

    return (
      <>
        <Card className={classes.header}>
          <CardContent>
            <Link to={this.props.destinationStr} className={classes.dismiss} aria-label="dismiss">X</Link>
            <h1 className={classes.headerTitle}>
              <Trans values={{ domain: document.location.hostname }}>gdprCookieUrl</Trans>
            </h1>
            <p className={classes.paragraph} style={{ maxWidth: '50%' }}><Trans i18nKey="cookiePreferencesHeaderContent" /></p>
            <Button
              id="acceptCookiesButton"
              onClick={this.acceptCookies}
              variant="contained"
              className={classes.buttons}
              endIcon={<ChevronRight />}
            >
              <Trans i18nKey="gdprAcceptAllCookies" />
            </Button>
          </CardContent>
        </Card>
        <Card className={classes.container}>
          <CardContent className={classes.bodyContent} style={{ padding: 'padding: 0px 40px 0px 40px' }}>
            <h2 className={classes.bodyTitle}><Trans i18nKey="cookiePreferencesTitle" /></h2>
            <Typography
              className={classes.paragraph}
              style={{ marginBottom: '0px', maxWidth: '80%' }}
              id="cookiePreferencesContent"
            >
              <Trans i18nKey="cookiePreferencesContent">
                <a
                  role="button"
                  href="#cookiePolicy"
                  onClick={this.redirectToCookiePolicyPage}
                  style={{ color: '#337ab7' }}
                  aria-label="Cookie Policy"
                >
                  Cookie Policy
                </a>
              </Trans>
            </Typography>
            <h2 className={classes.bodyTitle}><Trans i18nKey="cookieSettingsTitle" /></h2>
            <p className={classes.paragraph}><Trans i18nKey="cookieSettingsContent" /></p>
            <h2 className={classes.bodyTitle}><Trans i18nKey="necessaryCookiesTitle" /></h2>
            <Typography
              className={classes.paragraph}
              style={{ marginBottom: '0px', maxWidth: '45%' }}
              id="necessaryCookieContent"
            >
              <Trans i18nKey="necessaryCookiesContent">
                <a
                  role="button"
                  href="#cookiePolicyInfo"
                  onClick={this.redirectToCookiePolicyPage}
                  style={{ color: '#337ab7' }}
                  aria-label="Cookie Policy for further information"
                >
                  Cookie Policy for further information
                </a>
              </Trans>
            </Typography>
            <h2 className={classes.bodyTitle}><Trans i18nKey="performanceCookieTitle" /></h2>
            <div className={classes.paragraph} style={{ maxWidth: '40%' }}>
              <p><Trans i18nKey="performanceCookieContent" /></p>
              <MuiThemeProvider theme={theme}>
                <div style={{ textAlign: 'left' }}>
                  <RadioGroup>
                    <FormControlLabel
                      classes={{ label: classes.radioLinks }}
                      control={acceptCookie}
                      label={i18next.t('allowPerformanceCookie')}
                    />
                    <FormControlLabel
                      classes={{ label: classes.radioLinks }}
                      control={notAcceptCookie}
                      label={i18next.t('doNotUsePerformanceCookie')}
                    />
                  </RadioGroup>
                </div>
              </MuiThemeProvider>
              <br />
              <Typography className={classes.paragraph} style={{ marginBottom: '0px' }} id="findCookies">
                <Trans i18nKey="findAboutCookies">
                  <a
                    role="button"
                    href="https://www.allaboutcookies.org"
                    style={{ color: '#337ab7', textDecoration: 'none' }}
                  >
                    https://www.allaboutcookies.org
                  </a>
                </Trans>
              </Typography>
              {this.state.changesMade ? this.settingsSaved(classes) : null}
              <br />
              <Button
                id="saveChanges"
                onClick={this.saveSettings}
                variant="contained"
                className={classes.buttons}
                endIcon={<ChevronRight />}
              >
                <Trans i18nKey="saveChanges" />
              </Button>
            </div>
          </CardContent>
        </Card>
        {this.state.redirectToLogin && (this.props.cookiePolicy && this.props.cookiePolicy.essential)
          ? <Redirect to="/login" /> : null}
        {this.state.redirectToCookiePolicy ? <Redirect to="/cookiepolicy" /> : null}
      </>
    );
  }

  private settingsSaved(classes) {
    return (
      <>
        <br />
        <div className={classes.saveChangesBox}>
          <p><Trans i18nKey="settingsSaved" /></p>
          <a
            role="button"
            href="javascript:void(0)"
            onClick={this.redirectToLogin}
            className={classes.backLink}
          >
            <Trans i18nKey="backToPrevious" />
          </a>
        </div>
      </>
    );
  }

  private redirectToLogin() {
    this.setState({ ...this.state, redirectToLogin: true });
  }

  private acceptCookiesCheckbox() {
    this.setState({ ...this.state, cookiesAccepted: true });
  }

  private declineCookiesCheckbox() {
    this.setState({ ...this.state, cookiesAccepted: false });
  }

  private acceptCookies() {
    this.props.acceptCookies();
    this.setState({ ...this.state, cookiesAccepted: true, redirectToLogin: true });
  }

  private redirectToCookiePolicyPage() {
    this.setState({ ...this.state, redirectToCookiePolicy: true });
  }

  private saveSettings() {
    if (this.state.cookiesAccepted) {
      this.props.acceptCookies();
    } else {
      this.props.removeAnalyticsCookie();
    }
    this.setState({
      ...this.state,
      changesMade: true,
    });
  }
}

export default withStyles(styles)(CustomerFlowGDPRCookiePreferences);
