import * as React from 'react';
import { connect } from 'react-redux';
import CostcoErrorScreen from '../components/error-boundary/CostcoErrorScreen';
import DefaultErrorScreen from '../components/error-boundary/DefaultErrorScreen';
import ErrorBoundary, { Props } from '../components/error-boundary/ErrorBoundary';
import { AppState } from '../reducers';
import { CustomerAppState } from '../reducers/customer_ui';

const getErrorScreen = (state: AppState | CustomerAppState) => {
  if (state.ErrorState.isCostco) {
    return (<CostcoErrorScreen />);
  }
  return (<DefaultErrorScreen />);
};

const mapStateToProps = (state: AppState | CustomerAppState): Props => ({
  appError: state.ErrorState.error,
  errorScreen: getErrorScreen(state),
});

export default connect(
  mapStateToProps,
)(ErrorBoundary);
