import { connect } from 'react-redux';
import CustomerFlowForgotPasswordModal from '../../components/customer/CustomerFlowForgotPasswordModal';
import { CustomerAppState } from '../../reducers/customer_ui';

const mapStateToProps = (state: CustomerAppState) => ({
  emailSent: state.forgotPassword.emailSent,
  pending: state.forgotPassword.pending,
  country: state.JurisdictionInfo.country,
  roleError: state.forgotPassword.roleError,
  userError: state.forgotPassword.userError,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (email: string) => {
    dispatch({ type: 'FORGOT_PASSWORD_SUBMIT', payload: email });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerFlowForgotPasswordModal);
