import {
  Button, Card, CardContent, CardHeader, CircularProgress,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import * as React from 'react';
import { Trans } from 'react-i18next';
import BrandCode from '../../models/BrandCode';

interface Props {
  enabled: boolean;
  onSubmit: () => void;
  brandCode: string;
  spinnerStatus: boolean;
  submitting: boolean;
  isThirdPartyStandalone?: boolean;
  country?: string;
}

export default class DocumentSubmission extends React.Component<Props> {
  public render() {
    const {
      brandCode, onSubmit, enabled, spinnerStatus, submitting, isThirdPartyStandalone,
    } = this.props;
    let spinner;
    if (spinnerStatus) {
      spinner = <CircularProgress variant="indeterminate" size={14} style={{ color: 'rgba(0, 0, 0, 0.26)' }} />;
    }

    let renderRemainderMessage;
    if (isDirectBillChannel(brandCode)) {
      renderRemainderMessage = <Trans>application_submission_reminder_directBill</Trans>;
    }
    if (!isThirdPartyStandalone) {
      renderRemainderMessage = (
        <Trans>
          {this.props.country === 'CAN'
            ? 'application_submission_reminder_can'
            : 'application_submission_reminder'}
        </Trans>
      );
    }

    return (
      <Card>
        <CardHeader title={<Trans i18nKey="document_signature_page_submit_header" />} />
        <CardContent>
          <p>{renderRemainderMessage}</p>
          <Button onClick={onSubmit} variant="contained" disabled={!enabled || submitting}>
            <Trans>application_submit_button_label</Trans>
            <ChevronRight />
            {spinner}
          </Button>
        </CardContent>
      </Card>
    );
  }
}

function isDirectBillChannel(brandCode) {
  return brandCode === BrandCode.DIRBILL_USA || brandCode === BrandCode.DIRBILL_CANADA;
}
