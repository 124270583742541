import { CircularProgress, FormHelperText, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Done from '@material-ui/icons/Done';
import Info from '@material-ui/icons/Info';
import * as _ from 'lodash';
import * as React from 'react';
import { Component, Fragment } from 'react';
import Month from '../../models/Month';
import { parseDateOfBirth } from '../../services/persistence/SelfBoardToScarecrowApplicationConverter';
import RegexService from '../../services/RegexService';

interface Props {
  // Used here
  id: string;
  label: string;
  fieldValue: string | undefined;
  error: boolean;
  pending?: boolean;
  onChange: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  disabled?: boolean;
  tooltip?: string;
  subtext?: string;
  disableUnderline?: boolean;
  extras?: any;
  icon?: string;
}

function dollarFormatter(currency, groupSeparator) {
  return (
    currency.split(groupSeparator).join('')
      .replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator) // this regex puts a seperator every 3 digits.
  );
}
const monthNames = {
  [Month.JAN]: 'January',
  [Month.FEB]: 'February',
  [Month.MAR]: 'March',
  [Month.APR]: 'April',
  [Month.MAY]: 'May',
  [Month.JUN]: 'June',
  [Month.JUL]: 'July',
  [Month.AUG]: 'August',
  [Month.SEP]: 'September',
  [Month.OCT]: 'October',
  [Month.NOV]: 'November',
  [Month.DEC]: 'December',
};

// arrays that define types,
const dateList = ['dateOfBirth', 'idExpiryDate', 'baxEffectiveDate'];
const currencyList = ['projectedMonthlyCardSales', 'companyAnnualRevenue', 'averageTransactionValue'];

export function dateOfBirthFormatter(dateOfBirth, country) {
  const date = parseDateOfBirth(new Date(dateOfBirth));
  if (country === 'USA') {
    if (date.month && date.day && date.year) {
      return `${monthNames[Month[date.month]]} ${date.day.toString()}, ${date.year.toString()}`;
    }
  } else {
    return dateOfBirth;
  }
}

export default class ARTextField extends Component<Props> {
  private groupSeparator;

  private currencyDisplay;

  private currencyPosition;

  constructor(props: Props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    if (this.props.extras) {
      if (this.props.extras.numberFormat) {
        this.groupSeparator = this.props.extras.groupSeparator || ',';
        this.currencyDisplay = this.props.extras.currencyDisplay || '$';
        this.currencyPosition = this.props.extras.currencyPosition || 'left';
      }
    }
  }

  public render() {
    // A11y compliance for text fields
    const textField = document.getElementsByTagName('input') as any;

    if (textField) {
      _.map(textField, (item) => {
        item.setAttribute('aria-label', _.startCase(item.id));
        item.setAttribute('aria-describedby', `${RegexService.consumeCharsBeforeHyphenRegex.exec(item.id)}_error`);
      });
    }

    let country = 'USA';
    if (this.props.extras && this.props.extras.country === 'POL') {
      country = 'POL';
    }
    const tooltip = (
      <Tooltip title={this.props.tooltip}>
        <Info color="primary" />
      </Tooltip>
    );
    const emdash = '\u2014';
    const moneyFormat = _.get(this.props, 'extras.numberFormat') || _.includes(currencyList, this.props.id) || false;
    let content = '';
    if (this.props.fieldValue) {
      if (moneyFormat) {
        content = dollarFormatter(this.props.fieldValue, this.groupSeparator);
        if (this.props.extras && this.props.extras.reviewMode && this.currencyPosition === 'right') {
          content = `${content} ${this.currencyDisplay}`;
        }
      } else if (this.props.extras && this.props.extras.reviewMode && _.includes(dateList, this.props.id)) {
        content = dateOfBirthFormatter(this.props.fieldValue, country);
      } else {
        content = this.props.fieldValue;
      }
    } else if (this.props.extras && this.props.extras.reviewMode) {
      content = emdash;
    }
    let shouldDisable = false;
    if (this.props.extras && this.props.extras.reviewMode) {
      shouldDisable = true;
    }
    const subtext = <FormHelperText>{this.props.subtext}</FormHelperText>;
    const textWidth = _.get(this.props, 'extras.width') || '100%';

    let inputLabelProps;
    if (this.props.fieldValue) {
      inputLabelProps = { shrink: true };
    }

    let endAdornment;
    let startAdornment;
    if (this.currencyPosition === 'right') {
      if (!(this.props.extras && this.props.extras.reviewMode)) {
        endAdornment = this.currencyDisplay;
      }
    } else if (this.currencyPosition === 'left') {
      startAdornment = this.currencyDisplay;
    }

    return (
      <>
        {this.props.icon === 'Done' ? <Done /> : undefined}
        <TextField
          style={{ width: textWidth }}
          variant="standard"
          disabled={this.props.disabled}
          error={this.props.error}
          label={this.props.label}
          id={this.props.id}
          value={content}
          onFocus={this.props.onFocus}
          onChange={this.onChange}
          onBlur={this.props.onBlur}
          InputLabelProps={inputLabelProps}
          InputProps={{
            disableUnderline: shouldDisable,
            endAdornment,
            startAdornment,
            // readOnly: readOnlyProp,
          }}
        />
        {this.props.pending ? <CircularProgress size={14} /> : null}
        {this.props.tooltip !== undefined ? tooltip : null}
        {this.props.subtext !== undefined ? subtext : null}
      </>
    );
  }

  private onChange(event) {
    if (this.props.extras && this.props.extras.numberFormat) {
      // this script strips the currency formatting.
      this.props.onChange(event.target.value.split(this.groupSeparator).join(''));
    } else {
      this.props.onChange(event.target.value);
    }
  }
}
