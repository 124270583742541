import { Action } from '../actions/counterActions';
import { Region } from '../models/Jurisdiction';
import { getJurisdictionInfoStateFromApplication } from '../services/persistence/ScarecrowToSelfBoardApplicationConverter';

export interface JurisdictionInfoState {
  brandCode?: string;
  region?: Region;
  referrerName?: string;
  country?: string;
  jurisdictionCode?: string;
  subJurisdictionId?: number;
  subJurisdictionCode?: string;
}

export default function jurisdictionInfoReducer(state: JurisdictionInfoState = {}, action: Action) {
  switch (action.type) {
    case 'LOAD_APPLICATION':
      return {
        ...state,
        ...getJurisdictionInfoStateFromApplication(action.payload),
      };
    case 'SET_BRAND':
      return {
        ...state,
        brandCode: action.payload,
      };
    case 'SET_REFERRER':
      return {
        ...state,
        referrerName: action.payload,
      };
    case 'SET_COUNTRY':
      return {
        ...state,
        country: action.payload,
      };
    case 'SET_SUB_JURISDICTION_CODE':
      return {
        ...state,
        subJurisdictionCode: action.payload,
      };
    case 'SET_JURISDICTION_VALUES':
      if (!state.subJurisdictionCode) {
        return {
          ...state,
          subJurisdictionCode: action.payload.subJurisdictionCode,
          brandCode: action.payload.brand,
          country: action.payload.country,
        };
      }
      return state;

    default:
      return state;
  }
}
