import { connect } from 'react-redux';
import CustomerFlowLanguageSelector from '../../components/customer/CustomerFlowLanguageSelector';

const mapDispatchToProps = (dispatch) => ({
  setUserLanguage: (locale: string) => {
    dispatch({ type: 'SET_USER_LANGUAGE', payload: locale });
  },
});

export default connect(
  undefined,
  mapDispatchToProps,
)(CustomerFlowLanguageSelector);
