import {
  Button, Card, CardContent, CardHeader, Typography,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { StyledComponentProps, StyleRules, withStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import i18next from 'i18next';
import * as React from 'react';
import { Component } from 'react';
import { Trans } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

const styles: StyleRules = {
  cardContent: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
};

class CustomerSensitiveDataRemoved extends Component<StyledComponentProps & RouteComponentProps> {
  constructor(props) {
    super(props);

    this.returnToApplicationList = this.returnToApplicationList.bind(this);
  }

  public componentDidMount() {
    window.document.title = i18next.t('customer_ui_data_removed');
  }

  public render() {
    const classes = this.props.classes!;
    return (
      <Card>
        <CardHeader title={i18next.t('boarding_title_sensitivedataremoved')} component="h1" />
        <CardContent>
          <Typography variant="subtitle1">
            <Trans>boarding_sensitivedataremoved_body1</Trans>
          </Typography>
          <br />
          <div className={classes.cardContent}>
            <Typography variant="body1">
              <Trans>boarding_sensitivedataremoved_body2</Trans>
              {' '}
              <Trans>boarding_decision_body2_content_1</Trans>
              {' '}
              <Trans>boarding_decision_body2_content_2</Trans>
            </Typography>
            <br />
            <FormControl>
              <Button variant="contained" type="submit" onClick={this.returnToApplicationList}>
                <ChevronLeft />
                <Trans>Return_to_Application_List</Trans>
              </Button>
            </FormControl>
          </div>
        </CardContent>
      </Card>
    );
  }

  private returnToApplicationList() {
    this.props.history.push('/applist');
  }
}
export default withRouter(withStyles(styles)(CustomerSensitiveDataRemoved) as any);
