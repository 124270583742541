import { FormControlLabel, Radio } from '@material-ui/core';
import * as React from 'react';
import { QuizQuestion } from '../../services/EIDQuizService';

interface Props {
  quizQuestion: QuizQuestion;
  quizAnswers: { [quizAnswerIndex: number]: string };
  handleChange: (questionNumber: number, event: any) => void;
}

export default class EIDQuizChoice extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { quizQuestion, quizAnswers, handleChange } = this.props;
    return quizQuestion.questionChoices.map((quizChoice) => {
      let isRadioButtonTicked = false;
      if (quizAnswers[quizQuestion.questionNumber] && quizChoice.answerNumber) {
        isRadioButtonTicked = quizAnswers[quizQuestion.questionNumber] === quizChoice.answerNumber.toString();
      }
      return (
        <FormControlLabel
          onChange={handleChange.bind(this, quizQuestion.questionNumber)}
          label={quizChoice.answerText}
          value={`${quizChoice.answerNumber}`}
          control={<Radio checked={isRadioButtonTicked} />}
          aria-label={quizChoice.answerText}
          aria-selected={false}
          key={quizChoice.answerNumber}
        />
      );
    });
  }
}
