import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { CustomerAction, CustomerAppState } from '../../reducers/customer_ui';

/**
 * The server expects the userLanguage cookie to be set with the whole locale object, in a path that's accessible to the server.
 * ex: userLanguage=en_US;path=/
 *
 * If this cookie is not set, the document generation platform won't generate documents in the correct language for jurisdictions
 * that allow multiple languages, like Canada.
 *
 * Additionally we extract the cookie for use ourselves and override the query param value with what is found in the cookie as the user's
 * display language.
 */
export function setLanguageCookie(action$: Observable<CustomerAction>, state$: Observable<CustomerAppState>) {
  return action$.pipe(
    ofType('SET_USER_LANGUAGE'),
    tap((action) => {
      const cookie = `userLanguage=${action.payload};path=/`;
      document.cookie = cookie;
    }),
    filter(() => false),
  );
}
