import _ from 'lodash';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import {
  filter, map, tap, withLatestFrom,
} from 'rxjs/operators';
import { Action } from '../../actions/counterActions';
import { CookiePolicy } from '../../models/CookiePolicy';
import { CustomerAction, CustomerAppState } from '../../reducers/customer_ui';

export function setGDPRMixPanelCookie(action$: Observable<CustomerAction>, state$: Observable<CustomerAppState>):
Observable<Action> {
  return action$.pipe(
    ofType('SET_GDPR_COOKIES'),
    withLatestFrom(
      state$.pipe(
        map((state: CustomerAppState) => state.rest.cookiePolicy),
      ),
    ),
    map(([action, cookiePolicyState]) => {
      const cookiePolicy = { analytics: true, essential: true } as CookiePolicy;
      let time;
      if (!cookiePolicyState || !cookiePolicyState.analytics) {
        time = new Date();
        time.setDate(time.getDate() + 90); // 90 Days
        const cookie = `cookiePolicy=${JSON.stringify(cookiePolicy)};`;
        document.cookie = `${cookie} expires=${time.toUTCString()}; path=/`;
      }
      return {
        type: 'SET_COOKIE_POLICY',
        payload: cookiePolicy,
      };
    }),
  );
}

export function setOnlyNecessaryCookies(action$: Observable<CustomerAction>, state$: Observable<CustomerAppState>) {
  return action$.pipe(
    ofType('SET_ONLY_NECESSARY_COOKIES'),
    withLatestFrom(
      state$.pipe(
        map((state: CustomerAppState) => state.rest.cookiePolicy),
      ),
    ),
    map(([action, cookiePolicyState]) => {
      const cookiePolicy = { analytics: false, essential: true } as CookiePolicy;
      if (cookiePolicyState && cookiePolicyState.analytics) {
        removeMixPanelCookie();
      }
      const time = new Date();
      time.setDate(time.getDate() + 90); // 90 Days
      const cookie = `cookiePolicy=${JSON.stringify(cookiePolicy)};`;
      document.cookie = `${cookie} expires=${time.toUTCString()}; path=/`;
      return {
        type: 'SET_COOKIE_POLICY',
        payload: cookiePolicy,
      };
    }),
  );
}

export function removeAnalyticsCookie(action$: Observable<CustomerAction>, state$: Observable<CustomerAppState>) {
  return action$.pipe(
    ofType('REMOVE_ANALYTICS_COOKIE'),
    tap(() => {
      removeMixPanelCookie();
    }),
    filter(() => false),
  );
}

function removeMixPanelCookie() {
  const allCookies = document.cookie.split(';');
  let mixPanelCookieName = _.find(allCookies, (cookie) => _.endsWith(cookie.split('=')[0], 'mixpanel'));
  if (mixPanelCookieName) {
    // removing spaces
    mixPanelCookieName = _.replace(mixPanelCookieName, ' ', '').split('=')[0];
    document.cookie = `${mixPanelCookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}
