import * as React from 'react';
import { Component } from 'react';

export default class Spacer extends Component {
  public render() {
    return (
      <div style={{ height: '8px' }}>
        <span>&nbsp;</span>
      </div>
    );
  }
}
