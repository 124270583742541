import { MuiThemeProvider } from '@material-ui/core';
import * as _ from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { theme } from '../../theme/theme';
import InitialsDisclosure from './InitialsDisclosure';

interface Props {
  documentHtml: string;
  handleAllInitialsChecked: (array: boolean[]) => void;
  checkIfHasInitials: (hasInitials: boolean) => void;
}

interface State {
  initialsAdded: boolean[];
  nodes: NodeList;
}

export default class AgreementPresenter extends Component<Props, State> {
  private divRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    const item = (num: number) => new Node();
    this.state = {
      initialsAdded: [],
      nodes: {
        length: 0,
        item,
        forEach: _.forEach,
      },
    };

    this.divRef = React.createRef();
  }

  public render() {
    // console.log("did a render");
    return (
      <div style={{ width: '100%', minHeight: '250px', border: '1px solid #ccc' }} ref={this.divRef}>
        <div dangerouslySetInnerHTML={{ __html: this.props.documentHtml }} />
      </div>
    );
  }

  public componentDidMount() {
    const nodes = this.divRef.current!.querySelectorAll('.initials');
    this.props.checkIfHasInitials(nodes.length > 0);

    const initialsAdded = new Array(nodes.length);
    for (let index = 0; index < initialsAdded.length; index++) {
      initialsAdded[index] = false;
    }

    this.setState({
      nodes,
      initialsAdded,
    });
    let initials;
    _.forEach(nodes, (node, index) => {
      initials = node.getAttribute('initials');
      ReactDOM.render(
        <MuiThemeProvider theme={theme}>
          <InitialsDisclosure handleClick={this.handleClick(index)} initials={initials} />
        </MuiThemeProvider>,
        node,
      );
    });
  }

  public handleClick(index: number) {
    return () => {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.initialsAdded[index] = true;
      this.props.handleAllInitialsChecked(this.state.initialsAdded);
    };
  }
}
